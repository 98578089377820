// https://en.wikipedia.org/wiki/Depth-first_search
export function* dfsTraverse<T>(
  root: T,
  traversal: (node: T) => T[]
): Iterable<{ node: T; predecessor?: T; path: T[]; skipChildren: () => void }> {
  const visited = new Set<T>();
  const stack: { node: T; path: T[] }[] = [{ node: root, path: [] }];
  while (stack.length > 0) {
    const { node, path } = stack.pop();
    if (visited.has(node)) {
      continue;
    }
    visited.add(node);
    let skipChildren = false;
    yield { node, path, predecessor: path[path.length - 1], skipChildren: () => (skipChildren = true) };
    if (skipChildren) {
      continue;
    }
    const children = traversal(node);
    if (children) {
      stack.push(
        ...children
          .filter((child) => !visited.has(child))
          .map((child) => ({ node: child, path: [...path, node] }))
          .reverse() // Insert them in reverse order so that first returned child is traversed first
      );
    }
  }
}

export function drawTree<T>(
  traversal: Iterable<{ node: T; predecessor?: T; path: T[]; skipChildren: () => void }>,
  drawNode?: (node: T, padding: string) => string
): string {
  let result = '';
  for (const { node, path } of traversal) {
    const padding = '  '.repeat(path.length);
    result += `${padding}${drawNode?.(node, padding) ?? String(node)}\n`;
  }
  return result;
}
