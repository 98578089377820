<script setup lang="ts" generic="T">
import { computed, ref, useSlots, watch } from 'vue';
import { merge } from 'lodash-es';

import BaseLoading from '../../components/BaseLoading/BaseLoading.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import UtilDelayMount from '../../components/UtilDelayMount/UtilDelayMount.vue';

import Menu from '../../transitional-components/Menu/Menu.vue';
import MenuItem from '../../transitional-components/MenuItem/MenuItem.vue';
import SwmSelectionBlock from '../SwmSelectionBlock/SwmSelectionBlock.vue';
import { useItemSelectionScrollHandler } from '../../composables/useItemSelectionScrollHandler';
import {
  type ItemSelectionKeyHandlersOptions,
  useItemSelectionKeyHandlers,
} from '../../composables/useItemSelectionKeyHandlers';

const props = defineProps<{
  items: T[];
  command: (item: T) => boolean;
  query?: string;
  noResults?: boolean;
  loading?: boolean;
  loadingMore?: boolean;
  options?: ItemSelectionKeyHandlersOptions;
}>();

const emit = defineEmits<{
  updated: [];
  selectItem: [item: T];
  focusedItem: [item: T];
  hoveredItem: [item: T];
}>();

const slots = useSlots();

const page = ref(1);
const limit = ref(30);
const lastFocusedItem = ref<T>();

const isLoading = computed(() => {
  return props.loading;
});

const isStillLoading = computed(() => {
  return props.loading && props.items.length;
});

const showMoreLimitReached = computed(() => {
  return page.value === 4;
});

const paginatedItems = computed(() => {
  return props.items.slice(0, page.value * limit.value);
});

const canShowMore = computed(() => {
  return (
    !isLoading.value &&
    !isStillLoading.value &&
    page.value * limit.value < props.items.length &&
    !showMoreLimitReached.value
  );
});

const showPrompt = computed(() => {
  return !props.query && !props.loading && !props.noResults && !props.items.length && !!slots.prompt;
});

const accept = (payload: T | string) => {
  if (payload === 'showMore') {
    page.value++;
  } else {
    props.command(payload as T);
  }
  return true;
};

const defaultOptions = ref(
  merge(
    {
      additionalHandlers: {
        Enter: accept,
        Tab: accept,
      },
      includeAdditionalIndexes: canShowMore.value ? ['showMore'] : undefined,
      preventIndexReset: false,
    },
    props.options
  )
);

const { selectedIndex, onKeyDown } = useItemSelectionKeyHandlers<T>(paginatedItems, defaultOptions);

const { scrollContainerRef, setItemRef } = useItemSelectionScrollHandler(selectedIndex);

function isFocused(itemIndex: number) {
  if (!selectedIndex.value) {
    return false;
  }

  const selectedItemIndex = selectedIndex.value[0];
  const item = paginatedItems.value[selectedItemIndex] || undefined;

  if (item && itemIndex === selectedItemIndex && lastFocusedItem.value !== item) {
    emit('focusedItem', item);
    lastFocusedItem.value = item;
  }

  return itemIndex === selectedItemIndex;
}

function showMore() {
  page.value++;
}

watch(
  [() => props.query, () => paginatedItems.value, () => canShowMore.value],
  ([newQuery, newPaginatedItems], [oldQuery, oldPaginatedItems]) => {
    defaultOptions.value.includeAdditionalIndexes = canShowMore.value ? ['showMore'] : undefined;
    defaultOptions.value.preventIndexReset =
      newQuery === oldQuery && newPaginatedItems.length > oldPaginatedItems.length;

    defaultOptions.value = merge(defaultOptions.value, props.options);
  }
);

defineExpose({ onKeyDown });
</script>

<template>
  <SwmSelectionBlock class="swm-inline-selection-content">
    <slot name="header" />

    <div v-if="noResults" class="swm-inline-selection-content__no-results">
      <slot name="no-results">
        <BaseProse size="small">No results found.</BaseProse>
      </slot>
    </div>

    <div v-if="showPrompt" class="swm-inline-selection-content__prompt">
      <slot name="prompt" />
    </div>

    <BaseLoading
      v-if="isLoading"
      variant="secondary"
      :size="isStillLoading ? 'small' : 'medium'"
      class="swm-inline-selection-content__loading"
      :class="{ 'swm-inline-selection-content__loading--still': isStillLoading }"
    />

    <div v-show="items.length && !noResults" ref="scrollContainerRef" class="swm-inline-selection-content__items">
      <Menu wrapper="div">
        <slot name="items" :paginated-items="paginatedItems" :is-focused="isFocused" :set-item-ref="setItemRef" />

        <BaseProse
          v-if="showMoreLimitReached"
          class="swm-inline-selection-content__limit-reached"
          wrapper="li"
          size="small"
          >Please refine your search parameters to see more results…</BaseProse
        >

        <UtilDelayMount v-if="canShowMore">
          <MenuItem
            :ref="(el: any) => { 
            if (el?.root) {
              setItemRef(el.root, paginatedItems.length) 
            } 
          }"
            wrapper="div"
            class="swm-inline-selection-content__load-more"
            :class="{ 'swm-inline-selection-content__load-more--loading': loadingMore }"
            :focused="isFocused(paginatedItems.length)"
            @click="showMore"
            @keydown.enter="showMore"
            ><BaseLoading
              v-if="loadingMore"
              size="xsmall"
              variant="secondary"
              class="swm-inline-selection-content__loading-more"
            />
            <template v-else>Load more…</template></MenuItem
          >
        </UtilDelayMount>
      </Menu>
    </div>

    <slot name="footer" />
  </SwmSelectionBlock>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-inline-selection-content {
  $self: &;

  &__items {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }

  &__no-results,
  &__prompt {
    margin: var(--space-small) var(--space-xlarge);
    text-align: center;
  }

  &__loading {
    &--still {
      margin: var(--space-xsmall) var(--space-xlarge) 0;
    }
  }

  &__load-more {
    &--loading {
      &:hover,
      &:focus,
      &--focused {
        cursor: default;
        background-color: transparent;
      }
    }
  }

  &__loading-more {
    height: 21px; // Align perfectly with text
  }

  &__limit-reached {
    color: var(--color-text-secondary);
    list-style: none;
    padding: var(--space-xsmall) var(--space-xsmall) 0;
    text-align: center;
  }
}
</style>
