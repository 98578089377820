<script setup lang="ts">
import { productEvents } from '@swimm/shared';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { toRaw } from 'vue';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { BaseIcon } from '@swimm/reefui';
import type * as _model from '@tiptap/pm/model';

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

function handleClick() {
  const from = props.getPos();
  // toRaw due to https://github.com/ueberdosis/tiptap/issues/4274
  const to = from + toRaw(props.node).nodeSize;
  props.editor
    .chain()
    .focus(from - 1)
    .deleteRange({ from, to })
    .insertMermaid()
    .run();

  swimmEditorServices.external.trackEvent(productEvents.EDITED_PLACEHOLDER, {
    type: 'mermaid',
  });
}
</script>

<template>
  <NodeViewWrapper
    class="swm-mermaid-placeholder"
    data-swm-mermaid-placeholder
    :class="{ clickable: editor.options.editable }"
    data-testid="mermaid-placeholder"
    @click="handleClick"
  >
    <BaseIcon name="graph-flow" />
    <div contenteditable="false">{{ node.attrs.placeholder }}</div>
  </NodeViewWrapper>
</template>

<style scoped lang="scss">
.swm-mermaid-placeholder {
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 16px;
  font-size: var(--font-size-xsmall);
  line-height: 16px;
  color: var(--color-text-secondary);
  border: 1px dashed var(--color-border-default);
  background-color: var(--color-bg-surface);

  &.clickable:hover {
    background-color: var(--color-bg-surface-hover);
  }
}
</style>
