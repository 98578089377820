import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core';

function transformSwmTablePlaceholder(state: StateCore): void {
  const tokens = state.tokens;

  for (let open = 0; open < tokens.length; open++) {
    if (tokens[open].type !== 'swm_block_open' || tokens[open].tag !== 'SwmTablePlaceholder') {
      continue;
    }

    let close;
    for (close = open + 1; close < tokens.length; close++) {
      if (tokens[close].type === 'swm_block_close' && tokens[close].tag === 'SwmTablePlaceholder') {
        break;
      }
    }

    if (close === tokens.length) {
      continue;
    }

    // The element should either have no children or have a table as its only child
    if (tokens.length > 2 && (tokens[open + 1].type !== 'table_open' || tokens[close - 1].type !== 'table_close')) {
      continue;
    }

    tokens[open].type = 'swm_table_placeholder_open';

    tokens[close].type = 'swm_table_placeholder_close';
  }
}

export default function swm_table_placeholder(md: MarkdownIt): void {
  md.core.ruler.after('block', 'swm_table_placeholder', transformSwmTablePlaceholder);
}
