import { flatten } from 'lodash-es';

import { type TokenSuggestion, splitLineByWords } from '@swimm/shared';

import type { FileTokensIndex } from './fileTokensIndex';
import { getSuggestionsForQuery } from './filterSuggestions';
import type { QueryTokensService } from './queryTokensService';
import type { StaticAnalysisIndex } from './staticAnalysisIndex';
import { type ComputedRef, computed } from 'vue';

interface TokenSuggestionsResources {
  fileTokensIndex: FileTokensIndex;
  staticAnalysisIndex: StaticAnalysisIndex;
  queryTokensService: QueryTokensService;
}

export interface TokenSuggestionsService {
  query: (
    query: string,
    files: { repoId: string; path: string }[],
    repos: string[],
    options?: { exactMatch?: boolean }
  ) => { suggestions: TokenSuggestion[]; loading: boolean };
  queryAsync: (
    query: string,
    files: { repoId: string; path: string }[],
    repos: string[],
    options?: { exactMatch?: boolean }
  ) => Promise<TokenSuggestion[]>;
  suggestionsBuildTimestamp: ComputedRef<number>;
  queryDefinitionsAsync: (repoId: string, token: string) => Promise<TokenSuggestion[]>;
  clear: () => void;
}

export function useTokenSuggestionsService(dependencies: TokenSuggestionsResources): TokenSuggestionsService {
  const suggestionsBuildTimestamp = computed(() => {
    return dependencies.staticAnalysisIndex.suggestionsBuildTimestamp.value;
  });

  const query = (
    query: string,
    files: { repoId: string; path: string }[],
    repos: string[],
    options?: { exactMatch?: boolean }
  ): { suggestions: TokenSuggestion[]; loading: boolean } => {
    if (query === '') {
      return { suggestions: [], loading: false };
    }
    const { suggestions, loading } = getAllSuggestions(query, files, repos);
    return { suggestions: removeDuplicates(getSuggestionsForQuery(query, suggestions, options)), loading };
  };

  const queryAsync = async (
    query: string,
    files: { repoId: string; path: string }[],
    repos: string[],
    options?: { exactMatch?: boolean }
  ): Promise<TokenSuggestion[]> => {
    if (query === '') {
      return [];
    }
    if (!options?.exactMatch) {
      throw new Error('queryAsync must be called with options.exactMatch option set to true');
    }
    const suggestions = await getAllSuggestionsAsync(query, files, repos);
    return removeDuplicates(getSuggestionsForQuery(query, suggestions, options));
  };

  async function queryDefinitionsAsync(repoId: string, token: string): Promise<TokenSuggestion[]> {
    const map = await dependencies.staticAnalysisIndex.getAsyncMap(repoId);
    return map.get(token) ?? [];
  }

  const clear = (): void => {
    dependencies.fileTokensIndex.clear();
    dependencies.staticAnalysisIndex.clear();
    dependencies.queryTokensService.clear();
  };

  const getAllSuggestions = (
    query: string,
    files: { repoId: string; path: string }[],
    repos: string[]
  ): { suggestions: TokenSuggestion[]; loading: boolean } => {
    const tokensFromSources = [
      ...files.map(({ repoId, path }) => dependencies.fileTokensIndex.get(repoId, path)),
      ...repos.map((repoId) => dependencies.staticAnalysisIndex.get(repoId)),
      dependencies.queryTokensService.get(query),
    ];
    return {
      suggestions: flatten(tokensFromSources.map((tokensPerSource) => tokensPerSource.suggestions)),
      loading: tokensFromSources.some((source) => source.loading),
    };
  };

  async function getStaticSugggestionsAsync(repoId: string, query: string): Promise<TokenSuggestion[]> {
    const map = await dependencies.staticAnalysisIndex.getAsyncMap(repoId);
    return map.get(query) ?? [];
  }

  const getAllSuggestionsAsync = async (
    query: string,
    files: { repoId: string; path: string }[],
    repos: string[]
  ): Promise<TokenSuggestion[]> => {
    const queryWords = splitLineByWords(query);
    const firstToken = queryWords.length > 1 ? queryWords[0] : query;
    const tokensFromSources = [
      ...files.map(({ repoId, path }) => dependencies.fileTokensIndex.getAsync(repoId, path, firstToken)),
      ...repos.map((repoId) => getStaticSugggestionsAsync(repoId, firstToken)),
      dependencies.queryTokensService.getAsync(query),
    ];
    return flatten(await Promise.all(tokensFromSources));
  };

  const removeDuplicates = (suggestions: TokenSuggestion[]): TokenSuggestion[] => {
    const indexedSuggestions = new Map<string, TokenSuggestion>();
    suggestions.forEach((suggestion) => {
      const key = JSON.stringify({ repoId: suggestion.repoId, position: suggestion.position });
      // We prioritize non-static results.
      if (indexedSuggestions.get(key)?.static === false) {
        return;
      }
      indexedSuggestions.set(key, suggestion);
    });
    return [...indexedSuggestions.values()];
  };

  return { query, queryAsync, clear, suggestionsBuildTimestamp, queryDefinitionsAsync };
}
