import { type SwimmDocument, getLoggerNew } from '@swimm/shared';
import type { User } from '@swimm/reefui';
import { filter, groupByObject, parse } from './input';
import { toSwmd } from './output';
import InvalidInputError from '../invalidInputError';

const logger = getLoggerNew('ppg/cai/main.ts');

export default async function main(
  title: string,
  template: string,
  filePaths: string[],
  config: { repoId: string; repoName: string; branch: string; user: User }
): Promise<SwimmDocument> {
  logger.info('Starting CAI process');
  const validPaths: string[] = filter(filePaths);

  if (!validPaths.length) {
    logger.error('Invalid input >> no files match required input for composing a CAI document');
    throw new InvalidInputError('No files match required input for composing a CAI document');
  }

  const filesByObjectName = groupByObject(filePaths);

  if (Object.keys(filesByObjectName).length === 0) {
    logger.error('Invalid input >> No relevant files found');
    throw new InvalidInputError('No relevant files found');
  }

  const processes = await parse(filesByObjectName, config.repoId, config.branch);

  if (!processes.length) {
    logger.error('Invalid input >> process files are required to compose a CAI document');
    throw new InvalidInputError('Process files are required to compose a CAI document');
  }

  return toSwmd({ ...config, title }, processes, template);
}
