import {
  FirestoreFieldValue,
  collectionNames,
  getDocFromCollection,
  getDocFromSubCollection,
  getSubCollectionRef,
  setValuesInDoc,
  updateDocInSubCollection,
} from '@/adapters-common/firestore-wrapper';
import { type WorkspaceDocumentTemplate, type WorkspaceUser, config, getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function getWorkspaceFromDB(workspaceId: string) {
  const response = await getDocFromCollection(collectionNames.WORKSPACES, workspaceId);
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error getting workspace ${workspaceId} from DB, error: ${response.errorMessage}`);
    return {};
  }
  return response.data;
}

export async function getWorkspaceUserFromDB(workspaceId: string, userId: string): Promise<WorkspaceUser> {
  const response = await getDocFromSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error getting user ${userId} on workspace ${workspaceId} from DB, error: ${response.errorMessage}`);
    return null;
  }

  return response.data as WorkspaceUser;
}

export async function fetchDocumentTemplates(workspaceId: string): Promise<Map<string, WorkspaceDocumentTemplate>> {
  const response = getSubCollectionRef(collectionNames.WORKSPACES, workspaceId, collectionNames.DOCUMENT_TEMPLATES);
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error getting document templates ref on workspace ${workspaceId} from DB, error: ${response.errorMessage}`
    );
    return new Map();
  }
  const templatesMap = new Map<string, WorkspaceDocumentTemplate>();
  const querySnapshot = await response.data.orderBy('version', 'desc').get();
  if (querySnapshot.docs.length === 0) {
    return templatesMap;
  }
  querySnapshot.docs.forEach((doc) => {
    const data = doc.data();
    if (!templatesMap.has(data.name)) {
      templatesMap.set(data.name, {
        id: doc.id,
        format: data.format,
        name: data.name,
        text: data.text,
      });
    }
  });

  return templatesMap;
}

export async function addRepoToWorkspaceUserFavorites(workspaceId: string, userId: string, repoId: string) {
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId,
    { favoriteRepoIds: FirestoreFieldValue.arrayUnion(repoId) }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error adding repo ${repoId} to favorites for user ${userId} workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
  }
}

export async function removeRepoFromWorkspaceUserFavorites(workspaceId: string, userId: string, repoId: string) {
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId,
    { favoriteRepoIds: FirestoreFieldValue.arrayRemove(repoId) }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error removing repo ${repoId} from favorites for user ${userId} workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
  }
}

export async function updateWorkspaceUserRecentRepos(workspaceId: string, userId: string, recentRepoIds: string[]) {
  const response = await updateDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.WORKSPACE_USERS,
    userId,
    { recentRepoIds }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error updating recent repos for user ${userId} on workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
  }
}

export async function updateWorkspaceOnFirestore(
  workspaceId: string,
  data: { [key: string]: string | boolean | number | object }
): Promise<boolean> {
  const response = await setValuesInDoc(collectionNames.WORKSPACES, workspaceId, data, { merge: true });

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error updating workspace ${workspaceId} in DB, error: ${response.errorMessage}`);
    return false;
  }
  return true;
}
