<script setup lang="ts">
import { nodeViewProps } from '@tiptap/vue-3';
// import path from 'path-browserify';
import { computed, ref, toRef, watchEffect } from 'vue';
import type * as _model from '@tiptap/pm/model';
import { SwmPathNodeViewInner } from '@swimm/editor';
import {
  ApplicabilityStatus,
  type Path,
  type SmartElementWithApplicability,
  isSmartElementWithNewInfo,
} from '@swimm/shared';
import { getSwimmEditorServices } from '../extensions/Swimm';
import { getSwimmNodeId } from '@/swmd/swimm_node';
import { useTiptapIsSelected } from '@/composables/tiptapIsSelected';

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

const { selected } = useTiptapIsSelected(toRef(props, 'editor'), toRef(props, 'node'), toRef(props, 'getPos'));

// TODO Support for `short` & `custom`
// const text = computed(() => {
//   if (props.node.attrs.customDisplayText) {
//     return props.node.attrs.customDisplayText;
//   } else if (props.node.attrs.short) {
//     return decodeURI(path.basename(props.node.attrs.href));
//   } else {
//     return decodeURI(props.node.attrs.href);
//   }
// });

const customConfiguration = computed(() => swimmEditorServices.getCustomConfiguration(props.extension.name));

const type = computed(() => {
  if ((props.node.attrs.href as string).endsWith('/')) {
    return 'folder';
  }

  return 'file';
});

const swimmNodeId = computed(() => {
  return getSwimmNodeId(props.node);
});

const shouldAnimateNode = computed(() => {
  return swimmEditorServices.animations.shouldAnimateNode(swimmNodeId.value);
});

const crossRepo = computed(() => props.node.attrs.repoId !== swimmEditorServices.repoId.value);

const autosyncedElement = computed(() => {
  return swimmEditorServices.autosyncOutput.value?.smartElements.get(swimmNodeId.value) as
    | SmartElementWithApplicability<Path>
    | undefined;
});

const branch = ref<string>();
watchEffect(async () => {
  if (!swimmEditorServices.isAuthorized.value) {
    branch.value = undefined;
    return;
  }

  if (!crossRepo.value) {
    branch.value = swimmEditorServices.branch.value;
    return;
  }

  branch.value = swimmEditorServices.getRepo(props.node.attrs.repoId)?.defaultBranch;
});

const repo = computed(() => {
  return swimmEditorServices.getRepo(props.node.attrs.repoId);
});

const repoName = computed(() => {
  return props.node.attrs.repoName ?? 'Unknown repo';
});

const repoFullName = computed(() => {
  return props.node.attrs.repoName ? swimmEditorServices.getRepoFullName(props.node.attrs.repoId) : '';
});
const autosyncedPath = computed(() => {
  return autosyncedElement.value != null && isSmartElementWithNewInfo(autosyncedElement.value)
    ? `${autosyncedElement.value.newInfo.filePath}${props.node.attrs.href.endsWith('/') ? '/' : ''}`
    : undefined;
});

const currentPath = computed(() => autosyncedPath.value ?? props.node.attrs.href);

async function reselectPath() {
  const path = await swimmEditorServices.selectPath();
  if (path) {
    props.updateAttributes({
      href: encodeURI(path.type === 'directory' ? `/${path.path}/` : `/${path.path}`),
    });
  }
}

function deletePath() {
  props.editor.chain().focus().convertSwmPathToCode(props.getPos()).run();
}
</script>

<template>
  <SwmPathNodeViewInner
    :selected="selected"
    :editable="swimmEditorServices.editable.value"
    :is-marking-as-verified="shouldAnimateNode"
    :type="type"
    :branch="branch"
    :repo-id="node.attrs.repoId"
    :cross-repo="crossRepo"
    :authorized="swimmEditorServices.isAuthorized.value"
    :repo-icon-name="swimmEditorServices.getRepoIconName(node.attrs.repoId)"
    :repo-name="repoName"
    :repo-full-name="repoFullName"
    :repo="repo"
    :path="props.node.attrs.href"
    :applicability="autosyncedElement?.applicability ?? ApplicabilityStatus.Unknown"
    :autosynced-path="autosyncedPath"
    :hide-applicability="customConfiguration?.hideApplicability"
    @open-file="
      swimmEditorServices.external.openFile({ repoId: node.attrs.repoId, path: currentPath, revision: branch })
    "
    @mark-as-verified="
      autosyncedElement != null && editor.commands.applyAutosync(new Map([[autosyncedElement.id, autosyncedElement]]))
    "
    @reselect-path="reselectPath"
    @delete-path="deletePath"
  />
</template>
