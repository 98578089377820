import { BATCH_IMPORT_SCREENS, IMPORT_OPTIONS } from '@/modules/batch-import/constants';
import type { BatchImportFile, BatchImportScreen } from '@/modules/batch-import/constants';
import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { computed, nextTick, ref, shallowRef } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import type { Ref } from 'vue';
import swal from 'sweetalert';
import { BATCH_IMPORT_EVENTS, BATCH_IMPORT_ORIGINS } from '../constants/analytics-events';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { type SwimmDocument, config, fileUtils, objectUtils, shortUuid } from '@swimm/shared';
import { getFileFromZipFileName, getZipFilesContent } from '@/modules/batch-import/helpers/zipFileHelpers';
import { useClientFilesUpload } from '@/modules/batch-import/compositions/client-files-upload';
import { isResourceSwimmByFilename } from '@/common/composables/swimmResource';
import { generateJSON } from '@tiptap/html';
import { useRepoSwitcher } from '@/common/composables/repoSwitcher';
import { useRouting } from '@/common/composables/routing';
import { extensions, serializeSwmd } from '@swimm/swmd';

const DEFAULT_STEP = BATCH_IMPORT_SCREENS.IMPORT_OPTIONS_SELECTION;

export type ImportOptions = (typeof IMPORT_OPTIONS)[keyof typeof IMPORT_OPTIONS];

export const useBatchImportStore = defineStore(STORES.BATCH_IMPORT, () => {
  const store = useStore();
  const route = useRoute();
  const { connectToRepo } = useRepoSwitcher();
  const { getCurrentOrDefaultBranch } = useRouting();
  const analytics = useAnalytics();
  const { getFilesFromClientInput } = useClientFilesUpload();
  const importRepoId = ref<string>(null);
  const asSwmContent = ref(false);
  const mdFilesInputRef = ref(null);
  const zipFilesInputRef = ref(null);

  const conversionType = ref<ImportOptions>(IMPORT_OPTIONS.UPLOAD_FOLDER);

  const showBatchImportModal = ref(false);
  const waitingForFiles = ref(false);
  const currentModalScreen = shallowRef<BatchImportScreen>(null);
  const rawFilesSelected = ref([]);
  const filesToImport: Ref<BatchImportFile[]> = ref([]);

  const mdFiles = computed(() => filesToImport.value.filter((file) => !isReadme(file)));
  const mdFilesSelected = ref<Record<string, boolean>>(Object.create({}));

  const readmeMDFiles = computed(() => filesToImport.value.filter((file) => isReadme(file)));
  const readmeFilesSelected = ref<Record<string, boolean>>(Object.create({}));

  const shouldCreateFolders = ref(false);
  const isCreateFoldersSupported = computed(() => conversionType.value === IMPORT_OPTIONS.RUN_SMART_IMPORT);

  const shouldUploadFilesToRepo = computed(
    () => !!store.getters['database/db_getRepoMetadata'](importRepoId.value)?.integrations?.commit_images_to_repo
  );

  const handleFilesLoaded = ({
    files,
    forceImportAll = false,
  }: {
    files: BatchImportFile[];
    forceImportAll?: boolean;
  }) => {
    filesToImport.value = files;
    if (forceImportAll) {
      // MdFiles & readmdMDFiles is computed from filesToImport
      mdFiles.value.forEach((file) => {
        mdFilesSelected.value[file.id] = true;
      });
      readmeMDFiles.value.forEach((file) => {
        readmeFilesSelected.value[file.id] = true;
      });
      setCurrentModalScreen(BATCH_IMPORT_SCREENS.IMPORT_PROCESS);
    } else {
      setCurrentModalScreen(BATCH_IMPORT_SCREENS.FILES_SELECTION);
    }
  };
  const filesConverted: Ref<BatchImportFile[]> = ref([]);
  const aborted = ref<boolean>(false);

  const setCurrentModalScreen = (screen: BatchImportScreen) => {
    currentModalScreen.value = screen;

    const extraMetadataPerPage = getExtraMetadataForScreen();
    if (screen.key !== BATCH_IMPORT_SCREENS.IMPORT_OPTIONS_SELECTION.key) {
      analytics.track(BATCH_IMPORT_EVENTS.VIEW_STEP(screen.name), {
        Origin: BATCH_IMPORT_ORIGINS.CREATION_HUB,
        'Import Type': conversionType.value,
        ...extraMetadataPerPage[currentModalScreen.value.key],
      });
    }
  };

  const resetStore = () => {
    filesToImport.value = [];
    filesConverted.value = [];
    rawFilesSelected.value = [];
    asSwmContent.value = false;
    aborted.value = false;
    mdFilesSelected.value = Object.create({});
    readmeFilesSelected.value = Object.create({});
  };

  const toggleBatchImportModal = async (
    showBatchImportModalValue: boolean,
    { repoId = route.params.repoId as string }: { repoId?: string } = {}
  ) => {
    if (showBatchImportModalValue) {
      importRepoId.value = repoId;
      setCurrentModalScreen(DEFAULT_STEP);
      nextTick(() => {
        handleOptionSelected(conversionType.value);
      });
    } else {
      if (currentModalScreen.value.key === BATCH_IMPORT_SCREENS.IMPORT_PROCESS.key) {
        const shouldAbort = await swal({
          title: 'Are you sure you want to abort the import?',
          text: 'All progress will be lost.',
          dangerMode: true,
          buttons: {
            cancel: true,
            confirm: { text: 'Abort', visible: true },
          },
        });
        if (shouldAbort) {
          aborted.value = true;
          analytics.track(BATCH_IMPORT_EVENTS.CLOSED_IMPORT_MODAL, {
            Origin: BATCH_IMPORT_ORIGINS.CREATION_HUB,
            'Import Type': conversionType.value,
            step: currentModalScreen.value.name,
          });
        } else {
          return;
        }
      } else {
        analytics.track(BATCH_IMPORT_EVENTS.CLOSED_IMPORT_MODAL, {
          Origin: BATCH_IMPORT_ORIGINS.CREATION_HUB,
          'Import Type': conversionType.value,
          step: currentModalScreen.value.name,
        });
      }
    }

    showBatchImportModal.value = showBatchImportModalValue;
    resetStore();
  };

  const getExtraMetadataForScreen = () => {
    return {
      [BATCH_IMPORT_SCREENS.FILES_SELECTION.key]: {
        'MD Files Count': mdFiles.value.length ? mdFiles.value.length : undefined,
        'Readme Files Count': readmeMDFiles.value.length ? readmeMDFiles.value.length : undefined,
      },
      [BATCH_IMPORT_SCREENS.IMPORT_REPORT.key]: {
        'Errors count': filesConverted.value.filter(
          (file) => file.issues.filter((issue) => issue.type === 'ERROR').length
        ).length,
        'Warnings count': filesConverted.value.filter(
          (file) => file.issues.filter((issue) => issue.type === 'WARNING').length
        ).length,
      },
      [BATCH_IMPORT_SCREENS.IMPORT_SUMMARY.key]: {
        'Imported files count': filesConverted.value.length,
      },
    };
  };

  async function handleOptionSelected(option: ImportOptions) {
    if (waitingForFiles.value) {
      return;
    }
    analytics.track(BATCH_IMPORT_EVENTS.IMPORT_FLOW_STARTED, { 'Import Type': option });

    if (option === IMPORT_OPTIONS.RUN_SMART_IMPORT) {
      await loadRepoMarkdownFiles();
    } else if (option === IMPORT_OPTIONS.UPLOAD_FOLDER) {
      analytics.track(BATCH_IMPORT_EVENTS.FILE_PICKER_OPENED, { 'Import Type': option });
      await loadClientMDFiles();
    } else if (
      [IMPORT_OPTIONS.UPLOAD_NOTION_ZIP_FOLDER, IMPORT_OPTIONS.UPLOAD_CONFLUENCE_ZIP_FOLDER].includes(option)
    ) {
      analytics.track(BATCH_IMPORT_EVENTS.FILE_PICKER_OPENED, { 'Import Type': option });
      await loadClientZipFiles(option);
    }
  }

  const loadClientMDFiles = async () => {
    waitingForFiles.value = true;
    filesToImport.value = [];
    const { code, files, canceled } = await getFilesFromClientInput(mdFilesInputRef.value);
    waitingForFiles.value = false;
    if (code === config.ERROR_RETURN_CODE || canceled) {
      analytics.track(BATCH_IMPORT_EVENTS.FILE_PICKER_CANCELED, { 'Import Type': 'upload-folder' });
      toggleBatchImportModal(false);
    } else {
      rawFilesSelected.value = files;
      const clientMDFiles = rawFilesSelected.value
        .filter((file) => file.path.endsWith(config.MARKDOWN_FILE_EXTENSION) && !isResourceSwimmByFilename(file.path))
        .map((file) => ({
          name: file.path,
          path: file.webkitRelativePath || file.path,
          id: shortUuid(),
          file: file.content,
          issues: [],
        }));

      handleFilesLoaded({ files: clientMDFiles, forceImportAll: true });
    }
  };

  const loadClientZipFiles = async (option: ImportOptions) => {
    waitingForFiles.value = true;
    filesToImport.value = [];
    const { code, files, canceled } = await getFilesFromClientInput(zipFilesInputRef.value, { encode: true });
    if (code === config.ERROR_RETURN_CODE || canceled) {
      waitingForFiles.value = false;
      analytics.track(BATCH_IMPORT_EVENTS.FILE_PICKER_CANCELED, { 'Import Type': option });
      toggleBatchImportModal(false);
    } else {
      const optionsFilesExtension = {
        [IMPORT_OPTIONS.UPLOAD_NOTION_ZIP_FOLDER]: '.md',
        [IMPORT_OPTIONS.UPLOAD_CONFLUENCE_ZIP_FOLDER]: '.html',
      };

      rawFilesSelected.value = await getZipFilesContent(files);
      // Filter out files from zip that are not from the correct extension
      const filesFromZip = rawFilesSelected.value.filter((fileFromZip) =>
        fileFromZip.path.endsWith(optionsFilesExtension[option])
      );

      let filesToLoad: BatchImportFile[] = [];
      if (option === IMPORT_OPTIONS.UPLOAD_NOTION_ZIP_FOLDER) {
        filesToLoad = filesFromZip.map((fileFromZip) => ({
          name: getFileFromZipFileName(fileFromZip.file.name, option),
          path: fileFromZip.path,
          id: shortUuid(),
          file: fileFromZip.fileContent,
          issues: [],
        }));
      } else if (option === IMPORT_OPTIONS.UPLOAD_CONFLUENCE_ZIP_FOLDER) {
        filesToLoad = filesFromZip.map((fileFromZip) => {
          const asHTML = fileFromZip.fileContent;

          const jsonContent = generateJSON(asHTML, extensions);
          const swimmDocument: SwimmDocument = {
            title: getFileFromZipFileName(fileFromZip.file.name, option),
            repoId: route.params.repoId as string,
            frontmatter: {},
            content: jsonContent,
          };
          const markdown = serializeSwmd(swimmDocument, {
            baseUrl: config.BASE_URL,
            workspaceId: route.params.workspaceId as string,
          });

          return {
            name: getFileFromZipFileName(fileFromZip.file.name, option),
            path: fileFromZip.path,
            id: shortUuid(),
            file: markdown,
            issues: [],
          };
        });
      }
      waitingForFiles.value = false;

      handleFilesLoaded({
        files: filesToLoad,
        forceImportAll: false,
      });
    }
  };

  const loadRepoMarkdownFiles = async () => {
    waitingForFiles.value = true;
    const getRepoFolderTree = computed(() => store.getters['filesystem/fs_getRepoFolderTree']);
    const loadFolderTree = (args?) => store.dispatch('filesystem/loadFolderTree', args);
    const branch = await getCurrentOrDefaultBranch(importRepoId.value);
    // DB
    let repoTree = null;
    repoTree = getRepoFolderTree.value(importRepoId.value);
    if (!repoTree || objectUtils.isEmpty(repoTree)) {
      await connectToRepo({
        repoId: importRepoId.value,
        alertError: false,
      });
      await loadFolderTree({
        branch,
        requestMetadata: {
          repoId: importRepoId.value,
          workspaceId: route.params.workspaceId as string,
          cloneUrl: store.getters['database/db_getRepoMetadata'](importRepoId.value)?.url,
        },
      });
      await nextTick();
      repoTree = getRepoFolderTree.value(importRepoId.value);
    }
    if (!repoTree || objectUtils.isEmpty(repoTree)) {
      handleFilesLoaded({ files: [] });
      waitingForFiles.value = false;
      return;
    }

    rawFilesSelected.value = fileUtils.getFilesFromTreeFilteredByExtension({
      tree: repoTree,
      filterExtension: config.MARKDOWN_FILE_EXTENSION,
    });
    waitingForFiles.value = false;

    handleFilesLoaded({
      files: rawFilesSelected.value.map((file) => ({
        name: file.name,
        path: file.path,
        id: shortUuid(),
        file: null,
        issues: [],
      })),
    });
  };

  return {
    showBatchImportModal,
    readmeFilesSelected,
    currentModalScreen,
    mdFilesInputRef,
    zipFilesInputRef,
    mdFilesSelected,
    filesConverted,
    readmeMDFiles,
    filesToImport,
    waitingForFiles,
    mdFiles,
    aborted,
    asSwmContent,
    conversionType,
    rawFilesSelected,
    importRepoId,
    toggleBatchImportModal,
    setCurrentModalScreen,
    handleFilesLoaded,
    resetStore,
    shouldUploadFilesToRepo,
    shouldCreateFolders,
    isCreateFoldersSupported,
  };
});

function isReadme(file) {
  return file.name.toLowerCase().startsWith('readme.');
}
