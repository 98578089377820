import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { config, firestoreCollectionNames } from '@swimm/shared';

export const FirestoreFieldValue = firebase.firestore.FieldValue;

export const collectionNames = firestoreCollectionNames;

export const SUPPORTED_WHERE_IN_VALUES = 30; // 'IN' supports up to 30 comparison values
/**
 * Get firestore server timestamp
 * @returns {FieldValue}
 */
export function firestoreTimestamp() {
  return firebase.firestore.FieldValue.serverTimestamp();
}

export function firestoreDeleteField() {
  return firebase.firestore.FieldValue.delete();
}

/**
 * Get firestore timestamp for the current timestamp (similar to Date.now())
 * @returns {Timestamp}
 */
export function firestoreTimestampNow() {
  return firebase.firestore.Timestamp.now();
}

/**
 * @param seconds
 * @returns {Timestamp} of time in <seconds> seconds from now
 */
export function firestoreTimestampInSeconds(seconds) {
  const newTimestamp = Date.now() + seconds * 1000;
  return firebase.firestore.Timestamp.fromDate(new Date(newTimestamp));
}

export function firestoreTimestampFromDate(date) {
  return firebase.firestore.Timestamp.fromDate(date);
}

export function firestoreIncrement(n = 1) {
  return firebase.firestore.FieldValue.increment(n);
}

/**
 * Remove fields from array using firestore arrayRemove
 * @param toRemove a value to be removed
 * @returns {Promise<FieldValue>}
 */
export async function firestoreArrayRemove(toRemove) {
  return firebase.firestore.FieldValue.arrayRemove(toRemove);
}

/**
 * Add fields to array using firestore arrayUnion
 * @param toAdd a value to be added
 * @returns {Promise<FieldValue>}
 */
export async function firestoreArrayUnion(toAdd) {
  return firebase.firestore.FieldValue.arrayUnion(toAdd);
}

/**
 * Gets a doc by its ID from a certain collection
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param docId (string) - the Id in the collection to get
 * @returns {Promise<{code: 0, data: firebase.firestore.DocumentData}|{code: 1, errorMessage}>}
 */
export async function getDocFromCollection(collectionName, docId) {
  try {
    const docRef = await firebase.firestore().collection(collectionName).doc(docId).get();
    if (docRef.exists) {
      return { code: config.SUCCESS_RETURN_CODE, data: docRef.data() };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Doc ${docId} doesn't exist in collection ${collectionName}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collection
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param clauseArray (array) - containing the query as firestore would expect
 * @param orderBy (string=) - optional order
 * @param limit (integer=) - optional max number of items
 * @returns {Promise<{code: 1, errorMessage}|{code: 0, data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>}>}
 */
export async function getDocsRefWithWhereClause(collectionName, clauseArray, orderBy, limit) {
  return await getDocsRefWithWhereClauses(collectionName, [clauseArray], orderBy, limit);
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collection
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param clauseArrays (array or array) - containing list of queries as firestore would expect
 * @param orderBy (string=) - optional order
 * @param limit (integer=) - optional max number of items
 * @returns {Promise<{code: 1, errorMessage}|{code: 0, data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>}>}
 */
export async function getDocsRefWithWhereClauses(collectionName, clauseArrays, orderBy, limit) {
  try {
    let baseRef = firebase.firestore().collection(collectionName);
    let currentClauseArray = 'NONE';
    for (const clauseArray of clauseArrays) {
      currentClauseArray = clauseArray;
      baseRef = baseRef.where(...clauseArray);
    }
    if (orderBy) {
      baseRef = baseRef.orderBy(...orderBy);
    }

    if (limit) {
      baseRef = baseRef.limit(limit);
    }
    const docsRef = await baseRef.get();
    if (docsRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: docsRef };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Couldn't find docs in collection ${collectionName} with clause ${currentClauseArray}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Retrieves multiple documents from a collection using an array of document IDs
 * @param collectionName (string from 'collectionNames' enum) - The target collection to query
 * @param ids (string[]) - Array of document IDs to retrieve
 */
export async function getDocumentsByIds(collectionName, ids) {
  try {
    if (ids.length > SUPPORTED_WHERE_IN_VALUES) {
      return {
        code: config.ERROR_RETURN_CODE,
        errorMessage: 'getDocumentsByIds: ids array length is greater than where in supported values',
      };
    }
    const docsRef = await firebase
      .firestore()
      .collection(collectionName)
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
      .get();

    if (docsRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: docsRef };
    }
    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Couldn't find docs in collection ${collectionName} with ids ${ids}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the the target sub collection to query
 * @param clauseArrays (array of arrays) - containing the query as firestore would expect
 * @param [getOptions] (firebase.firestore.GetOptions) - firestore get options
 * @returns {Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>}
 */
export async function getDocsRefFromSubCollectionWithWhereClauses(
  parentCollectionName,
  parentId,
  collectionName,
  clauseArrays,
  getOptions = undefined
) {
  let baseRef = firebase.firestore().collection(parentCollectionName).doc(parentId).collection(collectionName);
  for (const clauseArray of clauseArrays) {
    baseRef = baseRef.where(...clauseArray);
  }
  return await baseRef.get(getOptions);
}

/* Backward compat version for single clauseArray */

export async function getDocsRefFromSubCollectionWithWhereClause(
  parentCollectionName,
  parentId,
  collectionName,
  clauseArray
) {
  return getDocsRefFromSubCollectionWithWhereClauses(parentCollectionName, parentId, collectionName, [clauseArray]);
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collectionGroup
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param clauseArray (array) - containing the query as firestore would expect
 * @returns {Promise<{code: 1, errorMessage}|{code: 0, data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>}>}
 */
export async function getCollectionGroupRefWithWhereClause(collectionName, clauseArray) {
  try {
    const docsRef = await firebase
      .firestore()
      .collectionGroup(collectionName)
      .where(...clauseArray)
      .get();
    if (docsRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: docsRef };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Couldn't find docs in collection ${collectionName} with clause ${clauseArray}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets the docs in a certain sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param [getOptions] (firebase.firestore.GetOptions) - firestore get options
 * @returns {Promise<{code: 1, errorMessage}|{code: 0, data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>}>}
 */
export async function getSubCollection(parentCollectionName, parentId, collectionName, getOptions = undefined) {
  try {
    const collectionRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .get(getOptions);

    if (collectionRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: collectionRef };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Collection ${collectionName} doesn't exist under ${parentCollectionName}/${parentId}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference to a certain sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @returns {{code: 1, errorMessage}|{code: 0, data: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}}
 */
export function getSubCollectionRef(parentCollectionName, parentId, collectionName) {
  try {
    const collectionRef = firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName);
    return { code: config.SUCCESS_RETURN_CODE, data: collectionRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a specific doc in a certain sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param docId (string) - the Id of the specific doc to get
 * @returns {Promise<{code: 0, data: firebase.firestore.DocumentData}|{code: 1, errorMessage}>}
 */
export async function getDocFromSubCollection(parentCollectionName, parentId, collectionName, docId) {
  try {
    const docRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .get();

    if (docRef.exists) {
      return { code: config.SUCCESS_RETURN_CODE, data: docRef.data() };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Doc ${docId} doesn't exist under ${parentCollectionName}/${parentId}/${collectionName}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference of a doc in a certain sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param docId (string) - the Id of the specific doc to get
 * @returns {Promise<{code: 0, data: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>}|{code: 1, errorMessage}>}
 */
export async function getDocRefFromSubCollection(parentCollectionName, parentId, collectionName, docId) {
  try {
    const docRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .get();

    return { code: config.SUCCESS_RETURN_CODE, data: docRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets the collection ref in a certain hierarch of sub collections
 * @param collectionNames (array) - containing the sub collections query by their hierarchy
 * @param parentId (array) - containing the relevant Ids in the path to query
 * @returns {firebase.firestore.DocumentReference}
 */

export function getDocRefRecursive(collectionNames, ids) {
  if (collectionNames.length !== ids.length) {
    throw Error('getDocRefRecursive: length of collection names should be equal to the length of ids');
  }
  let ref = firebase.firestore().collection(collectionNames[0]).doc(ids[0]);
  for (let i = 1; i < ids.length; i++) {
    ref = ref.collection(collectionNames[i]).doc(ids[i]);
  }
  return ref;
}

/**
 * Gets the collection ref in a certain hierarch of sub collections
 * @param parentCollectionName (array) - containing the sub collections query by their hierarchy (length n)
 * @param parentId (array) - containing the relevant Ids in the path to query (length n-1)
 */

export function getSubCollectionRefRecursive(parentCollectionName, parentId) {
  if (parentCollectionName.length !== parentId.length + 1) {
    throw Error('getSubCollectionRefRecursive: length of collection names should be 1 + length of ids');
  }
  let ref = firebase.firestore();
  for (let i = 0; i < parentId.length; i++) {
    ref = ref.collection(parentCollectionName[i]).doc(parentId[i]);
  }
  return ref.collection(parentCollectionName[parentCollectionName.length - 1]);
}

/**
 * Gets the docs in a certain hierarch of sub collections
 * @param parentCollectionName (array) - containing the sub collections query by their hierarchy (length n)
 * @param parentId (array) - containing the relevant Ids in the path to query (length n-1)
 * @returns {Promise<{code: 0, data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>}|{code: 1, errorMessage}>}
 */
export async function getSubCollectionRecursive(parentCollectionName, parentId) {
  try {
    const collection = await getSubCollectionRefRecursive(parentCollectionName, parentId).get();
    return { code: config.SUCCESS_RETURN_CODE, data: collection };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Fetches an item in a certain hierarch of sub collections
 * @param parentCollectionName (array) - containing the sub collections query by their hierarchy (length n)
 * @param parentId (array) - containing the relevant Ids in the path to query (length n-1)
 * @param docId  (string) - the Id of the specific doc to get
 * @returns {Promise<{code: 0, data: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>}|{code: 1, errorMessage}>}
 */
export async function getSubCollectionItemRecursive(parentCollectionName, parentId, docId) {
  try {
    const item = await getSubCollectionRefRecursive(parentCollectionName, parentId).doc(docId).get();
    return { code: config.SUCCESS_RETURN_CODE, data: item };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Deletes an item in a certain hierarch of sub collections
 * @param parentCollectionName (array) - containing the sub collections query by their hierarchy (length n)
 * @param parentId (array) - containing the relevant Ids in the path to query (length n-1)
 * @param docId  (string) - the Id of the specific doc to delete
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function deleteSubCollectionItemRecursive(parentCollectionName, parentId, docId) {
  try {
    await getSubCollectionRefRecursive(parentCollectionName, parentId).doc(docId).delete();
    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a doc from a collection reference
 * @param ref a reference to a collection
 * @param docId (string) - the Id of the specific doc to get
 * @returns {Promise<{code: 1, errorMessage}|{code: 0, data: firebase.firestore.DocumentData}>}
 */
export async function getDocFromRef(ref, docId) {
  try {
    const docRef = await ref.doc(docId).get();

    if (docRef.exists) {
      return { code: config.SUCCESS_RETURN_CODE, data: docRef.data() };
    }

    return { code: config.ERROR_RETURN_CODE, errorMessage: `Doc ${docId} doesn't exist in ref` };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Updates fields of a specific doc
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param docId (string) - the Id in the collection to update
 * @param updateValue (object) - containing the fields to update in the specific doc
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function updateDocInCollection(collectionName, docId, updateValue) {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(docId)
      .update({ ...updateValue });

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Delete a specific doc
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param docId (string) - the Id in the collection to update
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function deleteDocFromCollection(collectionName, docId) {
  try {
    await firebase.firestore().collection(collectionName).doc(docId).delete();

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Updates fields of a specific doc in a sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param docId (string) - the Id of the specific doc to update
 * @param updateValue (object) - containing the fields to update in the specific doc
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function updateDocInSubCollection(parentCollectionName, parentId, collectionName, docId, updateValue) {
  try {
    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .update({ ...updateValue });

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Set fields in an existing doc
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param docId (string) - the Id in the collection to set
 * @param setValues (object) - with the fields to set in the doc
 * @param options (object) - firestore set options
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function setValuesInDoc(collectionName, docId, setValues, options = {}) {
  try {
    await firebase.firestore().collection(collectionName).doc(docId).set(setValues, options);

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Set fields in an existing doc in a sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param docId  (string) - the Id of the specific doc to set
 * @param setValues (object) - with the fields to set in the doc
 * @param options (object) - firestore set options
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function setValuesInDocInSubCollection(
  parentCollectionName,
  parentId,
  collectionName,
  docId,
  setValues,
  options = {}
) {
  try {
    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .set(setValues, options);

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Set fields in an existing doc in a sub collection
 * @param parentCollectionName (array) - containing the sub collections query by their hierarchy (length n)
 * @param parentId (array) - containing the relevant Ids in the path to query (length n)
 * @param setValues (object) - with the fields to set in the doc
 * @param options (object) - firestore set options
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function setValuesInDocSubCollectionRecursive(parentCollectionName, parentId, setValues, options = {}) {
  let collectionRef = await firebase.firestore();
  try {
    for (let i = 0; i < parentId.length; i++) {
      collectionRef = await collectionRef.collection(parentCollectionName[i]).doc(parentId[i]);
    }
    await collectionRef.set(setValues, options);

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Add a new doc to a collection
 * @param collectionName (string from 'collectionNames' enum) - the collection to query
 * @param addValues (object) - to add to the collection
 * @returns {Promise<{code: 0, data: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>}|{code: 1, errorMessage}>}
 */
export async function addDocToCollection(collectionName, addValues) {
  try {
    const addedRef = await firebase.firestore().collection(collectionName).add(addValues);

    return { code: config.SUCCESS_RETURN_CODE, data: addedRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Add a new doc to a sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param addValues (object) - to add to the collection
 * @returns {Promise<{code: 0, data: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>}|{code: 1, errorMessage}>}
 */
export async function addDocToSubCollection(parentCollectionName, parentId, collectionName, addValues) {
  try {
    const addedRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .add(addValues);

    return { code: config.SUCCESS_RETURN_CODE, data: addedRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Delete a doc from a sub collection
 * @param parentCollectionName (string from 'collectionNames' enum) - the first collection in the hierarchy
 * @param parentId (string) - the doc in the collection to get the sub collection from
 * @param collectionName (string from 'collectionNames' enum) - the target sub collection
 * @param docId  (string) - the Id of the specific doc to delete
 * @returns {Promise<{code: 0}|{code: 1, errorMessage}>}
 */
export async function deleteDocFromSubCollection(parentCollectionName, parentId, collectionName, docId) {
  try {
    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .delete();

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Subscribe a given user's doc change
 * @param {string} userId
 * @param {function} userChangedCallback
 * @returns {Promise<{code: 0, data: function}|{code: 1, errorMessage}>}
 */
export async function subscribeToUser(userId, userChangedCallback) {
  try {
    const unsubscribeUserWatch = await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .onSnapshot((snapshot) => {
        userChangedCallback(snapshot.data());
      });
    return { code: config.SUCCESS_RETURN_CODE, data: unsubscribeUserWatch };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 *
 * @param pathParts path divided to parts, something like: [<collection1>,<id-in-collection1>,<collection2>,<id-in-collection2>,<collection3>,<id-in-collection3>...] must be even
 * @returns {Promise<*>} data
 */

export async function getDocRecursive(pathParts, { addId } = {}) {
  const pathNice = pathParts.join('/');
  if (pathParts.length % 2 !== 0) {
    throw Error('length of pathParts must be even');
  }
  let ref = firebase.firestore();
  for (let i = 0; i < pathParts.length; i += 2) {
    ref = ref.collection(pathParts[i]).doc(pathParts[i + 1]);
  }
  const doc = await ref.get();
  if (!doc.exists) {
    throw new Error(`Cannot find doc: ${pathNice}`);
  }
  if (doc.exists) {
    const extra = {};
    if (addId) {
      extra.id = doc.id;
    }
    return { ...extra, ...doc.data() };
  }
}
export async function updateDocRecursive(pathParts, values) {
  const joinedPath = pathParts.join('/');
  if (pathParts.length % 2 !== 0) {
    throw Error('length of pathParts must be even');
  }
  let ref = firebase.firestore();
  for (let i = 0; i < pathParts.length; i += 2) {
    ref = ref.collection(pathParts[i]).doc(pathParts[i + 1]);
  }
  try {
    await ref.update({ ...values });
  } catch (error) {
    throw Error(`Failed to updated ${joinedPath} with ${{ ...values }}`);
  }
}

/**
 *
 * @param pathParts path divided to parts, something like: [<collection1>,<id-in-collection1>,<collection2>,<id-in-collection2>,<collection3>,<id-in-collection3>, <collection4>] must be odd
 * @param { addId, filters}: {} addId (default false): if true will add doc id to each doc data, filters (default []), list of filters to apply
 * @returns {Promise<*>} list of data
 */
export async function getCollectionDocsRecursive(pathParts, { addId = false, filters = [] } = {}) {
  if (pathParts.length % 2 !== 1) {
    throw Error('length of pathParts must be odd');
  }
  let ref = firebase.firestore();
  for (let i = 0; i < pathParts.length - 1; i += 2) {
    ref = ref.collection(pathParts[i]).doc(pathParts[i + 1]);
  }
  const collectionRef = ref.collection(pathParts[pathParts.length - 1]);
  const result = [];
  let query = collectionRef;
  for (const f of filters || []) {
    query = query.where(...f);
  }
  const snap = await query.get();
  snap.docs.forEach((doc) => {
    const extra = {};
    if (addId) {
      extra.id = doc.id;
    }
    result.push({ ...extra, ...doc.data() });
  });
  return result;
}
