import type { RepoStateData } from '@swimm/shared';
import { config, gitwrapper, parseRepoDataFields, state } from '@swimm/shared';
import path from 'path-browserify';

const SWIMM_JSON_PATH = path.join(config.SWM_FOLDER_IN_REPO, config.REPO_JSON);

export async function isNewRepoInitialized({
  provider,
  repoName,
  owner,
  api_url,
  tenant_id,
  defaultBranch,
}: RepoStateData): Promise<{ initialized: true; repoId: string } | { initialized: false }> {
  const tempRepoId = `${repoName}/${owner}`;

  const repoData = parseRepoDataFields({
    provider,
    repoName,
    owner,
    api_url,
    tenant_id,
    defaultBranch,
  });

  await state.set({ key: `repos.${tempRepoId}`, value: repoData });
  let rawSwimmFile;
  try {
    rawSwimmFile = await gitwrapper.getFileContentFromRevision({
      filePath: SWIMM_JSON_PATH,
      repoId: tempRepoId,
      safe: true,
      revision: defaultBranch,
    });
    if (!rawSwimmFile) {
      return { initialized: false };
    }
  } finally {
    await state.deleteKey({ key: `repos.${tempRepoId}` });
  }
  const swimmFile = JSON.parse(rawSwimmFile);
  return { repoId: swimmFile.repo_id, initialized: true };
}

export async function isRepoInitialized({ repoId, revision }: { repoId: string; revision?: string }) {
  const rawSwimmFile = await gitwrapper.getFileContentFromRevision({
    repoId,
    filePath: SWIMM_JSON_PATH,
    revision,
    safe: true,
  });
  if (!rawSwimmFile) {
    return false;
  }
  const swimmFile = JSON.parse(rawSwimmFile);
  const actualRepoId = swimmFile && swimmFile.repo_id;
  return !!actualRepoId;
}
