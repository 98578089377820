/**
 * mermaid node.
 *
 * Based on https://github.com/ueberdosis/tiptap/blob/75f0418f03fb040dc4c12c104dfe457342b4e4b4/packages/extension-code-block/src/code-block.ts
 *
 * @module
 */

import { type JSONContent, Node, VueNodeViewRenderer, mergeAttributes, textblockTypeInputRule } from '@tiptap/vue-3';
import MermaidNodeView from '../nodeViews/MermaidNodeView.vue';
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmMermaid: {
      insertMermaid: () => ReturnType;
      replaceMermaidContent: (pos: number, text: string) => ReturnType;
    };
  }
}

export interface MermaidOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export const backtickInputRegex = /^```mermaid[\s\n]$/;
export const tildeInputRegex = /^~~~mermaid[\s\n]$/;

export default Node.create<MermaidOptions>({
  name: 'mermaid',

  group: 'block',

  content: '(text|swmToken|swmPath)*',

  code: true,

  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        priority: 51,
        tag: 'pre',
        preserveWhitespace: 'full',
        getAttrs: (node) => (node as HTMLElement).classList.contains('mermaid') && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['pre', mergeAttributes({ class: 'mermaid' }, this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(MermaidNodeView);
  },

  addCommands() {
    return {
      insertMermaid:
        () =>
        ({ commands }) => {
          const node: JSONContent = {
            type: this.name,
            attrs: {},
          };

          return commands.insertContent(node);
        },

      replaceMermaidContent:
        (pos, text) =>
        ({ state, commands }) => {
          const $pos = state.doc.resolve(pos + 1);

          if ($pos.parent.type !== this.type) {
            return false;
          }

          const range = {
            from: $pos.start(),
            to: $pos.end(),
          };

          return commands.insertContentAt(range, {
            type: 'text',
            text,
          });
        },
    };
  },

  addInputRules() {
    return [
      textblockTypeInputRule({
        find: backtickInputRegex,
        type: this.type,
      }),
      textblockTypeInputRule({
        find: tildeInputRegex,
        type: this.type,
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [
      // This plugin creates a mermaid node for pasted mermaid content from VS Code
      new Plugin({
        key: new PluginKey('mermaidVSCodeHandler'),
        props: {
          // Based on https://github.com/ueberdosis/tiptap/blob/c4e655fb07ca516190b2f0b9abfcb825ae5aa52e/packages/extension-code-block/src/code-block.ts#L242
          handlePaste: (view, event) => {
            if (!event.clipboardData) {
              return false;
            }

            // Don’t create mermaid nodes inside code block or inside another mermaid node
            if (this.editor.isActive('codeBlock')) {
              return false;
            }

            const text = event.clipboardData.getData('text/plain');
            const vscode = event.clipboardData.getData('vscode-editor-data');
            const vscodeData = vscode ? JSON.parse(vscode) : undefined;
            const language = vscodeData?.mode;

            if (!text || !language || language !== 'mermaid') {
              return false;
            }

            // handle pasting of mermaid inside mermaid as plain text
            if (this.editor.isActive(this.type.name)) {
              const { tr } = view.state;
              tr.insertText(text.replace(/\r\n?/g, '\n'));
              tr.setMeta('paste', true);
              view.dispatch(tr);
              return true;
            }

            const { tr } = view.state;

            // Create an empty mermaid node
            tr.replaceSelectionWith(this.type.create());

            // Put cursor inside the newly created mermaid node
            tr.setSelection(TextSelection.near(tr.doc.resolve(Math.max(0, tr.selection.from - 2))));

            // Add text to mermaid node
            // strip carriage return chars from text pasted as code
            // see: https://github.com/ProseMirror/prosemirror-view/commit/a50a6bcceb4ce52ac8fcc6162488d8875613aacd
            tr.insertText(text.replace(/\r\n?/g, '\n'));

            // Store meta information
            // this is useful for other plugins that depends on the paste event
            // like the paste rule plugin
            tr.setMeta('paste', true);

            view.dispatch(tr);

            return true;
          },
        },
      }),
    ];
  },
});
