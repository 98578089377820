import {
  DUMMY_REPO_ID,
  GitProviderName,
  type RepoIdOwnerName,
  config,
  getLoggerNew,
  gitProviderUtils,
  isRepoIdDummyRepo,
  state as localState,
  parseRepoDataFields,
} from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useCreateRepoUser } from './createRepoUser';
import { getRepoStateDataToKeep } from '@/adapters-common/user';
import { getRepoDefaultBranch, getRepoRemoteDataBatch } from '@/remote-adapters/local_repo';
import { useRepoSwitcher } from './repoSwitcher';
import { collectionNames } from '@/adapters-common/firestore-wrapper';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useGlobalStore } from '@/modules/core/stores/global-store';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useStigg } from '@/common/composables/useStigg';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useLocalStateStatusStore } from '@/common/store/localStateStatus';

const logger = getLoggerNew(__modulename);

const setWorkspaceDataRequests: { [workspaceId: string]: Promise<void> } = {};

export function useInitData() {
  const store = useStore();
  const route = useRoute();
  const { fetchWorkspace: fetchWorkspaceStore } = useWorkspaceStore();
  const { createRepoSwimmer } = useCreateRepoUser();
  const gitAuthStore = useGitAuthorizationStore();
  const { isCurrentRepoAuthorized } = storeToRefs(gitAuthStore);
  const { isProviderAuthorized } = gitAuthStore;
  const reposStore = useReposStore();
  const { setIsRepoLocalStateDataReady } = useLocalStateStatusStore();
  const { initStiggForCustomer } = useStigg();
  const { reposStateData } = storeToRefs(reposStore);
  const {
    setRepoStateData: setRepoStoreStateData,
    setRepoStateBranchData: setRepoStoreStateBranchData,
    loadRepoStateData,
    loadAllReposStateData,
    updateReposStateData,
  } = reposStore;
  const { connectToRepo } = useRepoSwitcher();
  const { user } = storeToRefs(useAuthStore());
  const { fetchDismissedWorkspaceInvites } = useGlobalStore();
  const repositories = computed(() => store.state.database.repositories);
  const db_isRepoSwimmer = computed(() => store.getters['database/db_isRepoSwimmer']);
  const db_isPublicRepo = computed(() => store.getters['database/db_isPublicRepo']);
  const db_getWorkspaceRepoIds = computed(() => store.getters['database/db_getWorkspaceRepoIds']);
  const fs_getSwmdFileNameInRepo = computed(() => store.getters['filesystem/fs_getSwmdFileNameInRepo']);
  const fs_getSwmdPathInRepo = computed(() => store.getters['filesystem/fs_getSwmdPathInRepo']);
  const fetchRepoSwimmer = (args?) => store.dispatch('database/fetchRepoSwimmer', args);
  const fetchSwimmerStatus = (args?) => store.dispatch('database/fetchSwimmerStatus', args);
  const fetchRepository = (args?) => store.dispatch('database/fetchRepository', args);
  const fetchRepositories = (args?) => store.dispatch('database/fetchRepositories', args);
  const fetchRepoLifeguard = (args?) => store.dispatch('database/fetchRepoLifeguard', args);
  const fetchWorkspace = (args?) => store.dispatch('database/fetchWorkspace', args);
  const fetchDocumentChildCollections = (args?) => store.dispatch('database/fetchDocumentChildCollections', args);
  const refreshWorkspaceSharedDocs = (args) => store.dispatch('database/refreshWorkspaceSharedDocs', args);
  const fetchWorkspaceInvites = (args?) => store.dispatch('database/fetchWorkspaceInvites', args);
  const getRepoSwmsLists = (args?) => store.dispatch('filesystem/getRepoSwmsLists', args);
  const loadLocalSwmFile = (args?) => store.dispatch('filesystem/loadLocalSwmFile', args);

  const setRepoData = async (repoId?: string | null) => {
    const requestedRepoId = repoId || (route.params.repoId as string);
    await setRepoDbData(requestedRepoId);
    await setRepoStateData(requestedRepoId);
  };

  const setRepoDbData = async (repoId: string) => {
    if (
      !store.getters['database/db_isRepoFetched'](repoId) ||
      !store.getters['database/db_isRepoSwimmersFetched'](repoId)
    ) {
      const repoData = await fetchRepository({ repoId });
      if (!repoData) {
        return;
      }

      await fetchRepoSwimmer({ repoId, userId: user.value.uid });

      const isUserInRepo = db_isRepoSwimmer.value(repoId, user.value.uid);
      if (!isUserInRepo && repoId !== DUMMY_REPO_ID) {
        await createRepoSwimmer(user.value, repoId);
      }

      if (db_isPublicRepo.value(repoId)) {
        // Fetch lifeguard only for public repos as in a private repo this permission doesn't matter
        await fetchRepoLifeguard({ repoId, userId: user.value.uid });
      }

      fetchSwimmerStatus({ repoId, userId: user.value.uid });
    }
  };

  /*
    preftech the default branch for all repos with no default branch
    (internally, uses graphql and implemented only for github cloud)
    returns dict with the default branch that is used later when setting the local state
   */
  const prefetchRepoDefaultBranchInBatch = async (repoIds: string[]): Promise<Record<string, string | undefined>> => {
    const t1 = Date.now();
    // only GH cloud is supported for now, so if you are not authorized to it - skip
    if (!isProviderAuthorized({ provider: GitProviderName.GitHub })) {
      return {};
    }
    const repoIdsWithNoDefaultBranch = repoIds.filter((repoId) => !reposStateData.value[repoId]?.defaultBranch);
    const repoIdsWithNoDefaultBranchGitHub = repoIdsWithNoDefaultBranch.filter(
      (repoId) => repositories.value[repoId]?.metadata?.provider === GitProviderName.GitHub && repoId !== DUMMY_REPO_ID
    );

    let numQueried = 0;
    const queriedRepos = repoIdsWithNoDefaultBranchGitHub
      .map((repoId) => ({
        repoId,
        repoName: repositories.value[repoId]?.metadata?.name as string,
        owner: repositories.value[repoId]?.metadata?.owner as string,
      }))
      .filter((repo) => !!(repo.repoId && repo.repoName && repo.owner));
    const { result: defaultBranchByRepoId, numEmpty } = await getDefaultBranchForRepos(queriedRepos);
    numQueried = queriedRepos.length;
    const t2 = Date.now();
    const numSuccess = Object.keys(defaultBranchByRepoId).length;
    logger.info(
      `prefetchRepoDefaultBranchInBatch took ${
        t2 - t1
      } ms with ${numQueried} numEmpty = ${numEmpty} got reply for ${numSuccess}`
    );
    return defaultBranchByRepoId;
  };

  // do the actual call to fetch teh default branch for the repos
  // the call is done in slices of 100
  // return the mapping for repo id -> default branch and number of empty repos (just to be reported later)
  const getDefaultBranchForRepos = async (repos: RepoIdOwnerName[]) => {
    const result = {};
    let numEmpty = 0;
    if (repos.length > 0) {
      const sliceSize = 100;
      const gitPromises: Array<ReturnType<typeof getRepoRemoteDataBatch>> = [];
      for (let i = 0; i < repos.length; i += sliceSize) {
        const curQueriedRepos = repos.slice(i, i + sliceSize);
        gitPromises.push(
          getRepoRemoteDataBatch({
            provider: GitProviderName.GitHub,
            repos: curQueriedRepos,
          })
        );
      }
      const gitResults = await Promise.all(gitPromises);
      for (const curResult of gitResults) {
        for (const [repoId, respData] of Object.entries(curResult)) {
          if (respData === 'not-found') {
            result[repoId] = ''; // for not found
          } else if (respData.defaultBranch) {
            result[repoId] = respData.defaultBranch;
          } else {
            numEmpty += 1;
          }
        }
      }
    }
    return { result, numEmpty };
  };

  const setRepoStateData = async (repoId: string) => {
    await loadRepoStateData(repoId);
    let dataToKeep = {};
    const repoStateData = reposStateData.value[repoId];
    if (repoStateData) {
      // Repo is considered empty if not for the 'keys to keep'
      const repoHasOnlyKeysToKeep = localState.isRepoConsideredEmpty(reposStateData.value[repoId]);

      // Repo is not empty
      if (!repoHasOnlyKeysToKeep && repoStateData.defaultBranch && repoStateData.owner && repoStateData.repoName) {
        return;
      }

      // Repo is empty but has keys to keep
      dataToKeep = getRepoStateDataToKeep(reposStateData.value[repoId]);
    }
    const repoMetaData = repositories.value[repoId].metadata;

    const parsedUrl = gitProviderUtils.parseGitProviderURL(repoMetaData.url);
    const repoDataFields = parseRepoDataFields({
      repoName: parsedUrl.name,
      owner: parsedUrl.owner,
      provider: repoMetaData.provider,
      api_url: repoMetaData.api_url,
      tenant_id: repoMetaData.tenant_id,
    });
    let defaultBranch;
    // Set all available repo information for later getting branch by the repo provider
    // do not call getRepoDefaultBranch if we are not authorized
    if (
      isProviderAuthorized({
        provider: repoMetaData.provider,
        gitUrl: repoMetaData.api_url,
        tenantId: repoMetaData.tenant_id,
      })
    ) {
      const defaultBranchResult = await getRepoDefaultBranch({ repoId, ...repoDataFields });
      defaultBranch = defaultBranchResult.code === config.SUCCESS_RETURN_CODE ? defaultBranchResult.branch : '';
    } else {
      defaultBranch = '';
    }

    if (isCurrentRepoAuthorized.value || isRepoIdDummyRepo(repoId)) {
      if (reposStateData.value[repoId]) {
        await setRepoStoreStateBranchData(repoId, { defaultBranch });
      }
    }

    if (
      !reposStateData.value[repoId] ||
      !Object.keys(repoDataFields).every((key) => key in reposStateData.value[repoId])
    ) {
      const repoData = { ...dataToKeep, ...repoDataFields, defaultBranch };
      if (repoMetaData.api_url) {
        repoData.api_url = repoMetaData.api_url;
        repoData.tenant_id = repoMetaData.tenant_id;
      }
      await setRepoStoreStateData(repoId, repoData);
      await setIsRepoLocalStateDataReady(repoId);
    }
  };

  const getRepoNewState = async (repoId: string, prefetchedDefaultBranch?: string) => {
    let dataToKeep = {};
    const repoStateData = reposStateData.value[repoId];
    if (repoStateData) {
      // Repo is considered empty if not for the 'keys to keep'
      const repoHasOnlyKeysToKeep = localState.isRepoConsideredEmpty(reposStateData.value[repoId]);

      // Repo is not empty
      if (!repoHasOnlyKeysToKeep && repoStateData.defaultBranch && repoStateData.owner && repoStateData.repoName) {
        return [repoId, null];
      }

      // Repo is empty but has keys to keep
      dataToKeep = getRepoStateDataToKeep(reposStateData.value[repoId]);
    }
    const repoMetaData = repositories.value[repoId].metadata;
    const parsedUrl = gitProviderUtils.parseGitProviderURL(repoMetaData.url);
    const repoDataFields = parseRepoDataFields({
      repoName: parsedUrl.name,
      owner: parsedUrl.owner,
      provider: repoMetaData.provider,
      api_url: repoMetaData.api_url,
      tenant_id: repoMetaData.tenant_id,
    });
    let defaultBranch;
    // prefetchedDefaultBranch will be an empty string in case of 404 (usually means no access to repo)
    // in this case, we don't recompute again
    if (prefetchedDefaultBranch == null) {
      // Set all available repo information for later getting branch by the repo provider
      // do not call getRepoDefaultBranch if we are not authorized
      if (
        isProviderAuthorized({
          provider: repoMetaData.provider,
          gitUrl: repoMetaData.api_url,
          tenantId: repoMetaData.tenant_id,
        })
      ) {
        const defaultBranchResult = await getRepoDefaultBranch({ repoId, ...repoDataFields });
        defaultBranch = defaultBranchResult.code === config.SUCCESS_RETURN_CODE ? defaultBranchResult.branch : '';
      } else {
        defaultBranch = '';
      }
    } else {
      defaultBranch = prefetchedDefaultBranch;
    }
    const newData = { ...dataToKeep, ...repoDataFields, defaultBranch };
    if (repoMetaData.api_url) {
      newData.api_url = repoMetaData.api_url;
      newData.tenant_id = repoMetaData.tenant_id;
    }
    if (
      !reposStateData.value[repoId] ||
      Object.keys(newData).some((key) => newData[key] !== reposStateData.value[repoId][key])
    ) {
      return [repoId, newData];
    }
    return [repoId, null];
  };

  const setReposStateData = async (repoIds: string[], defaultBranchDict: Record<string, string | undefined>) => {
    const newDataByRepoId = Object.fromEntries(
      (await Promise.all(repoIds.map((repoId) => getRepoNewState(repoId, defaultBranchDict[repoId])))).filter(
        ([_, data]) => !!data
      )
    );
    logger.info(`setReposStateData, updating state data for: ${Object.keys(newDataByRepoId).length}`);
    if (Object.keys(newDataByRepoId).length > 0) {
      await updateReposStateData(newDataByRepoId);
      Object.keys(newDataByRepoId).forEach((repoId) => setIsRepoLocalStateDataReady(repoId));
    }
  };

  const setWorkspaceData = async (workspaceId, shouldFetchReposSwimmers = true, repoId = null) => {
    if (setWorkspaceDataRequests[workspaceId]) {
      await setWorkspaceDataRequests[workspaceId];
    }

    const fetchData = async () => {
      try {
        await fetchWorkspaceStore();
        await fetchWorkspace({ workspaceId });
        await Promise.allSettled([
          fetchDocumentChildCollections({
            documentId: workspaceId,
            children: [
              collectionNames.WORKSPACE_USERS,
              collectionNames.WORKSPACE_ADMINS,
              collectionNames.ARCHIVED_WORKSPACE_USERS,
              collectionNames.TAGS,
            ],
            containerCollection: collectionNames.WORKSPACES,
          }),
          setDataForAllRepos(workspaceId, shouldFetchReposSwimmers, repoId),
          refreshWorkspaceSharedDocs({ workspaceId }),
        ]);
      } catch (err) {
        logger.error({ err }, `setWorkspaceData: fetchData failed: ${err}`);
      }
    };

    try {
      await (setWorkspaceDataRequests[workspaceId] = fetchData());
    } finally {
      setWorkspaceDataRequests[workspaceId] = undefined;
    }

    try {
      await initStiggForCustomer(workspaceId);
    } catch (err) {
      logger.error({ err }, `Failed setting workspace ${workspaceId} in Stigg`);
    }
  };

  const setDataForAllRepos = async (workspaceId: string, shouldFetchReposSwimmers = true, repoId = null) => {
    // PERFORMANCE: The subscribe operation is very, very expensive - it freezes the app and prevents the list of docs
    //              from loading. We limit the concurrency here to 4 at a time to avoid that.
    const t1 = Date.now();
    const workspaceReposIds: string[] = db_getWorkspaceRepoIds.value(workspaceId);
    if (shouldFetchReposSwimmers) {
      // If there are many repos in the workspace, this action can take a long time.
      // In some cases, like the EditorPage, the swimmer data is not needed
      const assertDbResults = await Promise.allSettled(workspaceReposIds.map(setRepoDbData));
      assertDbResults.forEach((result) => {
        if (result.status === 'rejected') {
          logger.error(`setRepoDbData failed for a repo. Details: ${result.reason}`, {
            service: 'assert-repo-subscribed',
          });
        }
      });
    } else {
      // If we don't need the swimmer data, we can batch fetch the repos data (up to 10 repos in 1 db call)
      // This is much faster and helpful when there are many repos in the workspace
      let reposTofetch = workspaceReposIds.filter((repoId) => !store.getters['database/db_isRepoFetched'](repoId));
      if (repoId) {
        // Fetch swimmers for the current repo
        await setRepoDbData(repoId);
        // remove the repoId value from the list of repos to fetch
        reposTofetch = reposTofetch.filter((id) => id !== repoId);
      }
      await fetchRepositories({ repoIds: reposTofetch });
      // fetch or update swimmer for currenr repo
    }
    const t2 = Date.now();
    await loadAllReposStateData();
    const defaultBranchDict = await prefetchRepoDefaultBranchInBatch(workspaceReposIds);
    const t3 = Date.now();
    await setReposStateData(workspaceReposIds, defaultBranchDict);
    const t4 = Date.now();
    logger.info(`setDataForAllRepos completed
      total: ${Date.now() - t1},
      'part1: setRepoDbData': ${t2 - t1},
      'part2: prefetchRepoDefaultBranchInBatch': ${t3 - t2},
      'part3: setReposStateData': ${t4 - t3}`);
  };
  const setHomeData = async () => {
    if (!user.value.uid) {
      return;
    }
    try {
      await Promise.all([fetchWorkspaceInvites(), fetchDismissedWorkspaceInvites()]);
    } catch (err) {
      logger.error({ err }, `The app failed to fetch all repos data: ${err}`);
    }
  };

  const setPlaylistData = async ({
    playlistId,
    repoId,
    reload = false,
  }: {
    playlistId: string;
    repoId: string;
    reload?: boolean;
  }) => {
    const playlistIdToSet: string = playlistId ? playlistId : <string>route.params.playlistId;
    // Use default branch for cross repo playlists
    const isCrossRepo = repoId !== route.params.repoId;
    if (isCrossRepo) {
      await connectToRepo({ repoId, alertError: false });
    }
    const branch =
      route.params.branch && repoId === route.params.repoId
        ? route.params.branch
        : reposStateData.value[repoId]?.defaultBranch ?? '';

    try {
      if (playlistIdToSet && playlistIdToSet !== 'new') {
        // Get the actual swimm file name on the repo
        await getRepoSwmsLists({ repoId, branch });
        let playlistfile = fs_getSwmdFileNameInRepo.value(repoId, playlistIdToSet);
        const path = fs_getSwmdPathInRepo.value(repoId, playlistIdToSet);

        if (!playlistfile && reload) {
          await getRepoSwmsLists({ repoId, branch });
          playlistfile = fs_getSwmdFileNameInRepo.value(repoId, playlistIdToSet, true);
        }

        if (playlistfile) {
          await loadLocalSwmFile({
            fileName: playlistfile,
            repoId: repoId,
            shouldAutoSync: false,
            type: config.SWIMM_FILE_TYPES.PLAYLIST,
            branch,
            path,
            reload,
          });
        }
      }
    } catch (err) {
      logger.error({ err }, `The app failed to fetch the playlist swm file: ${err}`);
    }
  };

  return {
    setWorkspaceData,
    setRepoStateData,
    setRepoData,
    setHomeData,
    setPlaylistData,
  };
}
