import { Node, VueNodeViewRenderer, mergeAttributes } from '@tiptap/vue-3';
import SwmMermaidPlaceholderNodeView from '../nodeViews/SwmMermaidPlaceholderNodeView.vue';

export interface SwmMermaidPlaceholderOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export default Node.create<SwmMermaidPlaceholderOptions>({
  name: 'swmMermaidPlaceholder',

  group: 'block',

  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      placeholder: {
        parseHTML: (element) => {
          return element.getAttribute('data-placeholder');
        },
        renderHTML: (attributes) => {
          return { 'data-placeholder': attributes.placeholder };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-mermaid-placeholder') && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-swm-mermaid-placeholder': '' }, this.options.HTMLAttributes, HTMLAttributes),
      `Mermaid Placeholder: ${HTMLAttributes.placeholder}`,
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(SwmMermaidPlaceholderNodeView);
  },
});
