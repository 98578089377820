import gitwrapper from 'GitUtils/gitwrapper';
import { isFileSupportedFilter } from './repo-utils';
import { createDynamicStructureFromDiffString, dynamicPatchToSnippets } from '../diff-parser';
import nodePath from 'path-browserify';

export interface PRData {
  base: string;
  head: string;
  metadata?: {
    title: string;
    body?: string;
  };
}

export const BIG_SNIPPET_SIZE_IN_LINES = 15;
const BIG_ENOUGH_INDENTED_SNIPPET = 3;
const BIG_ENOUGH_SAME_INDENTATION_SNIPPET = 7;

// Paths that contain these parts are ignored by PR2doc since they can be big and not relevant to the logic of the PR.
const IGNORED_PATH_PARTS = ['dist'];

const isPathIrrelevantToPR2Doc = (filePath: string) => {
  if (!isFileSupportedFilter(filePath)) {
    return true;
  }
  const filePathParts = filePath.split(nodePath.sep);
  return IGNORED_PATH_PARTS.some((part) => filePathParts.includes(part));
};

export async function getPrChangesDetails({
  repoId,
  prHead,
  prBase,
}: {
  repoId: string;
  prHead: string;
  prBase: string;
}): Promise<{ diffSnippets: SnippetInfo[] }> {
  const gitDiff = await gitwrapper.getGitDiffRemote({
    repoId,
    base: prBase,
    head: prHead,
  });
  const diffSnippets = diffToSwimmDocumentSnippets({ gitDiff, filterIrreleventPaths: true });

  return { diffSnippets };
}

export interface SnippetInfo {
  filePath: string;
  content: string;
  startLine: number;
  endLine: number;
  repoId?: string;
}

export function diffToSwimmDocumentSnippets({
  gitDiff,
  filterIrreleventPaths,
}: {
  gitDiff: string;
  filterIrreleventPaths?: boolean;
}): SnippetInfo[] {
  const dynamic = createDynamicStructureFromDiffString({ rawDiffString: gitDiff }).swimmPatch;
  // create SnippetInfo objects from the dynamic structure and filter out snippets that had only deletions (no content)
  const snippets = dynamicPatchToSnippets(dynamic).filter((snippet) => snippet.content.length > 0);
  const snippetsSplitted = splitLongSnippets(snippets);
  if (filterIrreleventPaths) {
    return snippetsSplitted.filter((snippet) => !isPathIrrelevantToPR2Doc(snippet.filePath));
  }
  return snippetsSplitted;
}

// Filter out sections that are empty or contain new line (empty line/line with whitespaces) so that we don't create empty snippets in the generated doc
const filterEmptySections = (section: string[]) =>
  section?.length && !section.every((line: string) => line.trim() === '*');

export function splitLongSnippets(snippets: SnippetInfo[]): SnippetInfo[] {
  const INDENTATION_REGEX = /^(\s+)/;
  const splittedContent = snippets.map((snippet) => {
    if (snippet.content.split('\n').length > BIG_SNIPPET_SIZE_IN_LINES) {
      const sections = [];
      let currentSection: string[] = [];
      const snippetLines = snippet.content.split('\n');
      snippetLines.forEach((line: string) => {
        // Some languages such as COBOL contain the line numbers inside the actual line text. We strip it so that it
        // doesn't affect our split algorithm (otherwise it would lump everything together since there's never an
        // 'empty' line.
        const strippedLine = line.replace(/^[0-9]+/, '');
        const indentation = strippedLine.match(INDENTATION_REGEX)?.[0] || ''; // get line indentation
        const sectionStartingIndentation =
          (currentSection.length && currentSection[0].match(INDENTATION_REGEX)?.[0]) || '';
        if (
          indentation.length < sectionStartingIndentation.length &&
          currentSection.length >= BIG_ENOUGH_INDENTED_SNIPPET
        ) {
          sections.push(currentSection);
          currentSection = [];
        }
        if (
          currentSection.length >= BIG_ENOUGH_SAME_INDENTATION_SNIPPET &&
          indentation.length === sectionStartingIndentation.length &&
          strippedLine.trim().length === 0 // only split on an empty line
        ) {
          sections.push(currentSection);
          currentSection = [];
        }
        currentSection.push(line);
      });

      // last section for the snippet - verify we are not adding a section of an empty line
      if (currentSection.length > 0 && !(currentSection.length === 1 && currentSection[0].trim().length === 0)) {
        sections.push(currentSection);
      }

      return sections.filter(filterEmptySections).reduce((agg: SnippetInfo[], section: string[]) => {
        if (!agg.length) {
          return [
            {
              ...snippet,
              content: section.join('\n'),
              startLine: snippet.startLine,
              endLine: snippet.startLine + section.length - 1,
              repoId: snippet.repoId,
            },
          ];
        }
        return [
          ...agg,
          {
            ...snippet,
            content: section.join('\n'),
            startLine: agg[agg.length - 1].endLine + 1,
            endLine: agg[agg.length - 1].endLine + section.length,
            repoId: snippet.repoId,
          },
        ];
      }, []);
    }
    // snippet is small enough
    return [snippet];
  });

  return splittedContent.flat();
}
