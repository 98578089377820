import { Node, mergeAttributes } from '@tiptap/vue-3';

export interface SwmTablePlaceholderOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export default Node.create<SwmTablePlaceholderOptions>({
  name: 'swmTablePlaceholder',

  group: 'block',

  content: 'table*',

  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-table-placeholder') && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-swm-table-placeholder': '' }, this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
