<template>
  <!-- The following line is defined as code to disable grammarly suggestions -->
  <NodeViewWrapper
    as="code"
    :class="['sym-text', { selected: isSelected, corrupted: isTokenCorrupted }]"
    data-testid="smart-token-text"
    :data-selected="isSelected"
    spellcheck="false"
  >
    <TokenNodeView
      v-if="skipPreview"
      :computed-applicability-status="computedApplicabilityStatus"
      :symbol-id="symbolId"
      :should-animate-node="shouldAnimateNode"
      :symbol-applicability="symbolApplicability"
      :hide-applicability="hideApplicability"
      :no-access-to-repo="noAccessToRepo"
      :display-data="displayData"
      :is-token-corrupted="isTokenCorrupted"
      :clickable="canOpenFileModal"
      @click="onTokenClicked"
    />
    <VDropdown
      v-else
      class="clickable"
      @show="popupshown"
      @hide="emit('focus-before-node')"
      theme="dropdown-limited-width"
    >
      <TokenNodeView
        :computed-applicability-status="computedApplicabilityStatus"
        :symbol-id="symbolId"
        :should-animate-node="shouldAnimateNode"
        :symbol-applicability="symbolApplicability"
        :hide-applicability="hideApplicability"
        :no-access-to-repo="noAccessToRepo"
        :display-data="displayData"
        :is-token-corrupted="isTokenCorrupted"
        :clickable="false"
        @click="() => {}"
      />
      <template #popper="{ hide, shown }">
        <MultiPopupWorkaround :hide="hide" :shown="shown" />
        <div
          class="applicability-popover-container"
          data-testid="applicability-popover-container"
          v-if="!isTokenCorrupted"
        >
          <div v-if="!isAuthorized" class="change-hunks" data-testid="change-lines-not-authorized">
            <EditorGenericTextLineHunk
              type="Authorize GitHub to view code"
              :is-authorized="isAuthorized"
              :show-file-name="canOpenFileModal"
              :line="{
                path: displayData.path,
                lineNumber: displayData.lineNumber,
                lineData: displayData.lineData,
                wordIndex: displayData.wordIndex,
                repoPrefix,
              }"
              :applicability="symbolApplicability"
              @authorize="$emit('authorize')"
              @show-file="
                () => {
                  hide();
                  showFile();
                }
              "
            />
          </div>
          <div v-else>
            <div v-if="isVerified" class="change-hunks" data-testid="change-lines-verified">
              <EditorGenericTextLineHunk
                type="Token up to date"
                :show-file-name="canOpenFileModal"
                :is-authorized="isAuthorized"
                line-class="verified-line"
                :line="{
                  path: displayData.path,
                  lineNumber: displayData.lineNumber,
                  lineData: displayData.lineData,
                  wordIndex: displayData.wordIndex,
                  repoPrefix,
                }"
                @show-file="
                  () => {
                    hide();
                    showFile();
                  }
                "
                @authorize="$emit('authorize')"
              />
            </div>
            <div v-else-if="noAccessToRepo" class="change-hunks" data-testid="change-lines-no-access">
              <EditorGenericTextLineHunk
                type="No access to the repository"
                :is-authorized="isAuthorized"
                line-class="no-access-line"
                :line="{
                  path: displayData.path,
                  lineNumber: displayData.lineNumber,
                  lineData: displayData.lineData,
                  wordIndex: displayData.wordIndex,
                  repoPrefix,
                }"
                @authorize="$emit('authorize')"
                @show-file="
                  () => {
                    hide();
                    showFile();
                  }
                "
              />
            </div>
            <div v-else-if="isOutdated" class="change-hunks" data-testid="change-lines-outdated">
              <EditorGenericTextLineHunk
                type="Token Outdated"
                :is-authorized="isAuthorized"
                line-class="original-line"
                v-if="originalData.lineData"
                :line="{
                  path: originalData.path,
                  lineNumber: originalData.lineNumber,
                  lineData: originalData.lineData,
                  wordIndex: originalData.wordIndex,
                  repoPrefix,
                }"
                @authorize="$emit('authorize')"
                @show-file="
                  () => {
                    hide();
                    showFile();
                  }
                "
              />
            </div>
            <div v-else-if="isAutosyncable" class="change-hunks" data-testid="change-lines-autosyncable">
              <EditorGenericTextLineHunk
                type="Token Auto-synced"
                :is-authorized="isAuthorized"
                line-class="updated-line"
                :line="{
                  path: displayData.path,
                  lineNumber: displayData.lineNumber,
                  lineData: displayData?.lineData,
                  wordIndex: displayData?.wordIndex,
                  repoPrefix,
                }"
                :original-line="{
                  path: originalData?.path,
                  lineNumber: originalData?.lineNumber,
                  lineData: originalData?.lineData,
                  wordIndex: originalData?.wordIndex,
                  repoPrefix,
                }"
                :show-file-name="canOpenFileModal"
                @show-file="
                  () => {
                    hide();
                    showFile();
                  }
                "
                @authorize="$emit('authorize')"
              />
            </div>
            <div v-if="autosyncedSameText && !shouldHideAutoSyncSameTextMessage" class="same-text-message">
              <div class="message-title">
                <SwText variant="subtitle-L">Why am I seeing this?</SwText>
                <SwText variant="body-S" class="link" @click="$emit('hide-auto-sync-same-text-message')"
                  >Don't show this again</SwText
                >
              </div>
              <SwText variant="body-S"
                ><strong>{{ displayData.text }}</strong> hasn't been changed, but the line has changed
                significantly.</SwText
              >
              <SwText variant="body-S"
                >Please double check to see that it is the same <strong>{{ displayData.text }}</strong> you
                intended.</SwText
              >
            </div>
            <div class="bottom-container">
              <SwText
                variant="body-XS"
                v-if="computedApplicabilityStatus || noAccessToRepo"
                class="applicability-text"
                >{{ applicabilityText }}</SwText
              >
              <div
                v-if="isEditable && computedApplicabilityStatus && applicabilityActions"
                class="popover-actions"
                data-testid="popover-actions"
              >
                <Action
                  data-testid="popover-action"
                  v-for="popverAction in applicabilityActions"
                  :key="popverAction.text"
                  :class="popverAction.class"
                  :size="popverAction.size"
                  :secondary="popverAction.secondary"
                  @click.stop="
                    () => {
                      hide();
                      popverAction.callback();
                    }
                  "
                  >{{ popverAction.text }}</Action
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="change-hunks">
            <EditorCorruptedGenericTextLineHunk @delete="deleteToken({ context: 'corrupted' })" />
          </div>
        </div>
      </template>
    </VDropdown>
  </NodeViewWrapper>
</template>

<script setup lang="ts">
import { NodeViewWrapper } from '@tiptap/vue-3';
import EditorGenericTextLineHunk from './GenericTextLineHunk.vue';
import EditorCorruptedGenericTextLineHunk from './EditorCorruptedGenericTextLine.vue';
import { ApplicabilityStatus } from '@swimm/shared';
import { computed } from 'vue';
import MultiPopupWorkaround from '@/components/EditorComponents/MultiPopupWorkaround.vue';
import { Action, SwText } from '@swimm/ui';
import type { ApplicabilityStatusPropsAndActions, TokenData } from '@/components/EditorComponents/GenericText/consts';
import TokenNodeView from '@/components/EditorComponents/GenericText/TokenNodeView.vue';
import type { OpenFileParam } from '@/types';

const props = withDefaults(
  defineProps<{
    repoId?: string;
    originalData: TokenData;
    displayData: TokenData;
    symbolId: string;
    isSelected: boolean;
    symbolApplicability: string;
    isAuthorized: boolean;
    isCrossRepo: boolean;
    shouldHideAutoSyncSameTextMessage: boolean;
    isEditable: boolean;
    branch: string;
    repoName: string;
    repoFullName?: string;
    isRepoInWorkspace: boolean;
    markAsVerified: () => void;
    editToken: () => void;
    deleteToken: ({ context }: { context: string }) => void;
    repoIcon?: string;
    popupshown: () => void;
    shouldAnimateNode: () => boolean;
    hideApplicability?: boolean;
    skipPreview?: boolean;
  }>(),
  {
    hideApplicability: false,
    skipPreview: false,
  }
);

const emit = defineEmits<{
  (e: 'open-file', param: OpenFileParam): void;
  (e: 'hide-auto-sync-same-text-message'): void;
  (e: 'authorize'): void;
  (e: 'focus-before-node'): void;
}>();

const applicabilityStatus: Record<string, ApplicabilityStatusPropsAndActions> = {
  [ApplicabilityStatus.Verified]: {
    icon: 'updated',
    text: 'Token matches the current state of your code.',
    actions: [
      {
        secondary: true,
        text: 'Edit',
        class: 'modify-button',
        callback: () => props.editToken(),
        size: 'small',
      },
      {
        secondary: true,
        text: 'Unlink',
        class: 'modify-button',
        callback: () => props.deleteToken({ context: ApplicabilityStatus.Verified }),
        size: 'small',
        isDeleteAction: true,
      },
    ],
  },
  [ApplicabilityStatus.Autosyncable]: {
    icon: 'sync',
    text: 'Code changed but token is auto-synced.',
    actions: [
      {
        text: 'Accept',
        class: '',
        callback: () => props.markAsVerified(),
        size: 'small',
      },
      {
        secondary: true,
        text: 'Edit',
        class: 'modify-button',
        callback: () => props.editToken(),
        size: 'small',
      },
      {
        secondary: true,
        text: 'Unlink',
        class: 'modify-button',
        callback: () => props.deleteToken({ context: ApplicabilityStatus.Autosyncable }),
        size: 'small',
        isDeleteAction: true,
      },
    ],
  },
  [ApplicabilityStatus.Outdated]: {
    icon: 'warning',
    text: 'Code changed and token is now deprecated.',
    actions: [
      {
        secondary: true,
        text: 'Edit',
        class: 'modify-button',
        callback: () => props.editToken(),
        size: 'small',
      },
      {
        secondary: true,
        text: 'Unlink',
        class: 'modify-button',
        callback: () => props.deleteToken({ context: ApplicabilityStatus.Outdated }),
        size: 'small',
        isDeleteAction: true,
      },
    ],
  },
  // Unknown is set temporarily on symbols copied from another doc - autosync will run when it detects such
  // tokens and correct the applicability - so we show a loader here
  [ApplicabilityStatus.Unknown]: {
    icon: 'sync',
    text: 'Checking token status...',
  },
};

const computedApplicabilityStatus = computed(() => {
  if (props.symbolApplicability) {
    return applicabilityStatus[props.symbolApplicability];
  }
  return undefined;
});

const repoPrefix = computed(() => {
  return {
    repoId: props.repoId,
    name: props.repoName,
    branch: props.branch,
    iconName: props.repoIcon,
    fullName: props.repoFullName,
  };
});

const noAccessToRepo = computed(() => {
  if (!props.isAuthorized) {
    return false;
  }

  return props.isCrossRepo && !props.branch;
});

if (props.symbolApplicability === ApplicabilityStatus.Autosyncable) {
  if (props.originalData.text && props.originalData.text !== props.displayData.text) {
    applicabilityStatus[
      ApplicabilityStatus.Autosyncable
    ].tooltip = `This code was changed from <code>${props.originalData.text}</code> -> <code>${props.displayData.text}</code>`;
  }
}

const applicabilityActions = computed(() => {
  let result = computedApplicabilityStatus.value?.actions;
  // if repo not in workspace - allow only delete
  if (!props.isRepoInWorkspace && result) {
    result = result.filter((action) => action.isDeleteAction);
  }
  return result;
});

const isTokenCorrupted = computed(() => {
  return props.symbolApplicability === ApplicabilityStatus.Invalid;
});

const applicabilityText = computed(() => {
  if (noAccessToRepo.value) {
    return "You don't have access to this repo";
  }
  return computedApplicabilityStatus.value?.text;
});

const isOutdated = computed(() => {
  return props.symbolApplicability === ApplicabilityStatus.Outdated;
});
const isVerified = computed(() => {
  return props.symbolApplicability === ApplicabilityStatus.Verified;
});
const isAutosyncable = computed(() => {
  return props.symbolApplicability === ApplicabilityStatus.Autosyncable;
});

const autosyncedSameText = computed(() => {
  return isAutosyncable.value && props.displayData.text === props.originalData.text;
});

const canOpenFileModal = computed(() => !isOutdated.value && !noAccessToRepo.value);

async function showFile() {
  if (canOpenFileModal.value) {
    emit('open-file', {
      repoId: props.repoId ?? '',
      path: props.displayData.path,
      revision: props.branch,
      revealLines: { start: props.displayData.lineNumber || 0, end: props.displayData.lineNumber || 0 },
    });
  }
}

const onTokenClicked = () => {
  if (props.skipPreview) {
    showFile();
  } else {
    emit('focus-before-node');
  }
};
</script>

<style scoped lang="postcss">
.sym-text {
  align-items: center;
  padding: var(--space-xs) 0 2px !important;
  display: inline !important; /* This allows the user to place the cursor before this node if it is first in the line. */
  background-color: transparent; /* override the background color of 'code' elements. The actual bg color is set in the elements below, so we set it as transparent here. */
}

:deep(.sym-text) {
  &.selected .container .text-node {
    background-color: transparent;
    color: var(--text-color-selection);
  }

  &.selected {
    background-color: var(--color-selection);
    border-radius: 0;
  }
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-base);
}

.sym-text ::selection {
  background-color: transparent;
}

.sym-text ::-moz-selection {
  background-color: transparent;
}

.v-popper {
  display: inline;
}

.applicability-popover-container {
  --side-padding: 15px;
  padding: var(--space-base) 0;
}

.applicability-popover-container .applicability-text {
  color: var(--text-color-secondary);
  padding: 0 var(--side-padding);
  align-self: center;
}

.applicability-popover-container .popover-actions {
  padding: 0 var(--side-padding);
}

.applicability-popover-container .popover-actions .modify-button {
  margin-left: 10px;
  border-color: var(--text-color-secondary);
  color: var(--text-color-secondary);
  padding: var(--space-xs) var(--space-base) var(--space-xs) var(--space-base);
}

:deep(.has-focus) {
  .container.verified {
    border-radius: 3px;
    outline: 1px solid var(--text-color-success-strong);
  }

  .container.autosyncable {
    border-radius: 3px;
    outline: 1px solid var(--text-color-magic-strong);
  }

  .container.outdated {
    border-radius: 3px;
    outline: 1px solid var(--color-border-danger);
  }
}

.same-text-message {
  margin: var(--space-base) var(--side-padding);
  padding: 8px;
  background-color: var(--color-status-magic);
  border: 1px solid var(--color-border-default-subtle);
  border-radius: 4px;

  .message-title {
    display: flex;
    justify-content: space-between;

    .link {
      color: var(--text-color-secondary);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
