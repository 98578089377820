import { TiktokenModel } from 'js-tiktoken';
import { objectUtils } from '../shared';

export interface OpenAIModelConfig {
  deploymentUrl?: string;
  maxTokenCount: string;
  usdPer1000InputTokens: string;
  usdPer1000OutputTokens: string;
  tiktokenModelName: TiktokenModel;
}

export const OpenAIModelKeys = ['GPT_4_SHORT', 'GPT_4_LONG', 'GPT_3_5', 'GPT_3_5_TI'] as const;
export type OpenAIModelName = (typeof OpenAIModelKeys)[number];
export const OPEN_AI_SUPPORTED_MODELS: OpenAIModelName[] = ['GPT_4_SHORT', 'GPT_4_LONG', 'GPT_3_5', 'GPT_3_5_TI'];

export enum OpenAIModelEnvVariables {
  API_KEY = 'API_KEY',
  CLIENT_ID = 'CLIENT_ID',
  CERTIFICATE_PATH = 'CERTIFICATE_PATH',
  TENANT_ID = 'TENANT_ID',
  DEPLOYMENT_URL = 'DEPLOYMENT_URL',
  MAX_TOKEN_COUNT = 'MAX_TOKEN_COUNT',
  USD_PER_1000_OUTPUT_TOKENS = 'USD_PER_1000_OUTPUT_TOKENS', // Optional
  USD_PER_1000_INPUT_TOKENS = 'USD_PER_1000_INPUT_TOKENS', // Optional
  MODEL = 'MODEL', // Optional override (We used to have one called TIKETOKEN_MODEL_NAME... but the model is also passed in the API... but ignored by Azure...)
}

export const OPEN_AI_MODEL_ENV_VARIABLES = [
  OpenAIModelEnvVariables.API_KEY,
  OpenAIModelEnvVariables.DEPLOYMENT_URL,
  OpenAIModelEnvVariables.MAX_TOKEN_COUNT,
];

const DEFAULT_OPEN_AI_MODEL_VALUES: Record<OpenAIModelName, OpenAIModelConfig> = {
  GPT_3_5: {
    maxTokenCount: '16384',
    usdPer1000InputTokens: '0.003',
    usdPer1000OutputTokens: '0.004',
    tiktokenModelName: 'gpt-3.5-turbo',
  },
  GPT_4_SHORT: {
    maxTokenCount: '8192',
    usdPer1000InputTokens: '0.03',
    usdPer1000OutputTokens: '0.06',
    tiktokenModelName: 'gpt-4-0613',
  },
  GPT_4_LONG: {
    maxTokenCount: '64768',
    usdPer1000InputTokens: '0.06',
    usdPer1000OutputTokens: '0.12',
    tiktokenModelName: 'gpt-4o',
  },
  // this model GPT_3_5_TI is used only for code completions
  GPT_3_5_TI: {
    maxTokenCount: '16384',
    usdPer1000InputTokens: '0.003',
    usdPer1000OutputTokens: '0.004',
    tiktokenModelName: 'gpt-3.5-turbo-instruct',
  },
};

export const openAIModelValues: Record<OpenAIModelName, OpenAIModelConfig> =
  objectUtils.deepClone(DEFAULT_OPEN_AI_MODEL_VALUES);

export function getMaxContextTokenCountForModel(model: OpenAIModelName): number {
  return parseFloat(openAIModelValues[model].maxTokenCount);
}

export function isOpenAIModelName(model: string): model is OpenAIModelName {
  return model in openAIModelValues;
}

export function overrideOpenAIModelValues(config: Record<OpenAIModelName, Partial<OpenAIModelConfig>>) {
  for (const model in DEFAULT_OPEN_AI_MODEL_VALUES) {
    openAIModelValues[model] = {
      ...DEFAULT_OPEN_AI_MODEL_VALUES[model],
      ...(model in config ? config[model] : {}),
    };
  }
}

export type GetOpenAIInstanceOptions = {
  apiVersion?: string;
};
