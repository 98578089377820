/**
 * These are all the languages the CTags support.
 * This can be produced by running `ctags --list-languages`.
 */
export enum CTagLanguage {
  ABAQUS = 'Abaqus',
  ABC = 'Abc',
  ADA = 'Ada',
  ANSIBLEPLAYBOOK = 'AnsiblePlaybook',
  ANT = 'Ant',
  ASCIIDOC = 'Asciidoc',
  ASM = 'Asm',
  ASP = 'Asp',
  AUTOCONF = 'Autoconf',
  AUTOIT = 'AutoIt',
  AUTOMAKE = 'Automake',
  AWK = 'Awk',
  BASIC = 'Basic',
  BETA = 'BETA',
  BIBTEX = 'BibTeX',
  C = 'C',
  'C#' = 'C#',
  'C++' = 'C++',
  CLOJURE = 'Clojure',
  CMAKE = 'CMake',
  COBOL = 'Cobol',
  COBOLFREE = 'CobolFree',
  COBOLVARIABLE = 'CobolVariable',
  CPREPROCESSOR = 'CPreProcessor',
  CSS = 'CSS',
  CTAGS = 'Ctags',
  CUDA = 'CUDA',
  D = 'D',
  DBUSINTROSPECT = 'DBusIntrospect',
  DIFF = 'Diff',
  DOSBATCH = 'DosBatch',
  DTD = 'DTD',
  DTS = 'DTS',
  EIFFEL = 'Eiffel',
  ELIXIR = 'Elixir',
  ELM = 'Elm',
  EMACSLISP = 'EmacsLisp',
  ERLANG = 'Erlang',
  FALCON = 'Falcon',
  FLEX = 'Flex',
  FORTRAN = 'Fortran',
  FUNCTIONPARAMETERS = 'FunctionParameters',
  FYPP = 'Fypp',
  GDBINIT = 'Gdbinit',
  GDSCRIPT = 'GDScript',
  GEMSPEC = 'GemSpec',
  GLADE = 'Glade',
  GO = 'Go',
  HASKELL = 'Haskell',
  HAXE = 'Haxe',
  HTML = 'HTML',
  INICONF = 'Iniconf',
  INKO = 'Inko',
  IPYTHONCELL = 'IPythonCell',
  ITCL = 'ITcl',
  JAVA = 'Java',
  JAVAPROPERTIES = 'JavaProperties',
  JAVASCRIPT = 'JavaScript',
  JSON = 'JSON',
  JULIA = 'Julia',
  KCONFIG = 'Kconfig',
  KOTLIN = 'Kotlin',
  LDSCRIPT = 'LdScript',
  LEX = 'LEX',
  LISP = 'Lisp',
  LITERATEHASKELL = 'LiterateHaskell',
  LUA = 'Lua',
  M4 = 'M4',
  MAKE = 'Make',
  MAN = 'Man',
  MARKDOWN = 'Markdown',
  MATLAB = 'MatLab',
  MAVEN2 = 'Maven2',
  MESON = 'Meson',
  MESONOPTIONS = 'MesonOptions',
  MOOSE = 'Moose',
  MYRDDIN = 'Myrddin',
  NSIS = 'NSIS',
  OBJECTIVEC = 'ObjectiveC',
  OCAML = 'OCaml',
  OPENAPI = 'OpenAPI',
  ORG = 'Org',
  PASCAL = 'Pascal',
  PASSWD = 'Passwd',
  PERL = 'Perl',
  PERL6 = 'Perl6',
  PHP = 'PHP',
  PLISTXML = 'PlistXML',
  POD = 'Pod',
  POWERSHELL = 'PowerShell',
  PROTOBUF = 'Protobuf',
  PUPPETMANIFEST = 'PuppetManifest',
  PYTHON = 'Python',
  PYTHONLOGGINGCONFIG = 'PythonLoggingConfig',
  QEMUHX = 'QemuHX',
  QTMOC = 'QtMoc',
  R = 'R',
  R6CLASS = 'R6Class',
  RELAXNG = 'RelaxNG',
  RESTRUCTUREDTEXT = 'ReStructuredText',
  REXX = 'REXX',
  ROBOT = 'Robot',
  RPMMACROS = 'RpmMacros',
  RPMSPEC = 'RpmSpec',
  RSPEC = 'RSpec',
  RUBY = 'Ruby',
  RUST = 'Rust',
  S4CLASS = 'S4Class',
  SCALA = 'Scala',
  SCHEME = 'Scheme',
  SCSS = 'SCSS',
  SH = 'Sh',
  SLANG = 'SLang',
  SML = 'SML',
  SQL = 'SQL',
  SVG = 'SVG',
  SYSTEMDUNIT = 'SystemdUnit',
  SYSTEMTAP = 'SystemTap',
  SYSTEMVERILOG = 'SystemVerilog',
  TCL = 'Tcl',
  TCLOO = 'TclOO',
  TEX = 'Tex',
  TEXBEAMER = 'TeXBeamer',
  THRIFT = 'Thrift',
  TTCN = 'TTCN',
  TXT2TAGS = 'Txt2tags',
  TYPESCRIPT = 'TypeScript',
  TYPESCRIPTX = 'TypeScriptX',
  UNKNOWN = 'Unknown',
  VARLINK = 'Varlink',
  VERA = 'Vera',
  VERILOG = 'Verilog',
  VHDL = 'VHDL',
  VIM = 'Vim',
  WINDRES = 'WindRes',
  XML = 'XML',
  XSLT = 'XSLT',
  YACC = 'YACC',
  YAML = 'Yaml',
  YUMREPO = 'YumRepo',
  ZEPHIR = 'Zephir',
}

export enum CTagMetaType {
  TAG = 'tag',
  PTAG = 'ptag',
}

/**
 * These are all the possible values for CTAG's kind.
 * This can be produced by running `ctags --list-kinds-full`.
 */
export type CTagKind =
  | 'accelerators'
  | 'accessor'
  | 'activeBindingFunc'
  | 'alias'
  | 'altstep'
  | 'anchor'
  | 'annotation'
  | 'anon'
  | 'anonMember'
  | 'architecture'
  | 'array'
  | 'article'
  | 'assembly'
  | 'assert'
  | 'attribute'
  | 'augroup'
  | 'autovar'
  | 'benchmark'
  | 'bibitem'
  | 'bitmap'
  | 'block'
  | 'blockData'
  | 'book'
  | 'booklet'
  | 'boolean'
  | 'build'
  | 'C#'
  | 'C++'
  | 'callback'
  | 'category'
  | 'ccflag'
  | 'cell'
  | 'chapter'
  | 'checker'
  | 'choice'
  | 'citation'
  | 'class'
  | 'clocking'
  | 'combo'
  | 'command'
  | 'common'
  | 'component'
  | 'cond'
  | 'condition'
  | 'conference'
  | 'config'
  | 'const'
  | 'constant'
  | 'constraint'
  | 'constructor'
  | 'Constructor'
  | 'context'
  | 'counter'
  | 'covergroup'
  | 'cursor'
  | 'custom'
  | 'data'
  | 'database'
  | 'dataframe'
  | 'define'
  | 'definition'
  | 'delegate'
  | 'deletedFile'
  | 'derivedMode'
  | 'describe'
  | 'dialog'
  | 'division'
  | 'document'
  | 'domain'
  | 'edesc'
  | 'element'
  | 'entity'
  | 'entry'
  | 'entryspec'
  | 'enum'
  | 'enumConstant'
  | 'enumerator'
  | 'error'
  | 'event'
  | 'exception'
  | 'Exception'
  | 'externvar'
  | 'face'
  | 'fd'
  | 'feature'
  | 'field'
  | 'filename'
  | 'font'
  | 'formal'
  | 'format'
  | 'fragment'
  | 'framesubtitle'
  | 'frametitle'
  | 'fun'
  | 'func'
  | 'function'
  | 'functionVar'
  | 'functor'
  | 'generator'
  | 'generic'
  | 'getter'
  | 'global'
  | 'globalVar'
  | 'grammar'
  | 'group'
  | 'guard'
  | 'handler' // We added this for our queries
  | 'header'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heredoc'
  | 'hunk'
  | 'icon'
  | 'id'
  | 'identifier'
  | 'ifclass'
  | 'implementation'
  | 'import'
  | 'inbook'
  | 'incollection'
  | 'index'
  | 'infoitem'
  | 'inline'
  | 'inproceedings'
  | 'inputSection'
  | 'instance'
  | 'integer'
  | 'interface'
  | 'iparam'
  | 'kconfig'
  | 'key'
  | 'keyword'
  | 'kind'
  | 'l4subsection'
  | 'l5subsection'
  | 'label'
  | 'langdef'
  | 'langstr'
  | 'library'
  | 'list'
  | 'literal'
  | 'local'
  | 'localvar'
  | 'localVariable'
  | 'loggerSection'
  | 'ltlibrary'
  | 'macro'
  | 'macrofile'
  | 'macroparam'
  | 'macroParameter'
  | 'mainMenu'
  | 'makefile'
  | 'man'
  | 'manual'
  | 'map'
  | 'mastersthesis'
  | 'member'
  | 'menu'
  | 'message'
  | 'method'
  | 'methodSpec'
  | 'minorMode'
  | 'misc'
  | 'mixin'
  | 'mlconn'
  | 'mlprop'
  | 'mltable'
  | 'modifiedFile'
  | 'modport'
  | 'module'
  | 'modulepar'
  | 'mxtag'
  | 'nameattr'
  | 'namelist'
  | 'namespace'
  | 'net'
  | 'nettype'
  | 'newFile'
  | 'node'
  | 'notation'
  | 'null'
  | 'number'
  | 'object'
  | 'OldC++'
  | 'oneof'
  | 'oparam'
  | 'operator'
  | 'optenable'
  | 'option'
  | 'optwith'
  | 'package'
  | 'packageName'
  | 'packspec'
  | 'paragraph'
  | 'param'
  | 'parameter'
  | 'parameterEntity'
  | 'part'
  | 'patch'
  | 'pattern'
  | 'phandler'
  | 'phdthesis'
  | 'pkg'
  | 'placeholder'
  | 'port'
  | 'probe'
  | 'procedure'
  | 'proceedings'
  | 'process'
  | 'program'
  | 'project'
  | 'property'
  | 'protected'
  | 'protectspec'
  | 'protocol'
  | 'protodef'
  | 'prototype'
  | 'publication'
  | 'qmp'
  | 'qualname'
  | 'receiver'
  | 'record'
  | 'RecordField'
  | 'regex'
  | 'region'
  | 'register'
  | 'reopen'
  | 'repoid'
  | 'repr'
  | 'resource'
  | 'role'
  | 'rpc'
  | 'rule'
  | 'run'
  | 'schema'
  | 'script'
  | 'section'
  | 'sectionGroup'
  | 'selector'
  | 'sequence'
  | 'service'
  | 'set'
  | 'setter'
  | 'signal'
  | 'signature'
  | 'singletonMethod'
  | 'slot'
  | 'source'
  | 'sourcefile'
  | 'step'
  | 'string'
  | 'struct'
  | 'structure'
  | 'stylesheet'
  | 'subdir'
  | 'submethod'
  | 'submodule'
  | 'subparagraph'
  | 'subprogram'
  | 'subprogspec'
  | 'subroutine'
  | 'subroutineDeclaration'
  | 'subsection'
  | 'subspec'
  | 'subst'
  | 'substdef'
  | 'subsubsection'
  | 'subtype'
  | 'symbol'
  | 'synonym'
  | 'table'
  | 'tag'
  | 'talias'
  | 'target'
  | 'task'
  | 'taskspec'
  | 'techreport'
  | 'template'
  | 'test'
  | 'testcase'
  | 'theme'
  | 'thriftFile'
  | 'throwsparam'
  | 'timer'
  | 'title'
  | 'token'
  | 'toplevelVariable'
  | 'trait'
  | 'trigger'
  | 'type'
  | 'typealias'
  | 'typedef'
  | 'typespec'
  | 'union'
  | 'unit'
  | 'unknown'
  | 'unpublished'
  | 'username'
  | 'val'
  | 'value'
  | 'var'
  | 'varalias'
  | 'variable'
  | 'varlambda'
  | 'varspec'
  | 'vector'
  | 'version'
  | 'view'
  | 'virtual'
  | 'vresource'
  | 'wrapper'
  | 'xinput';

/**
 * These are all the possible values for CTAG's roles.
 * This can be produced by running `ctags --list-roles`.
 */
export type CTagRoles =
  | 'subunit'
  | 'placement'
  | 'local'
  | 'system'
  | 'cmdline'
  | 'cmdline'
  | 'branched'
  | 'data'
  | 'library'
  | 'ltlibrary'
  | 'man'
  | 'program'
  | 'script'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'copied'
  | 'copied'
  | 'copied'
  | 'attOwner'
  | 'condition'
  | 'elementName'
  | 'partOfAttDef'
  | 'imported'
  | 'import'
  | 'extended'
  | 'imported'
  | 'receiverType'
  | 'extFile'
  | 'extFile'
  | 'attribute'
  | 'imported'
  | 'imported'
  | 'namespace'
  | 'used'
  | 'imported'
  | 'used'
  | 'source'
  | 'discarded'
  | 'mapped'
  | 'entrypoint'
  | 'referenced'
  | 'included'
  | 'sincluded'
  | 'undef'
  | 'included'
  | 'optional'
  | 'included'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'condition'
  | 'undef'
  | 'local'
  | 'system'
  | 'unused'
  | 'used'
  | 'endmarker'
  | 'imported'
  | 'extension'
  | 'imported'
  | 'indirectlyImported'
  | 'namespace'
  | 'imported'
  | 'indirectlyImported'
  | 'library'
  | 'require'
  | 'source'
  | 'undef'
  | 'decl'
  | 'loaded'
  | 'required'
  | 'requiredRel'
  | 'endmarker'
  | 'loaded'
  | 'After'
  | 'Before'
  | 'RequiredBy'
  | 'Requires'
  | 'WantedBy'
  | 'Wants'
  | 'bibliography'
  | 'included'
  | 'input'
  | 'included'
  | 'desigend'
  | 'condition'
  | 'undef'
  | 'local';
