import { flatten } from 'lodash-es';
import { ref } from 'vue';

import type { TokenSuggestion } from '@swimm/shared';
import { getLoggerNew } from '@swimm/shared';
import type { FileTokensIndex } from './fileTokensIndex';
import { tokenize } from '@/components/EditorComponents/GenericText/tokenizer';
import { type TokenSuggestionsMap, toTokenSuggestionsMap } from './staticAnalysisIndex';

const logger = getLoggerNew(__modulename);

export function useFileTokensIndex(
  fileGetter: (repoId: string, path: string) => Promise<string | undefined>
): FileTokensIndex {
  // we have 3 maps here:
  // loadingPromises is for the promises of the inner load to prevent 2 calls in parallel
  // index holds for each key (repoId+path) list of all suggestions from this file
  // maps holds for each key (repoId+path) map of suggestions keyd by their token
  // maps and index are build and deleted together

  const loadingPromises = new Map<string, Promise<TokenSuggestion[]>>();
  const index = ref(new Map<string, TokenSuggestion[]>());
  const maps = ref(new Map<string, TokenSuggestionsMap>());

  const load = async (repoId: string, path: string, options?: { force?: boolean }): Promise<TokenSuggestion[]> => {
    const { force } = Object.assign({}, { force: false }, options);
    const key = composeIndexKey(repoId, path);
    if (force) {
      loadingPromises.delete(key);
      index.value.delete(key);
      maps.value.delete(key);
    }
    if (!index.value.has(key)) {
      const curPromise = loadingPromises.get(key) ?? innerLoad(repoId, path);
      loadingPromises.set(key, curPromise);
      const suggestions = await curPromise;
      index.value.set(key, suggestions);
      maps.value.set(key, toTokenSuggestionsMap(suggestions));
    }
    return index.value.get(key) ?? [];
  };

  const get = (repoId: string, path: string): { loading: boolean; suggestions: TokenSuggestion[] } => {
    const indexed = index.value.get(composeIndexKey(repoId, path));
    if (indexed == null) {
      load(repoId, path);
      return { loading: true, suggestions: [] };
    }
    return { loading: false, suggestions: indexed };
  };

  const getAsync = async (repoId: string, path: string, token: string): Promise<TokenSuggestion[]> => {
    const key = composeIndexKey(repoId, path);
    if (!maps.value.has(key)) {
      await load(repoId, path);
    }
    return maps.value.get(key)?.get(token) ?? [];
  };

  const clear = (): void => {
    index.value.clear();
    maps.value.clear();
    loadingPromises.clear();
  };

  const innerLoad = async (repoId: string, path: string): Promise<TokenSuggestion[]> => {
    try {
      const fileContent: string | undefined = await fileGetter(repoId, path);
      if (fileContent == null) {
        logger.error({ repoId }, 'Failed to get file content');
        return [];
      }
      return tokenizeFile(repoId, path, fileContent);
    } catch (err) {
      logger.error({ err, repoId }, 'Failed to tokenize file');
      return [];
    }
  };

  const composeIndexKey = (repoId: string, path: string) => `${repoId}-${path}`;

  const tokenizeFile = (repoId: string, path: string, content: string): TokenSuggestion[] => {
    const contentLines = content.split('\n');
    const tokenSuggestionsPerLine: TokenSuggestion[][] = contentLines.map<TokenSuggestion[]>((line, index) => {
      const tokens = tokenize(line);
      return tokens.map((token) => ({
        token: token.text,
        position: {
          path,
          line: index + 1, // Line numbers are 1-based
          wordStart: token.index,
          wordEnd: token.index,
        },
        lineData: line,
        repoId,
        static: false,
      }));
    });
    return flatten(tokenSuggestionsPerLine);
  };

  return { load, get, getAsync, clear };
}
