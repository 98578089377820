export interface CreationHubOptions {
  folderId?: string;
  preventSgdInNonDefaultBranch?: boolean;
  limitedShownItems?: CreationHubItemId[];
  showImportSection?: boolean;
  keepAfterRouteChange?: boolean;
  showRepoDropdown?: boolean;
  newDocClick?: ({ query, repoId, branch, folderId, sgdTemplateId }) => void;
  newPlaylistClick?: ($event) => void;
  newProcessClick?: ($event) => void;
}

export const CreationHubSections = {
  GENERAL: 0,
  TEMPLATES: 1,
  IMPORT: 2,
  PROCESS: 3,
};

export const ItemIds = {
  BLANK_DOC: 'blankDoc',
  PLAYLIST: 'playlist',
  PULL_REQUEST: 'pullRequest',
  SNIPPETS: 'snippets',
  IMPORT_MD_FILES: 'importMdFiles',
  PROCESS: 'process',
};

export type CreationHubItemId = (typeof ItemIds)[keyof typeof ItemIds];
