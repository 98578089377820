import type { SwimmEditorServices } from '@/tiptap/editorServices';
import { Transform } from '@tiptap/pm/transform';
import type { Editor, JSONContent } from '@tiptap/vue-3';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { schema } from '../../swmd/extensions';
import { SwimmDocumentSuggestionSorter } from './SwimmDocumentSuggestionSorter';
import { useTextSuggestions } from './suggest';
import { SwimmportDecorationsHandler } from './docTraverse';
import { removePrefix } from '@swimm/shared';

export function swimmify(swimmEditorServices: SwimmEditorServices, editor: Editor) {
  const suggestionSorter = new SwimmDocumentSuggestionSorter(swimmEditorServices);
  const textSuggestionsService = useTextSuggestions(
    swimmEditorServices,
    () => triggerApplySuggestion(decorationsHandler, editor),
    true
  );

  const decorationsHandler = new SwimmportDecorationsHandler(
    textSuggestionsService.getSuggestionsForText,
    suggestionSorter
  );
}

let applyingSuggestions = false;
let pendingApplySuggestion = false;

function triggerApplySuggestion(decorationsHandler: SwimmportDecorationsHandler, editor: Editor) {
  if (applyingSuggestions) {
    pendingApplySuggestion = true;
    return;
  }
  applyingSuggestions = true;
  doApplySuggestions(decorationsHandler, editor).then(() => {
    applyingSuggestions = false;
    if (pendingApplySuggestion) {
      pendingApplySuggestion = false;
      triggerApplySuggestion(decorationsHandler, editor);
    }
  });
}

async function doApplySuggestions(decorationsHandler: SwimmportDecorationsHandler, editor: Editor) {
  const transform = new Transform(ProseMirrorNode.fromJSON(schema, editor.view.state.doc.toJSON()));

  const suggestions = await decorationsHandler.calculateSuggestions(transform.doc);
  const chain = editor.chain();
  chain.focus().setSourceExternal();

  for (const suggestion of suggestions) {
    const bestMatch = suggestion.result;

    if (bestMatch) {
      // Do not swimmify a single punctuation mark
      if (bestMatch.type === 'token') {
        if (bestMatch.suggestions[0].token.match(/^[\s.,;:]$/)) {
          return;
        }
        const tokenSuggestion = bestMatch.suggestions[0];

        chain
          .command(({ tr, commands }) => {
            return commands.deleteRange({ from: tr.mapping.map(suggestion.from), to: tr.mapping.map(suggestion.to) });
          })
          .command(({ tr, commands }) => {
            return commands.focus(tr.mapping.map(suggestion.from));
          })
          .insertSwmToken(
            tokenSuggestion.token,
            `/${removePrefix(tokenSuggestion.position.path, '/')}`,
            tokenSuggestion.position,
            tokenSuggestion.lineData,
            tokenSuggestion.repoId
          );
      } else {
        const pathSuggestion = bestMatch.suggestions[0];

        if (pathSuggestion.repoId) {
          chain
            .command(({ tr, commands }) => {
              return commands.deleteRange({ from: tr.mapping.map(suggestion.from), to: tr.mapping.map(suggestion.to) });
            })
            .command(({ tr, commands }) => {
              return commands.focus(tr.mapping.map(suggestion.from));
            })
            .insertSwmPath(pathSuggestion.path, pathSuggestion.repoId, pathSuggestion.type);
        }
      }
    }
  }
  chain.run();
}

// The reason for using a contentWrapper is that we need to pass the content by reference,
// since applySuggestions modifies the content in place, and is called multiple times inside textSuggestionsService.
// We need to refactor this - https://app.clickup.com/t/86bzp1y1t
export async function swimmifyContent(
  contentWrapper: { content: JSONContent },
  swimmEditorServices: SwimmEditorServices
): Promise<JSONContent> {
  const tr = new Transform(ProseMirrorNode.fromJSON(schema, contentWrapper.content));

  const suggestionSorter = new SwimmDocumentSuggestionSorter(swimmEditorServices);
  // No need to trigger re-run
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const textSuggestionsService = useTextSuggestions(swimmEditorServices, () => {}, true);

  const decorationsHandler = new SwimmportDecorationsHandler(
    textSuggestionsService.getSuggestionsForText,
    suggestionSorter
  );
  // trigger the apply suggestions
  await applySuggestionsOnContent(
    decorationsHandler,
    tr,
    contentWrapper,
    swimmEditorServices.repoId.value,
    swimmEditorServices.getRepoName(swimmEditorServices.repoId.value) || ''
  );

  return contentWrapper.content;
}

export async function applySuggestionsOnContent(
  decorationsHandler: SwimmportDecorationsHandler,
  tr: Transform,
  contentWrapper: { content: JSONContent },
  repoId: string,
  repoName: string,
  addSelectedFileFuncion?: (filePath: string) => void
) {
  const suggestions = await decorationsHandler.calculateSuggestions(tr.doc);

  for (const suggestion of suggestions) {
    const bestMatch = suggestion.result;
    const bestMatchRange = { from: suggestion.from, to: suggestion.to };

    if (bestMatch) {
      // Do not swimmify a single punctuation mark
      if (bestMatch.type === 'token') {
        if (bestMatch.suggestions[0].token.match(/^[\s.,;:]$/)) {
          return;
        }
        const node = schema.node('swmToken', {
          token: bestMatch.suggestions[0].token,
          path: bestMatch.suggestions[0].position.path,
          pos: {
            line: bestMatch.suggestions[0].position.line,
            wordStart: bestMatch.suggestions[0].position.wordStart,
            wordEnd: bestMatch.suggestions[0].position.wordEnd,
          },
          lineData: bestMatch.suggestions[0].lineData,
          repoId,
          repoName,
        });

        tr.replaceRangeWith(tr.mapping.map(bestMatchRange.from), tr.mapping.map(bestMatchRange.to), node);
        addSelectedFileFuncion?.(bestMatch.suggestions[0].position.path);
      } else {
        const swmPath =
          bestMatch.suggestions[0].type === 'directory'
            ? `${bestMatch.suggestions[0].path}/`
            : `${bestMatch.suggestions[0].path}`;
        const node = schema.node('swmPath', {
          href: encodeURI(swmPath),
          repoId: repoId,
          repoName: repoName,
        });

        tr.replaceRangeWith(tr.mapping.map(bestMatchRange.from), tr.mapping.map(bestMatchRange.to), node);
        if (bestMatch.suggestions[0].type === 'file') {
          addSelectedFileFuncion?.(bestMatch.suggestions[0].path);
        }
      }

      contentWrapper.content = tr.doc.toJSON();
    }
  }
}
