<script setup lang="ts">
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import swal from 'sweetalert';
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import UsersInviteSection from '@/modules/workspace/modals/settings/users/UsersInviteSection.vue';
import UsersTableSection from '@/modules/workspace/modals/settings/users/UsersTableSection.vue';
import {
  Licenses,
  type User,
  billingPlanTypes,
  type commonTypes,
  config,
  eventLogger,
  getLoggerNew,
  getUserEntitlementByLicense,
  productEvents,
} from '@swimm/shared';
import { StatusCodes } from 'http-status-codes';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useStigg } from '@/common/composables/useStigg';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { swimmApi } from '@/common/swimm-backend-client';
import { AxiosError } from 'axios';

const logger = getLoggerNew(__modulename);

const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { logEvent } = useSwimmEventLogs();
const { stiggClient } = useStigg();

const showPaywall = ref(false);
const sendInviteInProgress = ref(false);

const { user: currentUser } = storeToRefs(useAuthStore());
const isCurrentUserWorkspaceAdmin = computed(() =>
  store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, currentUser.value.uid)
);

const workspaceData = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId));
const deletedUsers = computed(
  () => store.getters['database/db_getWorkspaceDeletedUsers'](route.params.workspaceId) as User[]
);
const workspaceLicense = computed(() => store.getters['database/db_getWorkspaceLicense'](route.params.workspaceId));

const usersCount = computed(() => {
  const totalWithoutDeleted =
    (workspaceData.value?.invites?.length || 0) + (workspaceData.value?.counter_workspace_users || 0);
  if (!workspaceLicense.value || workspaceLicense.value === billingPlanTypes.FREE) {
    return totalWithoutDeleted + Object.keys(deletedUsers.value || {}).length;
  }

  return totalWithoutDeleted;
});

const subtitle = computed(() =>
  usersCount.value && usersCount.value > 1 ? `— ${usersCount.value} users` : `— 1 user`
);

const usersReceivedInvite = computed(() => workspaceData.value.invites || []);
const isFreePlan = computed(() => workspaceLicense.value === Licenses.FREE);

function getTotalSeatsRequested(emails) {
  const uniqueEmails = new Set([...usersReceivedInvite.value, ...emails]);
  if (isFreePlan.value) {
    Object.values(deletedUsers.value).forEach((user) => uniqueEmails.add(user.email));
  }
  return uniqueEmails.size;
}

async function checkUserLimit(emails) {
  const totalUserAmount = getTotalSeatsRequested(emails);
  const fallbackCount = workspaceData.value.counter_workspace_users + totalUserAmount;
  const featureId = getUserEntitlementByLicense(workspaceLicense.value);
  const entitlement = stiggClient.getMeteredEntitlement({
    featureId,
    options: {
      fallback: {
        hasAccess: isFreePlan.value ? fallbackCount <= config.FREE_TIER_LIMITS.users : true,
        usageLimit: config.FREE_TIER_LIMITS.users,
      },
      requestedUsage: totalUserAmount,
    },
  });
  if (!entitlement.hasAccess) {
    logger.error(
      `User limit reached, got to a total of ${totalUserAmount} for workspace ${route.params.workspaceId} for featureId ${featureId}`
    );
    showInviteErrorModal({ errorCode: StatusCodes.PAYMENT_REQUIRED });
    return false;
  }

  return true;
}

async function approveRequestHandler(email) {
  try {
    if (!(await checkUserLimit([email]))) {
      return;
    }

    await swimmApi.resolveJoinRequest({
      workspaceId: route.params.workspaceId ?? workspaceData.value.id,
      email,
      isApproved: true,
    });
    void CloudFunctions.postApprovedToWorkspace({
      workspaceId: route.params.workspaceId ?? workspaceData.value.id,
      email,
    });
    await store.dispatch('database/refreshWorkspaceUsersAndInvites', { workspaceId: route.params.workspaceId });
    reportAction({ userEmail: email, event: productEvents.INVITE_REQUEST_APPROVED });
    logEvent(eventLogger.SWIMM_EVENTS.ADMIN_APPROVED_JOIN_WORKSPACE_REQUEST, {
      srcId: currentUser.value.uid,
      srcName: currentUser.value.nickname,
      targetId: email,
      targetName: email,
    });
  } catch (err) {
    logger.error({ err }, `Failed to accept user ${email}: ${err}`);
    if (err instanceof AxiosError) {
      showInviteErrorModal({ errorCode: err.response?.status ?? 500 });
    } else {
      showInviteErrorModal({ functionContext: functionContextForError.APPROVE_REQUEST });
    }
  }
}

async function inviteHandler(emails = []) {
  try {
    if (!(await checkUserLimit(emails))) {
      return false;
    }
    const inviteResult = await swimmApi.inviteSwimmers({
      workspaceId: route.params.workspaceId ?? workspaceData.value.id,
      emails,
    });
    const { emailsAdded } = inviteResult.data;
    await store.dispatch('database/refreshWorkspaceUsersAndInvites', { workspaceId: route.params.workspaceId });
    emailsAdded.forEach((email) => {
      logEvent(eventLogger.SWIMM_EVENTS.USER_INVITED, {
        srcId: currentUser.value.uid,
        srcName: currentUser.value.nickname,
        targetId: email,
        targetName: email,
      });
    });
    analytics.track(productEvents.INVITE_SUCCESSFULLY_SENT, {
      'Total Invites': emailsAdded.length,
      'Workspace ID': route.params.workspaceId,
    });
    return true;
  } catch (err) {
    logger.error({ err }, `Failed to invite ${JSON.stringify(emails)}`);
    if (err instanceof AxiosError) {
      showInviteErrorModal({ errorCode: err.response?.status ?? 500 });
    } else {
      showInviteErrorModal({ functionContext: functionContextForError.INVITE });
    }
  }
  return false;
}

const functionContextForError = {
  INVITE: 'invite',
  APPROVE_REQUEST: 'approve-request',
};

function showInviteErrorModal({
  errorCode,
  functionContext,
}: commonTypes.XOR<{ errorCode: number }, { functionContext: string }>) {
  switch (functionContext) {
    case functionContextForError.APPROVE_REQUEST: {
      showSwal('Accept join request failed', 'An error has occurred while trying add the user to the workspace.');
      return;
    }
    case functionContextForError.INVITE: {
      showSwal('Adding users failed', 'An error has occurred while trying to add users.');
      return;
    }
  }

  switch (errorCode) {
    case StatusCodes.PAYMENT_REQUIRED: {
      analytics.cloudTrack({
        identity: currentUser.value.uid,
        event: productEvents.USER_LIMIT_REACHED_PAYWALL,
        payload: {
          'Inviter User Id': currentUser.value.uid,
          'Inviter Email': currentUser.value.email,
          'Workspace ID': route.params.workspaceId,
          'Workspace Name': workspaceData.value.name,
        },
      });
      showPaywall.value = true;
      return;
    }
    case StatusCodes.FORBIDDEN: {
      showSwal('Invalid email', "You can only invite addresses with your company's domain to this Workspace.");
      return;
    }
    default: {
      showSwal('An error has occurred', null);
    }
  }
}

function showSwal(title, text) {
  swal({
    title,
    text,
    // @ts-ignore
    content: SWAL_CONTACT_US_CONTENT(),
  });
}

async function sendInvite(emails) {
  sendInviteInProgress.value = true;
  await inviteHandler(emails);
  sendInviteInProgress.value = false;
}

async function acceptRequestToJoin(userEmail) {
  reportAction({ userEmail, event: productEvents.CLICKED_APPROVE_INVITE_REQUEST });
  await approveRequestHandler(userEmail);
}

async function denyRequestToJoin(userEmail: string) {
  try {
    reportAction({ userEmail, event: productEvents.CLICKED_DENY_INVITE_REQUEST });
    await swimmApi.resolveJoinRequest({
      workspaceId: route.params.workspaceId ?? workspaceData.value.id,
      email: userEmail,
      isApproved: false,
    });
    await store.dispatch('database/refreshWorkspaceUsersAndInvites', { workspaceId: route.params.workspaceId });
    reportAction({ userEmail, event: productEvents.INVITE_REQUEST_DENIED });
  } catch (err) {
    logger.error({ err }, `Failed in denyRequestToJoin for ${userEmail} for ${route.params.workspaceId}`);
    showSwal('An error has occurred', null);
  }
}

function reportAction({ userEmail, event }) {
  analytics.cloudTrack({
    identity: currentUser.value.uid,
    event,
    payload: { Email: userEmail, 'Workspace ID': route.params.workspaceId },
  });
}
</script>

<template>
  <SettingsModalSection title="Members" :subtitle="subtitle">
    <UsersInviteSection v-if="isCurrentUserWorkspaceAdmin" :loading="sendInviteInProgress" @send-invite="sendInvite" />
    <UsersTableSection class="table" @accept-request="acceptRequestToJoin" @deny-request="denyRequestToJoin" />
  </SettingsModalSection>
  <PaywallModal :show="showPaywall" :entity="PaywallEntity.USER" @close="showPaywall = false" />
</template>

<style scoped lang="postcss">
.table {
  margin-top: var(--space-md);
}
</style>
