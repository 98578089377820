<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  ApplicabilityStatus,
  type SmartElementWithApplicability,
  type Snippet,
  reversedEllipsisFunction,
} from '@swimm/shared';
import { Icon, SwText } from '@swimm/ui';
import { BaseIcon, BaseLayoutGap } from '@swimm/reefui';

type HunkApplicability = SmartElementWithApplicability<Snippet>['applicability'] | ApplicabilityStatus.Unknown;

const props = withDefaults(
  defineProps<{
    isIde: boolean;
    isHover: boolean;
    shouldAnimateHunkWrapper: boolean;
    isCrossDocHunk: boolean;
    repoIcon: string | undefined;
    repoName: string;
    repoFullName: string;
    hunkBranch: string | null;
    isAuthorized: boolean;
    originalHunkPath: string;
    filePath: string;
    verifying: boolean;
    collapsed: boolean;
    shouldShowReplaceHunkBox: boolean;
    hunkApplicability?: HunkApplicability;
    isEditable: boolean;
    shouldHideEditButton: boolean;
    shouldHideSplitButton: boolean;
    isDisconnectedDoc: boolean;
    isRepoRemovedFromWorkspace: boolean;
    noAccessToRepo: boolean;
    hideApplicabilityChip?: boolean;
    showOutboundLink?: boolean;
    splitSnippetMode?: boolean;
  }>(),
  {
    hideApplicabilityChip: false,
    showOutboundLink: false,
    splitSnippetMode: false,
  }
);

const emit = defineEmits<{
  (e: 'open-file'): void;
  (e: 'report-hover', context: string): void;
  (e: 'toggle-collapse'): void;
  (e: 'edit-hunk'): void;
  (e: 'open-split-snippet-mode'): void;
}>();

const showOutboundLinkIcon = ref(false);

function openFile() {
  emit('open-file');
}
function editHunk() {
  emit('edit-hunk');
}
function openSplitSnippetMode() {
  emit('open-split-snippet-mode');
}
function reportHover(context: string) {
  emit('report-hover', context);
}
function reverseEllipsis(text: string, length: number, clamp: string) {
  return reversedEllipsisFunction(text, length, clamp);
}

const repoNameTooltip = computed(() => {
  if (props.isRepoRemovedFromWorkspace) {
    return 'This repository has been removed from your workspace.';
  }
  if (props.repoFullName) {
    return props.repoFullName;
  }
  return undefined;
});

const shouldShowApplicabilityChip = computed(() => {
  return !props.hideApplicabilityChip && (props.hunkApplicability || props.verifying || props.noAccessToRepo);
});

const shouldShowPathDiff = computed(() => {
  return props.filePath !== props.originalHunkPath;
});

const collapseExpandTooltip = computed(() => {
  if (props.isEditable) {
    return props.collapsed ? 'Save view as expanded for viewers.' : 'Save view as collapsed for viewers.';
  }
  return props.collapsed ? 'Expand' : 'Collapse';
});

function toggleCollapse() {
  emit('toggle-collapse');
}
const hunkIsNotVerified = computed(() => {
  return props.hunkApplicability && props.hunkApplicability !== ApplicabilityStatus.Verified;
});
const hunkStatusIsNotOutdated = computed(() => {
  return props.hunkApplicability !== ApplicabilityStatus.Outdated;
});
const shouldAddRepoNamePrefix = computed(() => {
  return props.isCrossDocHunk || props.isDisconnectedDoc;
});

const applicability = computed(() => {
  if (props.verifying) {
    return {
      headerColor: 'var(--color-surface)',
      icon: 'sync',
      style: {
        color: 'var(--text-color-primary)',
      },
      tooltip: 'Verifying your code...',
      text: 'Verifying...',
      iconStyle: undefined,
    };
  }
  // When you have no access to repo
  // the hunk has applicability of verified
  if (props.noAccessToRepo) {
    if (props.isIde) {
      return {
        headerColor: 'var(--color-surface)',
        icon: 'help',
        style: {
          color: 'var(--text-color-on-accent)',
        },
        tooltip:
          'This code snippet is taken from another repository in your workspace, to check if it is synced with the code open the document in the Swimm WebApp',
        text: 'Unknown (other repo)',
        iconStyle: undefined,
      };
    } else {
      return {
        headerColor: 'var(--color-surface)',
        icon: 'not-allowed',
        style: {
          color: 'var(--text-color-on-accent)',
        },
        tooltip: "You don't have access to this repo.",
        text: 'No Access',
        iconStyle: undefined,
      };
    }
  }

  const defaultApplicabilityColors = {
    headerColor: 'var(--color-surface)',
    icon: 'updated',
    style: {
      color: 'var(--text-color-success)',
    },
    iconStyle: {
      color: 'var(--text-color-success-strong)',
    },
    tooltip: 'This snippet has not been changed since a team member verified it.',
    text: 'Up to date',
  };
  const applicabilityStatus = {
    [ApplicabilityStatus.Verified]: defaultApplicabilityColors,
    [ApplicabilityStatus.Unknown]: defaultApplicabilityColors,
    [ApplicabilityStatus.Unavailable]: defaultApplicabilityColors,
    [ApplicabilityStatus.Autosyncable]: {
      headerColor: 'var(--color-status-magic)',
      icon: 'sync',
      style: {
        color: 'var(--text-color-magic)',
      },
      iconStyle: {
        color: 'var(--text-color-magic-strong)',
      },
      tooltip: 'The code has changed and Swimm updated this snippet to match its current version.',
      text: 'Auto-synced snippet',
    },
    [ApplicabilityStatus.Outdated]: {
      headerColor: 'var(--color-status-error)',
      icon: 'warning',
      style: {
        color: 'var(--text-color-error)',
      },
      iconStyle: {
        color: 'var(--text-color-error-strong)',
      },
      tooltip: 'The code has changed and this snippet is no longer up to date.',
      text: 'Outdated snippet',
    },
    [ApplicabilityStatus.DisconnectedFromRepo]: {
      headerColor: 'var(--color-surface)',
      icon: 'cloud',
      style: {
        color: 'var(--text-color-on-accent)',
      },
      iconStyle: {
        color: 'var(--text-color-on-accent)',
      },
      tooltip: 'Snippets in Cloud Docs are not Auto-synced.',
      text: 'Not synced',
    },
  };
  return applicabilityStatus[props.hunkApplicability || ApplicabilityStatus.Verified];
});
</script>
<template>
  <div
    class="header-wrapper outlined-container"
    :class="{ accepting: shouldAnimateHunkWrapper, 'hunk-hovered': isHover }"
    :style="{ background: !shouldAnimateHunkWrapper ? applicability.headerColor : '' }"
    data-testid="snippet-header"
  >
    <BaseLayoutGap alignment="stretch">
      <template v-if="shouldAddRepoNamePrefix">
        <Icon v-if="repoIcon" :name="repoIcon" class="snippet-file-icon" />
        <VTooltip :disabled="!repoNameTooltip">
          <span>{{ repoName }}</span>
          <template #popper>{{ repoNameTooltip }}</template>
        </VTooltip>
        <div v-if="hunkBranch">&nbsp;({{ hunkBranch }})</div>
        <Icon name="arrow-right" class="no-padding-right no-padding-left" />
      </template>
      <Icon name="file" class="snippet-file-icon" :class="{ 'no-padding-left': shouldAddRepoNamePrefix }" />
      <div
        class="file-name-wrapper text-ellipsis text-reverse-ellipsis body-L"
        :class="{ disabled: !hunkStatusIsNotOutdated || !hunkBranch || !isAuthorized }"
        v-tooltip="isAuthorized ? '' : 'To see the entire file, authorize GitHub'"
        data-testid="file-name-wrapper"
        @click="openFile"
        @mouseenter="
          () => {
            showOutboundLinkIcon = true;
            reportHover('Snippet Title');
          }
        "
        @mouseleave="() => (showOutboundLinkIcon = false)"
      >
        <div v-if="shouldShowPathDiff" class="text-reverse-ellipsis-inner">
          <VTooltip>
            <span class="old-file-title"> {{ reverseEllipsis(originalHunkPath, 30, '...') }}</span>
            <span> {{ reverseEllipsis(filePath, 30, '...') }}</span>
            <template #popper>
              <div>
                <SwText variant="subtitle-L" component="span">Old path: </SwText>
                <span class="old-file-path"> {{ originalHunkPath }}</span>
              </div>
              <div><SwText variant="subtitle-L" component="span">New path:</SwText> {{ filePath }}</div>
            </template>
          </VTooltip>
        </div>
        <span v-else class="text-reverse-ellipsis-inner">{{ filePath }}</span>
      </div>
      <BaseIcon
        v-if="showOutboundLink && showOutboundLinkIcon"
        name="outbound-link"
        class="hunk-headline__outbound-link-icon"
      />
      <div class="actions-wrapper">
        <span>
          <span v-if="shouldShowApplicabilityChip" class="applicability" :data-testid="`snippet-${hunkApplicability}`">
            <Icon
              v-tooltip="applicability.tooltip"
              class="applicability-icon"
              :class="{ 'rotate-icon': verifying }"
              :style="applicability.iconStyle"
              :name="applicability.icon"
              :data-testid="`snippet-applicability-icon-${hunkApplicability}`"
            />
            <span
              class="applicability-text"
              :class="{ verifying }"
              :style="applicability.style"
              :data-testid="`snippet-applicability-text`"
              >{{ applicability.text }}</span
            >
            <slot name="header-hotspot" />
          </span>
        </span>
        <div v-if="!shouldShowReplaceHunkBox" class="hunk-actions">
          <span v-if="!hunkIsNotVerified || isDisconnectedDoc" v-tooltip="collapseExpandTooltip">
            <Icon
              class="clickable collapse-icon"
              data-testid="snippet-collapse"
              :name="`${collapsed ? 'expand' : 'collapse'}-arrow`"
              @click="toggleCollapse()"
            />
          </span>
          <div class="hunk-edit-actions bold">
            <span
              v-if="isEditable && !shouldHideSplitButton"
              v-tooltip="'Split snippet'"
              @click="openSplitSnippetMode()"
            >
              <Icon data-testid="split-snippet-button" class="clickable action-icon" :name="'split-snippet'" />
            </span>
            <span v-if="isEditable && !shouldHideEditButton">
              <Icon
                data-testid="edit-hunk-icon"
                class="clickable action-icon"
                name="edit-outline"
                v-tooltip="'Edit snippet'"
                @click="editHunk()"
              />
            </span>
          </div>
        </div>
      </div>
    </BaseLayoutGap>
  </div>
</template>

<style scoped lang="postcss">
.hunk-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__outbound-link-icon {
    padding-top: 3px;
  }
}

.header-wrapper {
  padding: 5px;
  font-size: var(--body-L);
  background: var(--color-surface);
  border-bottom: 1px solid var(--color-border-default-subtle);
}

.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.old-file-title {
  padding-right: 5px;
  text-decoration: line-through;
  font-style: italic;
  background-color: var(--color-status-error);
}

.old-file-path {
  text-decoration: line-through;
}

.file-name-wrapper {
  min-width: 0;
  max-width: 50%;
  cursor: pointer;
  line-height: inherit;

  &.disabled {
    cursor: not-allowed;
  }
}

.applicability {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-left: 5px;
  min-width: 0;
}

.applicability-icon {
  font-size: var(--fontsize-m);
}

.applicability-text {
  font-size: var(--body-L);
  font-family: var(--fontfamily-main);
  white-space: nowrap;
}

.snippet-file-icon {
  font-size: var(--fontsize-m);
}

.row {
  position: relative;
  align-items: center;
  width: 100%;
}

.action-icon {
  font-size: var(--font-size-medium);
  vertical-align: bottom;
}

.hunk-edit-actions {
  position: relative;
  bottom: 2px;
  display: flex;
  flex-direction: row;
  font-weight: lighter;

  & .action-icon:hover {
    color: var(--color-text-default);
  }
}

.hunk-actions {
  display: flex;
}

.header-wrapper .hunk-actions {
  visibility: hidden;
}

.hunk-hovered .actions-wrapper:not(.invisible) .hunk-actions {
  visibility: visible;
}

.collapse-icon {
  margin-left: 7px;
  font-size: var(--body-S);
  font-weight: 400;
  color: var(--text-color-secondary);
}

.rotate-icon {
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
  animation-direction: reverse;
  font-size: var(--fontsize-xs);
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.outlined-container {
  border-radius: 0;

  &.accepting {
    animation-name: autosyncable-smart-element-accepted;
    animation-duration: 1s;
    animation-delay: 250ms;
  }
}
</style>
