import { invokeCodeAnalysis } from '@/common/utils/code-analysis';
import {
  AsyncCache,
  type Branch,
  CodeAnalysisAction,
  GitProviderName,
  UNSUPPORTED_FILE_EXTENSIONS,
  UrlUtils,
  getLoggerNew,
  gitProviderUtils,
} from '@swimm/shared';
import { StatusCodes } from 'http-status-codes';
import { useMemoize } from '@vueuse/core';

const logger = getLoggerNew(__modulename);

export async function getLocalRepoTreeEnterprise({
  provider,
  branchObject,
  repoId,
  cloneUrl,
  workspaceId,
  userId,
  treeBaseUrl,
}: {
  provider: GitProviderName;
  branchObject: Branch;
  repoId: string;
  cloneUrl: string;
  workspaceId: string;
  userId: string;
  treeBaseUrl: string;
}): Promise<string[]> {
  try {
    if (provider === GitProviderName.GitLabEnterprise) {
      return memoizedGetLocalRepoTree({
        provider,
        branchObject,
        repoId,
        workspaceId,
        cloneUrl,
        userId,
        treeBaseUrl,
      });
    }

    return await listAllFilesCodeAnalysisCache.get(
      async ([repoId, branchObject, provider]) => {
        return getFlatRepoTreeFromCodeAnalysis(repoId, branchObject, provider);
      },
      [repoId, branchObject, provider]
    );
  } catch (err) {
    logger.error(
      { err },
      `Failed to get repo tree for repository ${repoId}: ${err}. Tree and GlobalTokens will be empty.`
    );
    return [];
  }
}

// Prevent getting the repo tree from the server more than once
// If the consumers would consume it when it's ready using a watch this wouldn't be needed
const memoizedGetLocalRepoTree = useMemoize(
  ({
    provider,
    branchObject,
    repoId,
    cloneUrl,
    workspaceId,
    userId,
    treeBaseUrl,
  }: {
    provider: GitProviderName;
    branchObject: Branch;
    repoId: string;
    cloneUrl: string;
    workspaceId: string;
    userId: string;
    treeBaseUrl: string;
  }) => {
    return getLocalRepoTree({
      provider,
      branchObject,
      repoId,
      workspaceId,
      cloneUrl,
      userId,
      treeBaseUrl,
    });
  },
  {
    getKey: ({ provider, branchObject, cloneUrl }) => `${provider}-${branchObject.sha}-${cloneUrl}`,
  }
);

async function getLocalRepoTree({
  provider,
  branchObject,
  repoId,
  workspaceId,
  cloneUrl,
  userId,
  treeBaseUrl,
}: {
  provider: GitProviderName;
  branchObject: Branch;
  repoId: string;
  workspaceId: string;
  cloneUrl: string;
  userId: string;
  treeBaseUrl: string;
}): Promise<string[]> {
  if (!treeBaseUrl) {
    throw new Error(`Local auth endpoints are missing. Cannot get local repo tree for ${provider}`);
  }
  const endpoint = `${treeBaseUrl}/repo-tree`;
  const cloneToken = await gitProviderUtils.getVerifiedGitHostingToken({
    gitHostingUrl: cloneUrl,
    provider,
    // TODO: remove this property!
    trackAuthorize: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  });
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      workspaceId,
      repoId,
      provider,
      cloneToken,
      repoTarget: {
        cloneUrl,
        branchOrTag: branchObject.name,
        languages: [],
        commitSha: branchObject.sha,
      },
    }),
  });
  if (response.status !== StatusCodes.OK) {
    throw new Error(`Request failed with Status: ${response.status}.`);
  }
  const tree = await response.json();
  if (!Array.isArray(tree)) {
    throw new Error(`Response is not an array containing the tree. Response: ${JSON.stringify(tree)}`);
  }
  return tree
    .map((entry: { path: string }) => entry.path)
    .filter((filePath: string) => {
      const lowerCasedFile = filePath.toLowerCase();
      return UNSUPPORTED_FILE_EXTENSIONS.every((fileType) => !lowerCasedFile.endsWith(fileType));
    });
}

async function getFlatRepoTreeFromCodeAnalysis(
  repoId: string,
  branchObject: Branch,
  provider: GitProviderName
): Promise<string[]> {
  const repoGitHostingUrl = UrlUtils.providerToGitCloudHostingUrl(provider);
  const token = await gitProviderUtils.getGitProviderToken({
    gitHostingUrl: repoGitHostingUrl,
    isClient: false,
    callerName: 'list-all-files-remote',
    repoId: repoId,
  });
  const tree = await invokeCodeAnalysis({
    args: {
      action: CodeAnalysisAction.GET_REPO_TREE,
      repoTarget: {
        cloneUrl: '', // Clone URL is fetched from the DB in the server.
        branchOrTag: branchObject.name,
        languages: [],
        commitSha: branchObject.sha,
      },
      cloneToken: token ?? '',
      repoId: repoId,
    },
    responseField: 'tree',
  });
  return tree.map((entry) => entry.path);
}

const listAllFilesCodeAnalysisCache = new AsyncCache<string[], [string, Branch, GitProviderName], string>(
  ([repoId, branchObject, provider]) => `${repoId}-${branchObject.name}-${branchObject.sha}-${provider}`
);
