import { confirmDraftDeletion } from '@/modules/drafts3/discard-draft-confirmations';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { DocumentationTypes } from '@/common/consts';
import type { DocumentationType } from '@/modules/core/types';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import { ref } from 'vue';
import { useFoldersStore } from '@/modules/folders/store/folders';
import type { DocumentationTypeToFolderItemType } from '@/modules/folders/types';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { config } from '@swimm/shared';
import { useNavigate } from '@/common/composables/navigate';

export interface ShareModalProps {
  showShareModal: boolean;
  entityId: string;
  entityName: string;
  entity: 'DOC' | 'PLAYLIST';
  repoId: string;
  entityLink: string;
}

export interface MoreAction {
  show: (item) => boolean;
  label: string;
  icon: string;
  handler: (options: {
    resource;
    repoId: string;
    isDraft?: boolean;
    type: DocumentationType;
    entityName: string;
  }) => void;
}

export interface MoveFolderModalProps {
  show: boolean;
  items: {
    id?: string;
    draftId?: string;
    isNew?: boolean;
    folderId?: string;
    documentationType: keyof typeof DocumentationTypeToFolderItemType;
  }[];
}

const ShareModalDefaultProps: ShareModalProps = {
  showShareModal: false,
  entityId: '',
  entity: 'DOC',
  entityName: 'Resource',
  entityLink: '',
  repoId: '',
};

export function useMoreActionsOptions() {
  const route = useRoute();
  const router = useRouter();
  const { reposStateData } = storeToRefs(useReposStore());
  const { renameFolderId } = storeToRefs(useFoldersStore());
  const { duplicateResource, getResourceLink, onDeleteItem } = useResourceActions();
  const drafts3Store = useDrafts3Store();
  const { getRepoPath } = useNavigate();

  const shareModalOptions = ref<ShareModalProps>(ShareModalDefaultProps);
  const moveFolderModalOptions = ref<MoveFolderModalProps>({ show: false, items: [] });
  function getRepoDefaultBranchName(repoId): string {
    const repoFromState = reposStateData.value[repoId];
    return repoFromState?.defaultBranch || '';
  }

  function getBranch(repoId: string): string {
    return (route.params.branch as string) || getRepoDefaultBranchName(repoId);
  }

  function openShareModal(options: ShareModalProps) {
    shareModalOptions.value = {
      ...options,
      showShareModal: true,
    };
  }

  function closeShareModal() {
    shareModalOptions.value = ShareModalDefaultProps;
  }

  function handleFolderRenameClick({ resource }: { resource }) {
    renameFolderId.value = resource.id;
  }

  function handleGetShareableLinkClick({
    resource,
    repoId,
    type,
    entityName,
  }: {
    resource;
    repoId: string;
    isDraft?: boolean;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
    entityName: string;
  }) {
    const branch = getBranch(repoId);

    const entityLink = `${location.origin}${getResourceLink({
      resource,
      repoId,
      branch,
      type,
    })}`;

    const entities = {
      [DocumentationTypes.DOC]: 'DOC',
      [DocumentationTypes.PLAYLIST]: 'PLAYLIST',
    };

    openShareModal({
      showShareModal: true,
      entity: entities[type],
      entityId: resource.id,
      entityLink,
      entityName,
      repoId,
    });
  }

  function handleDuplicateClick({
    resource,
    repoId,
    type,
  }: {
    resource;
    repoId: string;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  }) {
    const branch = getBranch(repoId);

    duplicateResource({ repoId, branch, resource, type });
  }

  function handleEditClick({
    resource,
    repoId,
    type,
  }: {
    resource;
    repoId: string;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  }) {
    const branch = getBranch(repoId);

    router.push(
      getResourceLink({
        resource,
        repoId,
        branch,
        type,
        editMode: true,
      })
    );
  }

  function handleDeleteClick({ resource, repoId }: { resource; repoId: string }) {
    onDeleteItem({
      item: resource,
      repoId,
    });
  }

  async function handleDiscardClick({ draftId }: { draftId: string }) {
    if (!(await confirmDraftDeletion())) {
      return;
    }
    await drafts3Store.discardDraft(draftId);
  }

  function handleMoveToFolderClick({ resource }) {
    moveFolderModalOptions.value = {
      show: true,
      items: [resource],
    };
  }

  const handlePrintModeClick = (docId) => {
    const docEmbedUrl = `${config.getEmbedUrl()}${getRepoPath()}/docs/${docId}`;
    window.open(docEmbedUrl, '_blank').focus();
  };

  return {
    handleGetShareableLinkClick,
    handleDuplicateClick,
    handleEditClick,
    handleDeleteClick,
    handleDiscardClick,
    handleMoveToFolderClick,
    handleFolderRenameClick,
    handlePrintModeClick,
    renameFolderId,
    shareModalOptions,
    moveFolderModalOptions,
    closeShareModal,
  };
}
