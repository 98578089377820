import * as swimm from '@swimm/backend-client';
import { SWIMM_BACKEND_CLOUD_RUN_URL } from '@/config';
import firebase from 'firebase/compat/app';
import { getLoggerNew, objectUtils } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

// to run the backend with local server, cd to swimm root folder and do
// echo "SWIMM_BACKEND_CLOUD_RUN_URL=http://127.0.0.1:24606" >> .env

const config = new swimm.Configuration({
  basePath: SWIMM_BACKEND_CLOUD_RUN_URL,
  accessToken: () => firebase.auth().currentUser.getIdToken(),
});

export const swimmApi = new swimm.SwimmApi(config);

// function to check that the backend client working ok
// and that the backend is connected to the right firebase
// it calls endpoint that uses auth and get user from auth and its record from firestore
export async function checkBackEndClient() {
  logger.info(`Checking backend client SWIMM_BACKEND_CLOUD_RUN_URL=${SWIMM_BACKEND_CLOUD_RUN_URL}`);
  try {
    const resp = await swimmApi.currentUser();
    if (
      !objectUtils.isEmpty(resp.data) &&
      !objectUtils.isEmpty(resp.data.auth) &&
      !objectUtils.isEmpty(resp.data.firestore)
    ) {
      logger.info('Backend client is working ok');
    } else {
      logger.error('Problem with backend client, look at backend logs');
    }
  } catch (err) {
    logger.error({ err }, `Failed to call backend client`);
  }
}
