<template>
  <template v-if="mentionsToShow.length">
    <BaseLayoutGap direction="column" size="xxsmall">
      <SwmNodeViewItemMention
        v-for="(mention, index) in mentionsToShow"
        :key="index"
        :name="mention.name"
        :email="mention.email"
        :unavailable="mention.applicability !== ApplicabilityStatus.Verified"
        unavailable-message="This user is not part of the workspace anymore."
        :data-testid="`doc-mention-${index}`"
      />
    </BaseLayoutGap>
  </template>
  <template v-else>
    <BaseProse variant="secondary" size="small" data-testid="doc-mentions-empty">
      <p>
        Users mentioned will be listed here.<br />
        Type <code>@</code> to mention another user.
      </p>
    </BaseProse>
  </template>
</template>

<script lang="ts">
import { computeMentionList } from '@/modules/doc-mentions/services/doc-mentions';
import { mapGetters } from 'vuex';
import { ApplicabilityStatus, WorkspaceUser } from '@swimm/shared';
import { BaseLayoutGap, BaseProse, SwmNodeViewItemMention } from '@swimm/reefui';
import { PropType } from 'vue';

export default {
  components: {
    SwmNodeViewItemMention,
    BaseLayoutGap,
    BaseProse,
  },
  data() {
    return {
      ApplicabilityStatus,
      mentionsToShowOld: [],
    };
  },
  props: {
    mentions: {
      type: Map as PropType<Map<string, { uid: string; email: string; pos: number; name: string }>>,
      required: true,
    },
  },
  mounted() {
    // For some reason, doing this on $nextTick
    // was too early, so I just added timer
    // we can consider using custom events
    // or using MutationObserver (both mainly for editor)
    window.setTimeout(() => {
      this.recalculateMentions();
    }, 100);
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspaceUsers']),
    workspaceUsers() {
      return Object.values(this.db_getWorkspaceUsers(this.$route.params.workspaceId)) as WorkspaceUser[];
    },
    mentionsToShow() {
      return [...this.mentions.values()].map((mention) => ({
        ...mention,
        applicability: this.isUserInWorkspace(mention) ? ApplicabilityStatus.Verified : ApplicabilityStatus.Outdated,
      }));
    },
  },
  methods: {
    recalculateMentions() {
      this.mentionsToShowOld = computeMentionList().map((mention) => ({
        ...mention,
        applicability: this.isUserInWorkspace(mention) ? ApplicabilityStatus.Verified : ApplicabilityStatus.Outdated,
      }));
    },
    isUserInWorkspace(userMentionSymbol) {
      return this.workspaceUsers.find((user) => user.external_uid === userMentionSymbol.uid);
    },
  },
};
</script>
