import type { FileTokensIndex } from '@swimm/editor';
import { useFileTokensIndex } from '@swimm/editor';

import { getRepoDefaultBranch } from '@/remote-adapters/local_repo';
import { getLoggerNew, gitwrapper } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export function useWebAppFileTokensIndex(docRepoId: string, docBranch: string): FileTokensIndex {
  async function getFileContent(repoId: string, path: string): Promise<string | undefined> {
    try {
      const branch = await getBranch(repoId);
      if (branch == null) {
        logger.error({ repoId }, 'Failed to get branch for repo');
        return null;
      }
      const fileContent: string | null = await gitwrapper.getFileContentFromRevision({
        filePath: path,
        revision: branch,
        repoId,
      });
      if (fileContent == null) {
        logger.error({ repoId, branch }, 'Failed to get file content');
        return undefined;
      }
      return fileContent;
    } catch (err) {
      logger.error({ err, repoId }, 'Failed to getFileContent');
      return undefined;
    }
  }

  async function getBranch(repoId: string): Promise<string | null> {
    if (repoId === docRepoId) {
      return docBranch;
    }
    const result = await getRepoDefaultBranch({ repoId });
    if (result.code === 0) {
      return result.branch;
    }
    return null;
  }
  return useFileTokensIndex(getFileContent);
}
