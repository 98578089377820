import { gitwrapper } from '@swimm/shared';
import { CDI_FILE_TYPES } from './types';
import { RawProcessFile } from '../source-types';
import { ObjectRef, ProcessFile } from '../output-types';
import { NEW_LINE_REGEX, composeValueObject, parseDescriptionField, parseObjectRefs } from '../source-json-parser';

export const CDI_FILE_NAME_PATTERN =
  /\.+(?<namePrefix>m_|mt_|tf_)+(?<objectName>.+?)\.(?<fileType>DTEMPLATE|MTT|TASKFLOW){1}\.json$/;

type FileMetadata = { prefix: string; name: string; type: CDI_FILE_TYPES; filePath: string };

export function validate(filePaths: string[]): boolean {
  return filePaths.every((filePath) => {
    return filePath.endsWith('.json') && filePath.match(CDI_FILE_NAME_PATTERN);
  });
}

export function filter(filePaths: string[]): string[] {
  return filePaths.filter((filePath) => filePath.match(CDI_FILE_NAME_PATTERN));
}

export function toFileMetadata(filePaths: string[]): Array<FileMetadata> {
  return filePaths
    .filter((filePath) => filePath.match(CDI_FILE_NAME_PATTERN))
    .map((filePath) => {
      const match = filePath.match(CDI_FILE_NAME_PATTERN);
      return {
        prefix: match.groups['namePrefix'],
        name: match.groups['objectName'],
        type: match.groups['fileType'] as CDI_FILE_TYPES,
        filePath,
      };
    });
}

/**
 * Parse nested object refs - we want to be able to move from each object to the objects it points to
 * Most commonly here taskflow -> MTT objects -> DTEMPLATE objects
 * We also make sure all the nested objects are available on the parent object
 * So taskflow will contain all its own MTT child elements, but also all of its children's DTEMPLATE elements
 * Allowing us to loop for MTTs an DTEMPLATEs on the taskflow level
 * @param objectRefs - a map of object name to its content
 * @param objectFiles - an array of all input files
 * @param repoId
 * @param branch
 */
async function parseNestedObjectRefs(
  objectRefs: { [name: string]: ObjectRef },
  objectFiles: Array<FileMetadata>,
  repoId: string,
  branch: string
) {
  // The parent object is returned all its original objectRefs
  let extendedRefs: { [name: string]: ObjectRef } = { ...objectRefs };

  for (const objectRef of Object.values(objectRefs)) {
    const objectFile = objectFiles.find(
      (file) => `${file.prefix}${file.name}` === objectRef.name.value && file.type === objectRef.type.value
    );

    if (objectFile) {
      const objectFileText = await gitwrapper.getFileContentFromRevision({
        filePath: objectFile.filePath,
        repoId,
        revision: branch,
      });
      const objectFileJson = JSON.parse(objectFileText) as RawProcessFile;
      const objectFileTextLines = objectFileText.split(NEW_LINE_REGEX);
      const objectFileObjectRefs = parseObjectRefs(objectFileJson, objectFileTextLines, 'name', objectFile.filePath);
      objectRef.objectRefs = Object.values(objectFileObjectRefs).map((objectRef) => ({
        ...objectRef,
        filePath: objectFile.filePath,
      }));

      if (objectRef.objectRefs.length) {
        const nestedRefsByName = await parseNestedObjectRefs(objectFileObjectRefs, objectFiles, repoId, branch);
        objectRef.objectRefs = [...Object.values(nestedRefsByName)];
        // Add nested refs to the parent object
        extendedRefs = { ...extendedRefs, ...nestedRefsByName };
      }

      // Each object contains its references objects both as an array of objectRefs and mapped by their type
      extendedRefs[objectRef.name.value] = { ...objectRef, ...getObjectRefsBy(objectRef.objectRefs, 'type') };
    }
  }
  return extendedRefs;
}

function getObjectRefsBy(objectRefs: ObjectRef[], by: string) {
  return Object.values(objectRefs).reduce((acc, objectRef) => {
    if (!acc[objectRef[by].value]) {
      acc[objectRef[by].value] = [objectRef];
    } else {
      acc[objectRef[by].value].push(objectRef);
    }
    return acc;
  }, {});
}

async function parseTaskFlow(
  filePath: string,
  objectFiles: Array<FileMetadata>,
  repoId: string,
  branch: string
): Promise<ProcessFile> {
  const fileText = await gitwrapper.getFileContentFromRevision({ filePath, repoId, revision: branch });
  const textLines = fileText.split(NEW_LINE_REGEX);
  const fileJson = JSON.parse(fileText) as RawProcessFile;
  let objectRefsByName = parseObjectRefs(fileJson, textLines, 'name', filePath);

  objectRefsByName = await parseNestedObjectRefs(objectRefsByName, objectFiles, repoId, branch);

  const objectRefs = Object.values(objectRefsByName);
  const relatedObjects = getObjectRefsBy(objectRefs, 'type');

  const additionalInfo = parseDescriptionField(
    textLines,
    fileJson.objectInfo?.metadata?.additionalInfo?.description,
    fileJson.objectInfo?.name,
    objectRefsByName
  );

  const name = composeValueObject(textLines, 'name', fileJson.objectInfo?.name as string);
  const type = composeValueObject(textLines, 'type', fileJson.objectInfo?.type as string);
  const path = composeValueObject(textLines, 'path', fileJson.objectInfo?.type as string);

  return {
    name,
    type,
    path,
    ...additionalInfo,
    ...relatedObjects,
    objectRefs,
    filePath,
  };
}

export async function parse(objectFiles: Array<FileMetadata>, repoId: string, branch: string) {
  return Promise.all(
    objectFiles
      .filter((objectFile) => objectFile.type === 'TASKFLOW')
      .map(({ filePath }) => {
        return parseTaskFlow(filePath, objectFiles, repoId, branch);
      }, Array<Promise<ProcessFile>>())
      .filter((result) => !!result)
  );
}
