import type MarkdownIt from 'markdown-it';
import swm_block from './swm_block';
import swm_inline from './swm_inline';
import swm_path from './swm_path';
import swm_link from './swm_link';
import swm_token from './swm_token';
import swm_snippet from './swm_snippet';
import swm_mention from './swm_mention';
import mermaid from './mermaid';
import youtube from './youtube';
import block_image from './block_image';
import swm_snippet_placeholder from './swm_snippet_placeholder';
// import swm_text_placeholder from './swm_text_placeholder';
import swm_table_placeholder from './swm_table_placeholder';
import swm_mermaid_placeholder from './swm_mermaid_placeholder';
import swm_drop from './swm_drop';
import html_inline_convert from './html_inline_convert';

export interface SwimmPluginOptions {
  dropUnmatched: boolean;
  convertHtml: boolean;
}

const DEFAULT_OPTIONS: SwimmPluginOptions = {
  dropUnmatched: true,
  convertHtml: true,
};

export default function swimm(md: MarkdownIt, options?: SwimmPluginOptions): void {
  options = { ...DEFAULT_OPTIONS, ...(options ?? {}) };

  md.use(swm_block);
  md.use(swm_inline);

  md.use(swm_path);
  md.use(swm_link);
  md.use(swm_token);
  md.use(swm_snippet);
  md.use(swm_mention);
  md.use(mermaid);
  md.use(youtube);

  md.use(block_image);

  md.use(swm_snippet_placeholder);
  // NOTE Currently disabled
  // md.use(swm_text_placeholder);
  md.use(swm_table_placeholder);
  md.use(swm_mermaid_placeholder);

  if (options.dropUnmatched) {
    md.use(swm_drop);
  }

  if (options.convertHtml) {
    md.use(html_inline_convert);
  }
}
