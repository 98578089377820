<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { SwmPathSelectionModalInner } from '@swimm/editor';
import type { Editor } from '@tiptap/vue-3';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import { type FolderTree, getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

const props = defineProps<{
  editor: Editor;
}>();

const swimmEditorServices = computed(() => getSwimmEditorServices(props.editor));

const repoId = ref<string>(swimmEditorServices.value.repoId.value);
const folderTree = ref<FolderTree>();
const loadingTree = ref(false);

const isCrossRepo = computed(() => repoId.value !== swimmEditorServices.value.repoId.value);
const branch = computed(() =>
  !isCrossRepo.value
    ? swimmEditorServices.value.branch.value
    : swimmEditorServices.value.repos.value.repos?.find((repo) => repo.id === repoId.value)?.defaultBranch
);

let pendingRefresh = false;
async function refreshTree() {
  if (
    swimmEditorServices.value &&
    swimmEditorServices.value.showSwmPathSelectionModal.value &&
    repoId.value &&
    branch.value
  ) {
    if (loadingTree.value) {
      pendingRefresh = true;
      return;
    }
    loadingTree.value = true;
    try {
      folderTree.value = await swimmEditorServices.value.external.getRepoFolderTree(
        repoId.value,
        branch.value,
        isCrossRepo.value
      );
    } catch (err) {
      logger.error({ err }, 'error in calling getRepoFolderTree');
    }

    loadingTree.value = false;

    if (pendingRefresh) {
      pendingRefresh = false;
      void refreshTree();
    }
  }
}

watch(() => swimmEditorServices.value, refreshTree);
watch(() => swimmEditorServices.value?.showSwmPathSelectionModal.value, refreshTree);
watch(() => repoId.value, refreshTree);
refreshTree();

const reposForTree = computed(() => {
  if (swimmEditorServices.value.isIde) {
    return swimmEditorServices.value.repos.value.repos.filter((workspaceRepo) => workspaceRepo.id === repoId.value);
  } else {
    return swimmEditorServices.value.repos.value.repos;
  }
});
</script>

<template>
  <SwmPathSelectionModalInner
    v-if="editor && swimmEditorServices && repoId"
    v-model="swimmEditorServices.showSwmPathSelectionModal.value"
    v-model:repo-id="repoId"
    v-model:selectedNode="swimmEditorServices.swmPathSelectionSelectedNode.value"
    :title="swimmEditorServices.swmPathSelectionDialogTitle.value"
    :is-onboarding="false"
    :workspace-repos="reposForTree"
    :folder-tree="folderTree"
    :disable-directory-selection="swimmEditorServices.swmPathSelectionDisableDirectorySelection.value"
    :loading-repo="swimmEditorServices.repos.value.loading"
    :loading-tree="loadingTree"
    @refresh-tree="refreshTree"
  />
</template>
