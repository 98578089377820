<script setup lang="ts">
import { computed, onMounted, ref, useAttrs, useSlots } from 'vue';
import { omit, uniqueId } from 'lodash-es';

import BaseIcon from '../BaseIcon/BaseIcon.vue';
import BaseProse from '../BaseProse/BaseProse.vue';
import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    /**
     * Optional label for the input.
     */
    label?: string;
    /**
     * Input name.
     */
    name?: string;
    /**
     * Input placeholder text.
     */
    placeholder?: string;
    /**
     * Is the input required?
     */
    required?: boolean;
    /**
     * Is the input invalid?
     */
    invalid?: boolean;
    /**
     * Invalid message via prop, alternatively use the #invalidMessage slot.
     */
    invalidMessage?: string;
    /**
     * Custom validator to be used in place for invalid prop.
     */
    validator?: () => boolean;
    /**
     * Note via prop, alternatively use the #note slot.
     */
    note?: string;
    /**
     * Is the input disabled?
     */
    disabled?: boolean;
    /**
     * Used for v-model.
     */
    modelValue?: string;
    /**
     * Input type
     */
    type?: string;
    /**
     * Focus the input automatically when it is mounted.
     */
    focusOnMount?: boolean;
  }>(),
  {
    label: undefined,
    name: undefined,
    placeholder: undefined,
    modelValue: undefined,
    type: 'text',
    invalidMessage: undefined,
    validator: () => false,
    note: undefined,
    focusOnMount: false,
  }
);

const emit = defineEmits<{
  blur: [evt: Event];
  touched: [value: boolean];
  'update:modelValue': [value: string];
  dirty: [value: boolean];
  click: [];
}>();

const slots = useSlots();
const attrs = useAttrs();

const field = ref<HTMLInputElement | null>(null);
const uid = ref(uniqueId('input-'));
const isTouched = ref(false);
const isDirty = ref(false);

const hasNote = computed(() => !!slots.default);

const inputId = computed(() => (typeof attrs.id === 'string' ? attrs.id : uid.value));

const inputAttrs = computed(() => omit(attrs, ['class']));

const inputBind = computed(() => ({
  name: props.name,
  placeholder: props.placeholder,
  disabled: props.disabled,
  ...inputAttrs.value,
}));

const computedClasses = computed(() => {
  /*
   * Split the attribute class string into an array
   */
  const attrClassArray = attrs.class ? (attrs.class as string).split(' ') : [];

  /*
   * Convert the array into an object where each key is a class name and each value is true
   */
  const attrClassObject = attrClassArray.reduce((obj, className) => {
    return { ...obj, [className]: true };
  }, {});

  return {
    'input--invalid': props.invalid,
    'input--disabled': props.disabled,
    ...attrClassObject,
  };
});

const isInvalid = computed(() => {
  return props.invalid || props.validator();
});

const hasInvalidMessage = computed(() => {
  return !!slots.invalidMessage || props.invalidMessage;
});

const hasDescription = computed(() => {
  return (
    (isInvalid.value && hasInvalidMessage.value) || (isInvalid.value && props.required) || props.note || !!hasNote.value
  );
});

const descriptionVariant = computed(() =>
  (isInvalid.value && hasDescription) || (isInvalid.value && props.required) ? 'danger' : 'secondary'
);

const onBlur = (evt: Event) => {
  emit('blur', evt);
  if (!isTouched.value) {
    isTouched.value = true;
    emit('touched', true);
  }
};

const onClear = () => {
  emit('update:modelValue', '');
  field.value?.focus();
};

const onInput = (evt: Event) => {
  const value = (evt.target as HTMLInputElement).value;
  emit('update:modelValue', value);
  if (!isDirty.value && value !== props.modelValue) {
    emit('dirty', true);
    isDirty.value = true;
  }
};

if (props.focusOnMount) {
  onMounted(() => {
    field.value?.focus();
  });
}

defineExpose({
  field,
});
</script>

<template>
  <BaseLayoutGap direction="column" size="xxsmall" class="input" :class="computedClasses">
    <label v-if="label" :for="inputId" class="input__label"
      >{{ label }} <sup v-if="required" class="input__required">*</sup></label
    >
    <div class="input__container">
      <span v-if="type === 'search'" class="input__icon" aria-hidden="true">
        <BaseIcon name="search" />
      </span>
      <input
        :id="inputId"
        ref="field"
        class="input__field"
        v-bind="inputBind"
        :type="type"
        :value="modelValue"
        @blur="onBlur"
        @click="emit('click')"
        @input="onInput"
      />
      <button
        v-if="type === 'search' && !!modelValue"
        type="button"
        class="input__clear"
        aria-label="Clear input"
        :aria-controls="inputId"
        @click.stop="onClear"
      >
        <BaseIcon name="close" aria-hidden="true" />
      </button>
    </div>

    <BaseProse v-if="hasDescription" :variant="descriptionVariant" size="small"
      ><template v-if="isInvalid && hasInvalidMessage">
        <slot name="invalidMessage">{{ invalidMessage }}</slot>
      </template>
      <template v-else-if="isInvalid && required">This field is required</template>
      <template v-else>
        <slot>{{ note }}</slot>
      </template></BaseProse
    >
  </BaseLayoutGap>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.input {
  $self: &;

  @include defaults;

  position: relative;
  width: 100%;

  &__label {
    font-size: var(--font-size-small);
  }

  &__required {
    color: var(--color-text-danger);
    line-height: 0.8;
  }

  &__container {
    position: relative;
    width: 100%;
  }

  &__icon {
    color: var(--color-text-secondary);
    height: var(--font-size-small);
    font-size: var(--font-size-small);
    line-height: var(--font-size-small);
    left: var(--space-xsmall);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: var(--font-size-small);
  }

  &__field {
    appearance: none;
    background-color: var(--color-bg-default);
    border: 1px solid var(--color-border-default);
    color: var(--color-text-default);
    border-radius: var(--space-xxsmall);
    box-sizing: border-box;
    font-size: var(--font-size-small);
    line-height: var(--font-size-default);
    outline: none;
    padding: calc(var(--space-xsmall) - 1px) var(--space-xsmall);
    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }

    &::placeholder {
      color: var(--color-text-disabled);
    }

    &:hover {
      border-color: var(--color-border-default-strong);
    }

    &:focus {
      border-color: var(--color-border-brand);
    }

    &[type='search'] {
      text-indent: calc(var(--space-small) + 2px);
    }
  }

  &__clear {
    background-color: var(--color-bg-default);
    border: none;
    color: var(--color-text-secondary);
    height: var(--font-size-small);
    font-size: var(--font-size-small);
    line-height: var(--font-size-small);
    padding: 0;
    position: absolute;
    right: var(--space-xsmall);
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: calc(var(--font-size-small) + var(--space-xxsmall));
    z-index: var(--layers-overlap);

    &:hover {
      cursor: pointer;
      color: var(--color-text-brand);
    }
  }

  &--invalid {
    .input__field {
      border-color: var(--color-border-danger);
    }
  }

  &--disabled {
    .input__field {
      background-color: var(--color-bg-disabled);
      border-color: var(--color-border-default-subtle);
      color: var(--color-text-disabled);
    }
  }
}
</style>
