<script setup lang="ts">
import { IconButton } from '@swimm/ui';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { computed } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';

const props = defineProps({
  showGoToCommitted: { type: Boolean, default: true },
  showDiscard: { type: Boolean, default: true },
  showMoveToFolder: { type: Boolean, default: true },
});

const emit = defineEmits(['committed-click', 'discard-click', 'change-folder', 'print-mode-click']);
const repoStore = useReposStore();
const { isFoldersReady } = useFoldersStore();

const shouldShowMoveToFolder = computed(() => {
  if (!isFoldersReady(repoStore.repoId)) {
    return false;
  }
  return props.showMoveToFolder;
});

const analytics = useAnalytics();
const onPrintModeClicked = () => {
  analytics.track(productEvents.CLICK_PRINT_MODE, {
    Context: 'DocumentationOptionsMenu',
  });
  emit('print-mode-click');
};
</script>

<template>
  <VDropdown data-testid="doc-options-menu" v-if="showGoToCommitted || showDiscard || shouldShowMoveToFolder">
    <div class="options-menu">
      <IconButton name="more" />
    </div>
    <template #popper>
      <ul class="options">
        <li class="option" v-if="showGoToCommitted" @click="$emit('committed-click')">
          <SwText variant="body-S" class="option-text">
            <Icon name="plan" no-padding />
            Go to committed version
          </SwText>
        </li>
        <li class="option" v-if="showDiscard" @click="$emit('discard-click')" data-testid="discard-draft">
          <SwText variant="body-S" class="option-text">
            <Icon name="discard" no-padding />
            Discard draft
          </SwText>
        </li>
        <li class="option" v-if="shouldShowMoveToFolder" @click="$emit('change-folder')" data-testid="move-to-folder">
          <SwText variant="body-S" class="option-text">
            <Icon name="move" no-padding />
            Move to folder
          </SwText>
        </li>
        <li class="option" @click="onPrintModeClicked">
          <SwText variant="body-S" class="option-text">
            <Icon name="printer" no-padding />
            Print mode
          </SwText>
        </li>
      </ul>
    </template>
  </VDropdown>
</template>

<style scoped lang="postcss">
.options-menu {
  display: flex;
}

.options {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .option {
    list-style-type: none;
    cursor: pointer;
    padding: var(--space-base) var(--space-sm);

    &:hover {
      background-color: var(--color-hover);
    }

    .option-text {
      display: flex;
      align-items: center;
      gap: var(--space-xs);
    }
  }
}
</style>
