<template>
  <div
    class="hunk-view-wrapper"
    :class="{ 'with-comments': withComments }"
    data-testid="hunk"
    :data-applicability="hunkApplicability"
  >
    <slot name="repo-settings-modal" />
    <div
      class="hunk-view"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
      :data-testid="`snippet-view-${hunkApplicability}`"
    >
      <HunkViewHeader
        :is-ide="isIde"
        :is-hover="isHover"
        :should-animate-hunk-wrapper="shouldAnimateHunkWrapper"
        :is-cross-doc-hunk="isCrossDocHunk"
        :repo-icon="repoIcon"
        :repo-name="repoName"
        :repo-full-name="repoFullName"
        :hunk-branch="hunkBranch ?? null"
        :is-authorized="isAuthorized"
        :hunk-applicability="hunkApplicability"
        :verifying="verifying"
        :original-hunk-path="originalHunkPath"
        :file-path="filePath"
        :collapsed="collapsed"
        :is-editable="editMode"
        :is-repo-removed-from-workspace="!repoMetadata"
        :should-hide-edit-button="shouldHideEditButton"
        :should-hide-split-button="shouldHideSplitButton"
        :is-disconnected-doc="isDisconnectedDoc"
        :should-show-replace-hunk-box="shouldShowReplaceHunkBox"
        :no-access-to-repo="noAccessToRepo"
        :hide-applicability-chip="hideApplicability"
        :show-outbound-link="showOutboundLink"
        :split-snippet-mode="splitSnippetMode"
        @toggle-collapse="toggleCollapse"
        @report-hover="reportHover"
        @open-file="openFile"
        @edit-hunk="editHunk"
        @open-split-snippet-mode="openSplitSnippetMode"
      >
        <template #header-hotspot>
          <slot name="header-hotspot" />
        </template>
      </HunkViewHeader>
      <div v-if="!collapsed">
        <DiffSnippet
          v-if="shouldShowAutosyncedDiffView"
          :changes="autosyncedSideBySide"
          :file-type="fileType"
          :titles="{ a: 'Outdated', b: 'Auto-synced' }"
        />
        <CodeSnippet
          v-else
          :changes="changes"
          :repo-id="repoId"
          :branch="hunkBranch ?? undefined"
          :file-name="filePath"
          :file-type="fileType"
          :applicability="hunkApplicability"
          :force-disconnected="noAccessToRepo"
          :is-authorized="isAuthorized"
          :edit-mode="editMode"
          :file-contents="fileContents"
          :hide-show-more="hideShowMore ?? false"
          :split-snippet-mode="splitSnippetMode"
          :query-definitions="queryDefinitionsIfApplicable"
          :allow-drag-drop-editing="allowDragDropEditing"
          @report-hover="reportHover($event)"
          @line-range-change="emit('hunk-line-range-change', $event)"
          @split-snippet="splitSnippet"
          @add-def-snippet="emit('add-def-snippet', $event)"
          @token-interacted="(op: 'hover' | 'click', tok: OverlayToken) => emit('token-interacted', op, tok)"
        />
      </div>
      <HunkViewFooter
        v-if="shouldShowReplaceHunkBox"
        :hunk-applicability="hunkApplicability"
        :is-ide="isIde"
        :is-admin="isAdmin"
        :is-auto-syncable="isAutoSyncable"
        :autosynced-includes-code-changes="autosyncedIncludesCodeChanges"
        :split-snippet-mode="splitSnippetMode"
        @accept-hunk="acceptHunk"
        @discard-hunk="discardHunk"
        @configure-autosync="configureAutosync"
        @edit-hunk="editHunk"
        @close-split-mode="closeSplitSnippetMode"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SmartItemActions } from '@/consts';
import {
  ApplicabilityStatus,
  HunkChangeLineType,
  LangExtensionUtils,
  type SmartElementWithApplicability,
  type Snippet,
  type SwmCellSnippet,
  buildRepoFullName,
  convertSwmSnippetToAutosyncSnippet,
  getGitProviderIconName,
  isSmartElementWithNewInfo,
  lineMatchSnippetCellBySimilarity,
  swmCellToDynamicPatch,
} from '@swimm/shared';
import { structuredPatch } from 'diff';
import CodeSnippet from './CodeSnippet.vue';
import DiffSnippet from './DiffSnippet.vue';
import HunkViewHeader from './HunkViewHeader.vue';
import HunkViewFooter from './HunkViewFooter.vue';
import { findLastIndex } from 'lodash-es';
import { computed, ref, watch } from 'vue';
import type { DynamicChangeLine, GitInfo, TokenSuggestion, WorkspaceRepo } from '@swimm/shared';
import type { OpenFileParam } from '@/types';
import type { OverlayToken } from '../GenericText/tokenizer';

type DisplayLineType = {
  original: {
    lineNumber?: number;
    data?: string;
    isEmpty?: boolean;
  };
  autosynced: {
    lineNumber?: number;
    data?: string;
    isEmpty?: boolean;
  };
  lineChange?: string;
  type: HunkChangeLineType;
};

const props = defineProps<{
  hunk: SwmCellSnippet;
  hunkBranch: string | null;
  shouldAnimateHunkWrapper: boolean;
  docRepoId: string | null;
  docId: string | null;
  autosyncedSnippetData: SmartElementWithApplicability<Snippet> | null;
  repoId: string;
  editMode: boolean;
  verifying: boolean;
  withComments: boolean;
  fileContents: string[] | null;
  isDragInProgress: boolean;
  isAdmin: boolean;
  isAuthorized: boolean;
  isIde: boolean;
  repoMetadata: WorkspaceRepo | null;
  hideApplicability?: boolean;
  hideShowMore?: boolean;
  showOutboundLink?: boolean;
  queryDefinitions?: ((token: string) => Promise<TokenSuggestion[]>) | null;
  allowDragDropEditing?: boolean;
}>();

const emit = defineEmits<{
  (e: 'report-fixed-hunk', { context, action }: { context: string; action: string }): void;
  (e: 'open-file', param: OpenFileParam): void;
  (e: 'set-collapsed-on-file', v: boolean): void;
  (e: 'report-hover', element: string): void;
  (e: 'configure-autosync'): void;
  (e: 'discard-hunk'): void;
  (e: 'configure-autosync'): void;
  (e: 'edit-hunk'): void;
  (e: 'hunk-line-range-change', newHunk: { firstLineNumber: number; lines: string[] }): void;
  (e: 'split-hunk', splitAfterLineNumber: number): void;
  (e: 'report-split-hunk-mode-changed', opened: boolean): void;
  (e: 'accept-auto-synced-hunk'): void;
  (e: 'add-def-snippet', suggestion: TokenSuggestion): void;
  (e: 'token-interacted', op: 'hover' | 'click', tok: OverlayToken): void;
}>();

const splitSnippetMode = ref(false);

const openSplitSnippetMode = () => {
  splitSnippetMode.value = true;
  emit('report-split-hunk-mode-changed', true);
};

const closeSplitSnippetMode = () => {
  splitSnippetMode.value = false;
  emit('report-split-hunk-mode-changed', false);
};

const isHover = ref(false);
const collapsedLocally = ref(props.hunk.collapsed);

const isDisconnectedDoc = computed(() => {
  return props.hunk.applicability === ApplicabilityStatus.DisconnectedFromRepo;
});
const isCrossDocHunk = computed(() => {
  return props.repoId !== props.docRepoId;
});
const repoName = computed(() => {
  return props.repoMetadata?.name ?? 'Unknown repo';
});

const repoFullName = computed(() => {
  return buildRepoFullName(props.repoMetadata);
});

const noAccessToRepo = computed(() => {
  if (!props.isAuthorized) {
    return false;
  }

  // We set to null if no access (but it can be also empty string)
  return !props.hunkBranch;
});
const repoIcon = computed(() => {
  return getGitProviderIconName(props.repoMetadata?.provider);
});
const isReadOnlyHunk = computed(() => {
  return !props.repoMetadata || noAccessToRepo.value;
});
const initiaSnippet = computed(() => {
  const gitInfo = generateGitInfo(props.hunk);
  const autosyncSnippet = convertSwmSnippetToAutosyncSnippet({
    swmSnippet: props.hunk,
    docRepoId: props.hunk.repoId,
    gitInfo: gitInfo,
  });
  return autosyncSnippet;
});
const hunkBeforeAutosyncContainer = computed(() => {
  return swmCellToDynamicPatch(initiaSnippet.value)[props.hunk.path].hunkContainers[0];
});
const displayedHunk = computed<{ changes: DynamicChangeLine[] }>(() => {
  // when autosync finished it will return the new data
  if (props.autosyncedSnippetData && isSmartElementWithNewInfo(props.autosyncedSnippetData)) {
    return autosyncedHunkContainer.value;
  } else {
    return hunkBeforeAutosyncContainer.value;
  }
});
const shouldShowAutosyncedDiffView = computed(() => {
  return shouldShowReplaceHunkBox.value && isAutoSyncable.value && autosyncedIncludesCodeChanges.value;
});
const autosyncedIncludesCodeChanges = computed(() => {
  return originalAutosyncedDiffPatch.value.hunks.length > 0;
});

const changes = computed(() => {
  return displayedHunk.value.changes.filter((change) => !!change.fileA);
});
const autosyncedHunkContainer = computed(() => {
  if (props.autosyncedSnippetData && isSmartElementWithNewInfo(props.autosyncedSnippetData)) {
    const dynamicPatch = swmCellToDynamicPatch(props.autosyncedSnippetData.newInfo)[
      props.autosyncedSnippetData.newInfo.filePath
    ].hunkContainers[0];
    const firstMeatIndex =
      dynamicPatch.changes?.findIndex((line) => line.changeType !== HunkChangeLineType.Context) ?? -1;
    const lastMeatIndex =
      findLastIndex(dynamicPatch.changes ?? [], (line) => line.changeType !== HunkChangeLineType.Context) ?? -1;

    if (firstMeatIndex === -1 || lastMeatIndex === -1) {
      return { ...dynamicPatch };
    }

    const filteredLines = dynamicPatch.changes.filter(
      (line, index) => index >= firstMeatIndex && index <= lastMeatIndex
    );
    return {
      ...dynamicPatch,
      changes: filteredLines,
    };
  }
  return {
    changes: [], // not sure?
  };
});

const originalHunkLines = computed(() => {
  return hunkBeforeAutosyncContainer.value.changes.filter((change) => !!change.fileA);
});
const autosyncedHunkLines = computed(() => {
  return displayedHunk.value.changes.filter((change) => !!change.fileA);
});
const autosyncedSideBySide = computed<DynamicChangeLine[]>(() => {
  const changes = [];
  // Line match between the 2 snippets (original and autosynced)
  // set type and line change for css class usage

  // creating a hunk that include the diff between the snippets
  // structuredPatch create a patch from old and new string - without heading it assume first line is 1
  // adding a '\n' to handle EOF - it does not effect the snippets - it is only used for the line match calculation.

  let originalSnippetLineIndex = 0;
  let autosyncedSnippetLineIndex = 0;
  // If there is a lot of context, it is possible that the patch will split into more than 1 hunk
  for (const patchHunk of originalAutosyncedDiffPatch.value.hunks) {
    // Creating a snippet cell and match the diff lines
    const diffHunk = { ...initiaSnippet.value };
    diffHunk.lines = [...patchHunk.lines];
    const hunkChanges = lineMatchSnippetCellBySimilarity(diffHunk);
    // StructuredPatch return a diff with 3 context lines. it is possible that the diff start in the middle of the snippet
    // so we should add the lines before the start index of the diff (-1 beacuse we use indexs and in patch is the line number)
    const firstLineOfDiff = patchHunk.newStart - 1;
    changes.push(
      ...generateAutosyncedHunkLines({
        linesToAdd: firstLineOfDiff - autosyncedSnippetLineIndex,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        changeType: 'no-change',
        originalSnippetLineIndex: originalSnippetLineIndex,
        autosyncedSnippetLineIndex: autosyncedSnippetLineIndex,
      })
    );
    originalSnippetLineIndex = patchHunk.oldStart - 1;
    autosyncedSnippetLineIndex = patchHunk.newStart - 1;
    const emptyLine = { data: '', lineNumber: 0, isEmpty: true } as const;

    hunkChanges.forEach((diffLine) => {
      const displayLine: DisplayLineType = {
        original: {},
        autosynced: {},
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: 'autosync',
      };
      if (isAutosyncedContextLine(autosyncedSnippetLineIndex) || isOriginalHunkContextLine(originalSnippetLineIndex)) {
        displayLine.type = HunkChangeLineType.Context;
      }

      if (diffLine.fileA) {
        displayLine.original.lineNumber = originalHunkLines.value[originalSnippetLineIndex].fileA?.actualLineNumber;
        displayLine.original.data = originalHunkLines.value[originalSnippetLineIndex].fileA?.data;
        originalSnippetLineIndex++;
      } else {
        displayLine.original = { ...emptyLine };
      }

      if (diffLine.fileB) {
        displayLine.autosynced.lineNumber =
          autosyncedHunkLines.value[autosyncedSnippetLineIndex].fileA?.actualLineNumber;
        displayLine.autosynced.data = autosyncedHunkLines.value[autosyncedSnippetLineIndex].fileA?.data;
        autosyncedSnippetLineIndex++;
      } else {
        displayLine.autosynced = { ...emptyLine };
      }

      if (diffLine.changeType === 'context' && displayLine.type !== 'context') {
        // Change type context - the line didn't change
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        displayLine.type = 'no-change';
        // Highlight line number if it is he only change
        if (displayLine.original.lineNumber !== displayLine.autosynced.lineNumber) {
          displayLine.lineChange = 'onlyLineNumberChange';
        }
      }
      changes.push(displayLine);
    });
  }
  // It is possible that there are more rows that hasn't change and didn't returned in structure patch (more than 3 'context' lines)
  changes.push(
    ...generateAutosyncedHunkLines({
      linesToAdd: autosyncedHunkLines.value.length - autosyncedSnippetLineIndex,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      changeType: 'no-change',
      originalSnippetLineIndex: originalSnippetLineIndex,
      autosyncedSnippetLineIndex: autosyncedSnippetLineIndex,
    })
  );
  const mappedChanges = changes.map((change) => {
    return {
      ...change,
      changeType: change.type,
      fileA: {
        actualLineNumber: change.original.lineNumber ?? 0,
        data: change.original.data ?? '',
        isEmpty: change.original.isEmpty,
      },
      fileB: {
        actualLineNumber: change.autosynced.lineNumber ?? 0,
        data: change.autosynced.data ?? '',
        isEmpty: change.autosynced.isEmpty,
      },
    };
  });
  return mappedChanges;
});
const isAutoSyncable = computed(() => {
  return hunkApplicability.value === ApplicabilityStatus.Autosyncable;
});
const hunkApplicability = computed<
  SmartElementWithApplicability<Snippet>['applicability'] | ApplicabilityStatus.Unknown
>(() => {
  if (props.autosyncedSnippetData) {
    return props.autosyncedSnippetData.applicability;
  } else {
    return ApplicabilityStatus.Unknown;
  }
});
const shouldShowReplaceHunkBox = computed(() => {
  return (
    !isReadOnlyHunk.value &&
    !collapsed.value &&
    props.editMode &&
    (splitSnippetMode.value ||
      [ApplicabilityStatus.Outdated, ApplicabilityStatus.Invalid, ApplicabilityStatus.Autosyncable].includes(
        hunkApplicability.value
      ))
  );
});
const shouldHideEditButton = computed(() => isReadOnlyHunk.value);
watch(
  () => props.hunk.applicability,
  (applicability) => {
    if (applicability !== ApplicabilityStatus.Verified) {
      splitSnippetMode.value = false;
    }
  }
);

const shouldHideSplitButton = computed(() => {
  return (
    !props.editMode ||
    hunkApplicability.value !== ApplicabilityStatus.Verified ||
    // Can't split collapsed snippet and user probably wouldn't want to.
    collapsed.value ||
    // Can't split if there's just one line.
    props.hunk.lines.length === 1
  );
});

const splitSnippet = (splitAfterLineNumber: number) => {
  splitSnippetMode.value = false;
  emit('split-hunk', splitAfterLineNumber);
};

const fileType = computed(() => {
  return LangExtensionUtils.getFileProgrammingLanguageByPath(filePath.value);
});

const hunkStatusIsNotOutdated = computed(() => {
  return hunkApplicability.value !== ApplicabilityStatus.Outdated;
});
const hunkIsNotVerified = computed(() => {
  return hunkApplicability.value && hunkApplicability.value !== ApplicabilityStatus.Verified;
});
const originalHunkPath = computed(() => {
  return props.hunk.path;
});
const filePath = computed(() => {
  if (props.autosyncedSnippetData && isSmartElementWithNewInfo(props.autosyncedSnippetData)) {
    return props.autosyncedSnippetData.newInfo.filePath;
  } else {
    return props.hunk.path;
  }
});
const originalAutosyncedDiffPatch = computed(() => {
  if (!(isAutoSyncable.value && props.editMode)) {
    return { hunks: [] };
  }

  return structuredPatch(
    originalHunkPath.value,
    filePath.value,
    originalHunkLines.value.map((line) => line.fileA?.data).join('\n') + '\n',
    autosyncedHunkLines.value.map((line) => line.fileA?.data).join('\n') + '\n',
    '',
    ''
  );
});

const collapsed = computed(() => {
  // For cross doc hunks and disconnected docs we don't force expanded view
  if (hunkIsNotVerified.value && !isDisconnectedDoc.value) {
    return false;
  }

  return collapsedLocally.value || props.isDragInProgress;
});

function generateGitInfo(hunk: SwmCellSnippet): GitInfo {
  return {
    repoId: hunk.repoId,
    // Currently we are not using owner and name so we've decided to send it as an empty string

    repoOwner: '',
    repoName: repoName.value,
    branch: props.hunkBranch ?? '',
  };
}
function configureAutosync() {
  emit('configure-autosync');
}
function getFirstLineAndLines(): { firstLine: number; lines: string[] } {
  if (props.autosyncedSnippetData && isSmartElementWithNewInfo(props.autosyncedSnippetData)) {
    const firstLine = props.autosyncedSnippetData.newInfo.startLineNumber ?? props.hunk.firstLineNumber;
    const lines = props.autosyncedSnippetData.newInfo.lines ?? props.hunk.lines;
    return { firstLine, lines };
  }
  return { firstLine: props.hunk.firstLineNumber, lines: props.hunk.lines };
}
function findMeatLineNumbers() {
  const { firstLine, lines } = getFirstLineAndLines();
  // return LinesRange object (with start/end)
  const firstMeatIndex = lines.findIndex((line) => line.startsWith('*'));
  const lastMeatIndex = findLastIndex(lines, (line) => line.startsWith('*'));
  return {
    start: firstLine + (firstMeatIndex >= 0 ? firstMeatIndex : 0),
    end: firstLine + (lastMeatIndex >= 0 ? lastMeatIndex : lines.length - 1),
  };
}
function openFile() {
  if (props.isAuthorized && hunkStatusIsNotOutdated.value && props.hunkBranch) {
    const meatLines = findMeatLineNumbers();
    emit('open-file', {
      repoId: props.repoId,
      path: filePath.value,
      revision: props.hunkBranch,
      revealLines: meatLines,
      isSwimmDoc: false,
    });
  }
}
function toggleCollapse() {
  collapsedLocally.value = !collapsedLocally.value;
  if (props.editMode) {
    emit('set-collapsed-on-file', collapsedLocally.value);
  }
}
function generateAutosyncedHunkLines({
  linesToAdd,
  changeType,
  originalSnippetLineIndex,
  autosyncedSnippetLineIndex,
}: {
  linesToAdd: number;
  changeType: HunkChangeLineType;
  originalSnippetLineIndex: number;
  autosyncedSnippetLineIndex: number;
}) {
  const lines = [];
  for (let line = 0; line < linesToAdd; line++) {
    const displayLine: DisplayLineType = {
      original: {},
      autosynced: {},
      type: changeType,
    };
    const originalLineIndex = line + originalSnippetLineIndex;
    if (originalLineIndex >= 0 && originalLineIndex < originalHunkLines.value.length) {
      displayLine.original.lineNumber = originalHunkLines.value[originalLineIndex].fileA?.actualLineNumber;
      displayLine.original.data = originalHunkLines.value[originalLineIndex].fileA?.data;
    }
    const autosyncedLineIndex = line + autosyncedSnippetLineIndex;
    if (autosyncedLineIndex < autosyncedHunkLines.value.length) {
      displayLine.autosynced.lineNumber = autosyncedHunkLines.value[autosyncedLineIndex].fileA?.actualLineNumber;
      displayLine.autosynced.data = autosyncedHunkLines.value[autosyncedLineIndex].fileA?.data;
    }
    if (isAutosyncedContextLine(autosyncedLineIndex) || isOriginalHunkContextLine(originalLineIndex)) {
      // The line is a context line - should be in context coloring
      displayLine.type = HunkChangeLineType.Context;
    }
    // If the only change in the diff is the line number, highlight it
    if (
      displayLine.original.lineNumber !== displayLine.autosynced.lineNumber &&
      displayLine.type !== HunkChangeLineType.Context
    ) {
      displayLine.lineChange = 'onlyLineNumberChange';
    }

    lines.push(displayLine);
  }
  return lines;
}

function isAutosyncedContextLine(lineIndex: number) {
  return autosyncedHunkLines.value[lineIndex] && autosyncedHunkLines.value[lineIndex].changeType === 'context';
}
function isOriginalHunkContextLine(lineIndex: number) {
  return originalHunkLines.value[lineIndex] && originalHunkLines.value[lineIndex].changeType === 'context';
}
function discardHunk() {
  emit('discard-hunk');
  emit('report-fixed-hunk', { context: ApplicabilityStatus.Outdated, action: SmartItemActions.DELETE });
}
function editHunk(context?: string) {
  if (props.isIde) {
    openFile();
  }
  emit('edit-hunk');
  if (context) {
    // context does not exist on cases where user edits a verified hunk
    emit('report-fixed-hunk', { context, action: SmartItemActions.RESELECT });
  }
}
function acceptHunk() {
  emit('accept-auto-synced-hunk');
  emit('report-fixed-hunk', { context: ApplicabilityStatus.Autosyncable, action: SmartItemActions.ACCEPT });
}
function reportHover(element: string) {
  emit('report-hover', element);
}

const queryDefinitionsIfApplicable = computed(() => {
  // we pass the querydefintions only if we are in verified and edit
  // for ide, only if we are in the same repo
  // (this might be redundant check, since in this case the applicability should not be verfieid)
  if (!props.editMode || isReadOnlyHunk.value) {
    return null;
  }
  // in ide, you cannot access cross repo
  if (props.isIde && isCrossDocHunk.value) {
    return null;
  }
  if (hunkApplicability.value !== ApplicabilityStatus.Verified) {
    return null;
  }
  return props.queryDefinitions;
});
</script>

<style scoped lang="postcss">
.hotspot-container {
  width: 32px;
}

.hunk-view {
  position: relative;
  border-top: 1px solid var(--color-border-default-subtle);
  border-radius: 8px;
  overflow: hidden;
  color: var(--text-color-secondary);

  .with-comments & {
    border-radius: 0 0 8px 8px;
  }
}

.hunk-view-wrapper {
  margin: auto;
  font-weight: normal;
  text-align: left;
}

.file-custom-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: var(--color-bg);
}

.display-file-btn {
  margin-top: 10px;
  font-size: var(--body-L);
  font-weight: bold;
  color: var(--text-color-primary);
}
</style>
