<script setup lang="ts">
import { type PropType, computed, ref } from 'vue';
import { Action, SwModal } from '@swimm/ui';
import TreeView from '@/components/common/tree/TreeView.vue';
import PathMenuShortcuts from './PathMenuShortcuts.vue';
import type { FolderTree, Repo } from '@swimm/shared';

const props = defineProps({
  modelValue: { type: Boolean },
  selectedNode: { type: Object as PropType<FolderTree & { repoId: string }> },
  repoId: { type: String, required: true },
  title: { type: String },
  isOnboarding: { type: Boolean },
  workspaceRepos: { type: Array as PropType<Repo[]> },
  folderTree: { type: Object as PropType<FolderTree> },
  loadingRepo: { type: Boolean },
  loadingTree: { type: Boolean },
  disableDirectorySelection: { type: Boolean, defualt: false },
  excludeFiles: { type: Array as PropType<string[]>, default: () => [] },
  excludeFolders: { type: Array as PropType<string[]>, default: () => [] },
});

const emit = defineEmits<{
  (event: 'update:modelValue', modelValue: boolean): void;
  (event: 'update:selectedNode', selectedNode: (FolderTree & { repoId: string }) | undefined): void;
  (event: 'update:repoId', repoId: string): void;
  (event: 'refreshTree'): void;
  (event: 'close'): void;
}>();

const repoNodeSelected = ref(false);
const reposListShown = ref(false);

const repoMetadata = computed(() => {
  return props.workspaceRepos?.find((repo) => repo.id === props.repoId);
});

function handleClose(): void {
  reposListShown.value = false;
  repoNodeSelected.value = false;
  emit('update:selectedNode', undefined);
  emit('update:modelValue', false);
  emit('close');
}

function handleConfirm(): void {
  // TODO Might be possible to handle this better?
  if (reposListShown.value || repoNodeSelected.value) {
    emit('update:selectedNode', undefined);
  }

  emit('update:modelValue', false);
}

function handleNodeSelected(node: FolderTree): void {
  emit('update:selectedNode', { ...node, repoId: props.repoId });
}

function handleResetSelection(): void {
  emit('update:selectedNode', undefined);
}

function handleRepoSelected(selectedRepoId: string): void {
  emit('update:selectedNode', undefined);
  emit('update:repoId', selectedRepoId);
}

function reposListToggled(isShown: boolean): void {
  reposListShown.value = isShown;
}

const disableConfirm = computed(() => {
  return (
    !props.selectedNode ||
    reposListShown.value ||
    repoNodeSelected.value ||
    (props.disableDirectorySelection && props.selectedNode.type === 'directory') ||
    props.excludeFiles.includes(props.selectedNode.path) ||
    props.excludeFolders.includes(props.selectedNode.path) ||
    props.excludeFolders.some((excludeFolder) => props.selectedNode?.path.startsWith(excludeFolder + '/'))
  );
});
</script>

<template>
  <SwModal
    v-if="!isOnboarding"
    :show-modal="modelValue"
    :padded="false"
    :heading="title ?? 'Choose a path'"
    @close="handleClose"
    data-testid="path-selection-modal"
  >
    <div class="swm-path-selection-modal-inner__container">
      <div class="tree-path-container" tabindex="0">
        <TreeView
          class="path-selection-tree"
          :mark-nodes-with-units="false"
          :show-search="true"
          :search-include-dirs="true"
          :selected-path="selectedNode?.path ?? ''"
          :repo-id="repoId"
          :workspace-repos="workspaceRepos"
          :loading-selected-repo="loadingRepo"
          :repo-metadata="repoMetadata"
          :repo-folder-tree="folderTree"
          :loading-tree="loadingTree"
          @refresh-tree="$emit('refreshTree')"
          @node-selected="handleNodeSelected"
          @apply-node-selection="handleConfirm"
          @reset-selection="handleResetSelection"
          @repo-node-selected="(isSelected: boolean) => (repoNodeSelected = isSelected)"
          @repo-selected="handleRepoSelected"
          @repos-list-toggled="reposListToggled"
        />
        <Action :disabled="disableConfirm" class="large-button" data-testid="path-confirm-button" @click="handleConfirm"
          >Confirm</Action
        >
      </div>
      <PathMenuShortcuts />
    </div>
  </SwModal>
</template>

<style scoped>
.tree-path-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 20px;
  width: 40vw;
  min-width: 450px;
  max-width: 800px;
  outline: none;
}

.path-selection-tree {
  overflow-x: auto;
  min-height: 60vh;
  max-height: 60vh;
}

.large-button {
  margin-top: 10px;
  width: 75%;
}
</style>
