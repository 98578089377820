<template>
  <div>
    <div
      :class="['repo-line', { disabled, active, 'child-active': isChildActive }]"
      data-testid="repo-line"
      ref="elementRef"
      @click.stop="handleLineClick"
      @mouseover="hovering = true"
      @mouseleave="hovering = false"
    >
      <Icon
        class="handle expand-toggle"
        :class="{ hidden: isInFavoriteSection }"
        :name="repoListExpanded ? 'arrow-down' : 'arrow-right'"
      />
      <div class="repo-data-wrapper" :data-testid="active && 'selected-repo'">
        <div class="repo-link">
          <div class="repo-name-wrapper">
            <Icon
              :name="repoIcon"
              no-padding
              :class="[{ 'has-drafts': active && isRepoHaveDrafts }]"
              v-tooltip="active && isRepoHaveDrafts ? 'Uncommitted changes in this repo' : ''"
            />
            <EllipsisTooltip
              data-testid="repo-name"
              class="repo-name body-S data-hj-suppress"
              :content="repoDisplayNameTooltip"
              :length="0"
            >
              <VMenu :disabled="!disabled || !disabledReason" placement="right">
                <component
                  class="repo-name-link"
                  :class="{ disabled }"
                  :is="disabled ? 'span' : 'router-link'"
                  :to="repoUrl"
                  @click.stop="handleNameClick"
                >
                  <HighlightedText :full-text="repoDisplayName" :highlighted-text="filter" />
                </component>
                <template #popper>
                  <div class="disabled-popup" v-if="disabledReason === 'expired'">
                    <div class="subtitle-L bold">Upgrade to access this repo</div>
                    <div class="body-XS">{{ repoExpiredDescription }}</div>
                    <Action
                      v-if="isWorkspaceAdmin"
                      size="small"
                      class="paywall-popup-action"
                      @click="setShowBillingPlans(true)"
                      >Upgrade</Action
                    >
                  </div>
                  <div v-else class="disabled-popup">
                    <div class="subtitle-L bold title">
                      <template v-if="disabledReason === 'no-access'"
                        >You don't have access to this repo on {{ providerDisplayName }}</template
                      >
                      <template v-else>Authorize {{ providerDisplayName }} first!</template>
                    </div>
                    <div class="body-XS description">
                      <template v-if="disabledReason === 'no-access'">
                        Make sure you have access to this repo on {{ providerDisplayName }} (contact your
                        {{ providerDisplayName }} admin).
                      </template>
                      <template v-else>Authorize {{ providerDisplayName }} to access docs in this repo</template>
                    </div>
                  </div>
                </template>
              </VMenu>
            </EllipsisTooltip>
            <EllipsisTooltip
              v-if="!isInFavoriteSection && (active || dummyRepo)"
              :content="branchNameDisplay"
              :length="6"
              class="text-ellipsis branch-name body-XS"
            >
              <span @click.stop="handleBranchNameClicked">
                (<Icon v-if="!dummyRepo" name="branch" no-padding />{{ branchNameDisplay }})
              </span>
            </EllipsisTooltip>
          </div>
        </div>
      </div>
      <div class="repo-options">
        <div class="repo-options" v-if="hovering || menuIsOpen">
          <RepoSettings
            class="repo-settings-icon"
            data-testid="repo-settings-icon"
            :repo-provider="repo.metadata.provider"
            :repo-url="repositoryProviderUrl"
            :repo-id="repo.metadata.id"
            :workspace-id="workspaceId"
            :repo-selected="active"
            :is-favorite="isFavorite"
            :favorite-and-recent-section="isInFavoriteSection"
            @open-settings="onRepoSettingsClick"
            @remove="$emit('remove-repo', repo.metadata.id)"
            @add-to-favorites="$emit('add-to-favorites', repo.metadata.id)"
            @remove-from-favorites="$emit('remove-from-favorites', repo.metadata.id)"
            @menu-opened="onRepoSettingsMenuOpen"
            @menu-closed="onRepoSettingsMenuClose"
          />
          <RepoOrFolderCreateOptions
            v-if="!disabled && !isInFavoriteSection"
            class="repo-add-icon"
            :repo-id="repo.metadata.id"
            :folder-id="repoRootFolderId"
            entity="REPO"
            @expand-parent="onExpandParent"
            @close-menu="() => (menuIsOpen = false)"
            @open-menu="() => (menuIsOpen = true)"
          />
          <IconButton
            v-if="active"
            :name="isRepoOutdated ? 'warning' : 'updated'"
            :class="['status-icon', { outdated: isRepoOutdated }]"
            v-tooltip="isRepoOutdated ? 'Some docs are outdated, click to review' : 'All docs are up-to-date'"
            @click.stop="onOutdatedIconClick"
            @mouseenter="trackHoverOutdatedDot"
          />
        </div>
        <Icon
          v-if="isInFavoriteSection && (hovering || isFavorite)"
          :name="favoriteIcon"
          v-tooltip="favoriteTooltip"
          class="repo-favorite-icon"
          :class="{ favorite: isFavorite }"
          no-padding
          @click.stop="onFavoriteIconClick"
        />
      </div>
    </div>
    <SidebarRepoFolders v-if="!isInFavoriteSection && repoListExpanded" :repo-id="repo.metadata.id" :filter="filter" />
  </div>
</template>

<script lang="ts">
import { useFiltersStore } from '@/modules/core/filters-row/useFiltersStore';
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';
import RepoSettings from '@/common/components/RepoSettings/RepoSettings.vue';
import { GitProviderIcons, UrlUtils, buildRepoFullName, isRepoIdDummyRepo, productEvents } from '@swimm/shared';
import { type Ref, computed, ref, watch } from 'vue';
import { useNavigate } from '@/common/composables/navigate';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useRouting } from '@/common/composables/routing';
import RepoOrFolderCreateOptions from '@/modules/core/workspace/sidebar/components/RepoOrFolderCreateOptions.vue';
import SidebarRepoFolders from '@/modules/core/workspace/sidebar/components/SidebarRepoFolders.vue';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { Action, HighlightedText, IconButton } from '@swimm/ui';
import router from '@/router';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { debounce } from 'lodash-es';
import { useScroll } from '@swimm/editor';
import { useStore } from 'vuex';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useBillingStore } from '@/modules/billing/store/billing';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';

export default {
  components: {
    Action,
    EllipsisTooltip,
    RepoSettings,
    RepoOrFolderCreateOptions,
    SidebarRepoFolders,
    IconButton,
    HighlightedText,
  },
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledReason: { type: String, default: null },
    workspaceId: { type: String, default: null },
    provider: { type: String, required: true },
    isPrivate: { type: Boolean, required: true },
    repo: { type: Object, required: true },
    search: { type: String, default: '' },
    dummyRepo: { type: Boolean, default: false },
    isFavorite: { type: Boolean, default: false },
    isInFavoriteSection: { type: Boolean, default: false },
    totalRepoCount: { type: Number, default: 0 },
    isDuplicateName: { type: Boolean },
  },
  emits: [
    'remove-repo',
    'open-repo',
    'open-repo-settings',
    'repo-settings-hover-menu-opened',
    'repo-settings-hover-menu-closed',
    'add-to-favorites',
    'remove-from-favorites',
  ],
  setup(props, { emit }) {
    const repoListExpanded = ref(false);
    const filter = ref(props.search);
    const store = useStore();
    const { user } = storeToRefs(useAuthStore());
    const filtersStore = useFiltersStore();
    const { getRepoPath } = useNavigate();
    const route = useRoute();
    const { assertOnRepoPageRoute, getCurrentOrDefaultBranch } = useRouting();
    const foldersStore = useFoldersStore();
    const { getRepoRootFolder, loadRepoFolders, selectFolder } = foldersStore;
    const { currentFolderIdByRepo, foldersByRepo } = storeToRefs(foldersStore);
    const { isChoosingBranch, reposStateData } = storeToRefs(useReposStore());
    const { getNeedsReviewDocs } = useRepoDocsStore();
    const drafts3Store = useDrafts3Store();
    const analytics = useAnalytics();
    const { isScrolledIntoView } = useScroll();
    const hovering = ref(false);
    const menuIsOpen = ref(false);
    const elementRef: Ref<HTMLDivElement | null> = ref<HTMLDivElement | null>(null);
    const { setShowBillingPlans } = useBillingStore();
    const workspaceStore = useWorkspaceStore();
    const { providerDisplayName } = storeToRefs(workspaceStore);

    const currentBranch = ref(props.repo.branch);
    const currentFolder = computed(
      () => foldersByRepo.value[props.repo.metadata.id]?.[currentFolderIdByRepo.value[props.repo.metadata.id]]
    );
    const repoRootFolderId = computed(() => {
      const repoRoot = getRepoRootFolder(props.repo.metadata.id);
      if (!repoListExpanded.value) {
        loadRepoFolders(repoId.value);
      }
      if (!repoRoot) {
        return null;
      }
      return repoRoot.id;
    });
    const repoId = computed(() => props.repo.metadata.id);
    const branch = computed(() => route.params.branch);
    const branchNameDisplay = computed(() => (props.dummyRepo ? 'demo repo' : currentBranch.value));
    const repoIcon = computed(() => GitProviderIcons[props.repo.metadata.provider]);

    watch([repoId, branch], async () => {
      currentBranch.value = await getCurrentOrDefaultBranch(repoId.value);
    });

    function onExpandParent() {
      repoListExpanded.value = true;
    }

    const onRepoSettingsClick = (args) => {
      emit('open-repo-settings', args);
      analytics.track(productEvents.OPENED_SIDEBAR_ELLIPSIS_MENU, {
        'Entity Type': 'Repo',
        'Entity ID': repoId.value,
      });
    };

    const isDummyRepo = computed(() => isRepoIdDummyRepo(repoId.value));

    const repoDisplayName = computed(() => props.repo.metadata.name);

    const repoDisplayNameTooltip = computed(() => buildRepoFullName(props.repo.metadata));

    const repoUrl = computed(() => {
      const branch = isCurrentRepo.value
        ? reposStateData.value[repoId.value]?.branch
        : reposStateData.value[repoId.value]?.defaultBranch;
      const url = getRepoPath(repoId.value, branch);

      if (route.path.endsWith('/status')) {
        return `${url}/status`;
      }
      return url;
    });

    const repositoryProviderUrl = computed(() =>
      UrlUtils.normalizeRepositoryLink(props.repo.metadata.url, props.repo.metadata.provider)
    );

    const toggleRepoListExpand = async (forceToggle = false) => {
      if (props.isInFavoriteSection) {
        return;
      }
      if (!repoListExpanded.value && !isCurrentRepo.value) {
        router.push(repoUrl.value);
      }

      if (!forceToggle && isCurrentRepo.value && repoListExpanded.value) {
        // when clicking on repo name to go back to repo home
        // i.e from a folder or the status page no need to collapse
        return;
      }

      repoListExpanded.value = !repoListExpanded.value;
      if (repoListExpanded.value) {
        loadRepoFolders(repoId.value);
      }

      analytics.track(productEvents.EXPAND_COLLAPSE_SIDEBAR_ITEM, {
        'Is Current Repo': repoId.value === route.params.repoId,
        'Entity Type': 'Repo',
        'Entity ID': repoId.value,
        Expanded: repoListExpanded.value,
        'Is Quick Search Active': !!props.search,
      });
    };

    function navigateToStatusPage() {
      if (route.fullPath.endsWith('/status')) {
        return;
      }
      router.push(`${repoUrl.value}/status`);
    }

    const isRepoOutdated = computed(() => {
      const needsReviewDocs = getNeedsReviewDocs(repoId.value);
      return !!needsReviewDocs.length;
    });

    const isRepoHaveDrafts = computed(() => {
      return !!drafts3Store.drafts && drafts3Store.drafts.size > 0;
    });

    const isCurrentRepo = computed(() => route.params.repoId === repoId.value);

    watch(
      () => [isCurrentRepo.value, props.active, elementRef.value],
      ([isCurrentRepo, isActive, element]) => {
        if (!isCurrentRepo || !isActive) {
          return;
        }
        repoListExpanded.value = true;
        if (!element || isScrolledIntoView(element as HTMLDivElement)) {
          return;
        }
        (element as HTMLDivElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      { immediate: true }
    );

    const isChildActive = computed(() => {
      if (props.isInFavoriteSection) {
        return false;
      }

      if (!isCurrentRepo.value) {
        return false;
      }

      const isOnRepoPage = assertOnRepoPageRoute();
      // Repo page and Current folder is not the root folder
      return !isOnRepoPage || (isOnRepoPage && !currentFolder.value?.is_root);
    });

    const isOnRepoPage = computed(() => {
      return assertOnRepoPageRoute();
    });

    const favoriteTooltip = computed(() => (props.isFavorite ? 'Remove from favorites' : 'Add to favorites'));
    const favoriteIcon = computed(() => (props.isFavorite ? 'star-filled' : 'star-outline'));

    const handleNameClick = () => {
      if (props.disabled) {
        return;
      }
      const notOnRootFolder = repoRootFolderId.value && repoRootFolderId.value !== currentFolder.value.id;
      if (isCurrentRepo.value) {
        if (notOnRootFolder) {
          selectFolder(repoId.value, repoRootFolderId.value);
          return;
        } else if (isOnRepoPage.value) {
          toggleRepoListExpand(true);
          return;
        }
      } else {
        toggleRepoListExpand(true);
      }

      emit('open-repo', props.repo);

      analytics.track(productEvents.CLICKED_ITEM_NAME, {
        'Is Current Repo': repoId.value === route.params.repoId,
        'Entity Type': 'Repo',
        'Entity ID': repoId.value,
        'Total Repo Count': props.totalRepoCount,
        'Is In Favorites Recents Section': props.isInFavoriteSection,
      });
    };

    const handleLineClick = function () {
      if (props.disabled) {
        return;
      }
      emit('open-repo', props.repo);
      toggleRepoListExpand(true);
      analytics.track(productEvents.CLICKED_SIDEBAR_ITEM, {
        'Entity Type': 'Repo',
        'Entity ID': repoId.value,
        'Total Repo Count': props.totalRepoCount,
        'Is In Favorites Recents Section': props.isInFavoriteSection,
      });
    };

    function trackHoverOutdatedDot() {
      analytics.track(productEvents.HOVERED_OUTDATED_DOT, {
        'Has Dot': isRepoOutdated.value,
      });
    }

    function onOutdatedIconClick() {
      navigateToStatusPage();
      analytics.track(productEvents.CLICKED_OUTDATED_ICON_ON_REPO, {
        'Has Dot': isRepoOutdated.value,
      });
    }

    function onFavoriteIconClick() {
      emit(props.isFavorite ? 'remove-from-favorites' : 'add-to-favorites', repoId.value);
    }

    watch(
      () => props.active,
      (newValue) => {
        if (!newValue) {
          repoListExpanded.value = false;
        }
      }
    );

    const debouncedUpdateFilterTerm = debounce(() => {
      filter.value = props.search;
    }, 200);

    watch(
      () => props.search,
      () => {
        debouncedUpdateFilterTerm();
        if (props.search && props.active) {
          repoListExpanded.value = true;
        }
      }
    );

    const onRepoSettingsMenuOpen = () => {
      menuIsOpen.value = true;
      emit('repo-settings-hover-menu-opened');
    };

    const onRepoSettingsMenuClose = () => {
      menuIsOpen.value = false;
      emit('repo-settings-hover-menu-closed');
    };

    const isWorkspaceAdmin = computed(() =>
      store.getters['database/db_isWorkspaceAdmin'](props.workspaceId, user.value.uid)
    );
    const repoExpiredDescription = computed(() => {
      if (isWorkspaceAdmin.value) {
        return 'Your free trial has expired, upgrade your subscription now to use this repo.';
      }
      return 'Your free trial has expired, please contact your workspace admin.';
    });

    return {
      repoDisplayName,
      repoDisplayNameTooltip,
      isChildActive,
      repoListExpanded,
      filtersStore,
      isDummyRepo,
      currentBranch,
      onRepoSettingsClick,
      repoUrl,
      repositoryProviderUrl,
      isChoosingBranch,
      repoRootFolderId,
      toggleRepoListExpand,
      handleNameClick,
      navigateToStatusPage,
      isRepoOutdated,
      isRepoHaveDrafts,
      trackHoverOutdatedDot,
      onExpandParent,
      onOutdatedIconClick,
      filter,
      branchNameDisplay,
      favoriteTooltip,
      onFavoriteIconClick,
      handleLineClick,
      favoriteIcon,
      hovering,
      menuIsOpen,
      onRepoSettingsMenuOpen,
      onRepoSettingsMenuClose,
      elementRef,
      repoIcon,
      isWorkspaceAdmin,
      repoExpiredDescription,
      setShowBillingPlans,
      providerDisplayName,
    };
  },
  methods: {
    clickedRepoTooltip(event) {
      // Avoiding the response of clicking on the repo section
      event.stopPropagation();
    },
    handleBranchNameClicked() {
      if (this.dummyRepo) {
        this.handleNameClick();
        return;
      }
      // Go To Repo page before opening the branch selector
      this.$emit('open-repo', this.repo);
      this.isChoosingBranch = true;
    },
  },
};
</script>

<style scoped lang="postcss">
.expand-toggle {
  display: inline-block;
  font-size: var(--body-L);
  border-radius: 50%;
  color: var(--text-color-disable);
  visibility: hidden;
  padding: 0;
  margin: 0 0 0 var(--space-xs);

  &.hidden {
    opacity: 0;
  }
}

.repo-line {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  min-width: 0;
  cursor: pointer;
  padding-right: calc(var(--space-sm) - 4px); /* 4px - account for the scrollbar. */
  font-size: var(--body-S);
  height: var(--sidebar-item-height);
  box-sizing: border-box;

  .repo-options {
    display: flex;
    align-items: center;
    font-size: var(--body-L);

    .repo-favorite-icon {
      padding-left: var(--space-xs);

      &.favorite {
        color: var(--color-bg-warning);
      }
    }
  }

  .repo-data-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }

  .repo-link {
    display: block;
    padding: var(--space-xs) var(--space-sm) var(--space-xs) 0;
  }

  .repo-name-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    gap: var(--space-xs);

    .repo-name {
      overflow: hidden;
      flex-shrink: 0;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .branch-name {
      color: var(--text-color-secondary);
    }
  }

  &.disabled {
    .repo-name-wrapper {
      opacity: 0.5;
    }
  }

  &.child-active {
    .expand-toggle {
      visibility: visible;
    }

    .repo-name-wrapper {
      .repo-name {
        .repo-name-link {
          color: var(--text-color-link);
          font-weight: 600;
        }
      }
    }
  }

  .repo-name-wrapper:hover .repo-name-link:not(.disabled) {
    color: var(--text-color-link);
  }

  &.active {
    position: sticky;
    top: var(--sidebar-item-height); /* Place it right below the 'home' line. */
    z-index: 1;

    &.child-active {
      background-color: var(--color-bg);
    }

    &:not(.child-active) {
      background-color: var(--color-selected);

      .expand-toggle {
        visibility: visible;
      }

      .repo-name-wrapper {
        .repo-name {
          .repo-name-link {
            color: var(--text-color-link);
            font-weight: 600;
          }
        }
      }
    }
  }

  &:hover {
    .repo-options {
      .repo-settings-icon,
      .repo-favorite-icon {
        display: flex;
      }
    }
  }

  &:hover:not(.disabled) {
    background-color: var(--color-surface);

    .repo-options {
      .repo-add-icon {
        display: flex;
      }
    }

    .expand-toggle {
      visibility: visible;
    }
  }
}

.disabled {
  cursor: not-allowed;
}

.repo-icon {
  object-fit: contain;
  align-self: center;
  padding: 0;
}

.icon-github {
  font-size: var(--body-L);
}

.repository-wrapper:hover .subscribed-repo {
  background-color: var(--color-hover);
}

.bold-text {
  font-weight: 800;
}

.status-icon {
  color: var(--text-color-secondary);
  position: relative;
  padding: 2px;

  &.outdated::after {
    position: absolute;
    width: 5px;
    height: 5px;
    content: '';
    background-color: var(--text-color-error-strong);
    border-radius: 50%;
    top: 16px;
    left: 16px;
  }
}

.has-drafts {
  position: relative;

  &::after {
    position: absolute;
    width: 5px;
    height: 5px;
    content: '';
    background-color: var(--text-color-warning-strong);
    border-radius: 50%;
    top: 10px;
    left: 11px;
  }
}

.disabled-popup {
  padding: var(--space-base);
  width: 250px;
  display: flex;
  flex-direction: column;
}

.paywall-popup-action {
  margin-top: var(--space-base);
}

.popover-button {
  display: flex;
  justify-content: center;
}
</style>
