import LinkPopover from '@/components/LinkPopover.vue';
import { Editor, getAttributes, getMarkRange } from '@tiptap/core';
import Link from '@tiptap/extension-link';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { omit } from 'lodash-es';
import { showEditorPopover } from '../popover';
import { getSwimmEditorServices } from './Swimm';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    link2: {
      editOrInsertLink: (options?: { focus?: boolean; noTextInput?: boolean }) => ReturnType;
    };
  }
}

const ALLOWED_PROTOCOLS = ['http:', 'https:', 'file:', 'ftp:'];

export default Link.extend({
  addAttributes() {
    return {
      ...omit(this.parent?.(), 'class'),
      title: {
        default: null,
      },
    };
  },

  inclusive() {
    return false;
  },

  addOptions() {
    return {
      ...this.parent?.(),
      openOnClick: false,
      validate(url) {
        try {
          const parsed = new URL(url);

          if (!ALLOWED_PROTOCOLS.includes(parsed.protocol)) {
            return false;
          }

          return true;
        } catch (e) {
          return false;
        }
      },
      HTMLAttributes: { target: '_blank', rel: 'noopener noreferrer nofollow', class: 'link' },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      // Note that this command will trample other marks in the range when editing a link
      editOrInsertLink:
        (options) =>
        ({ can, dispatch, state, editor }) => {
          const range = getMarkRange(state.selection.$head, this.type) ?? state.selection;
          if (!dispatch) {
            if (editor.isActive('mermaid')) {
              return false;
            }
            return can().insertContentAt(range, {
              type: 'text',
              text: '',
              marks: [
                {
                  type: 'link',
                  attrs: {
                    href: '',
                  },
                },
              ],
            });
          }
          const attrs = getAttributes(state, this.type);
          let curHref: string | undefined = attrs.href;
          // Workaround for the case that the link is only over an inline image
          // and something in the selection goes wrong
          if (!attrs || Object.keys(attrs).length === 0) {
            const pos = state.selection.from;
            const node = editor.state.doc.nodeAt(pos);
            if (node?.type.name === 'image') {
              curHref = node.marks.find((m) => m.type.name === 'link')?.attrs.href;
            }
          }
          setTimeout(async () => {
            let selected: { url: string; text: string; textChanged: boolean } | undefined;
            let doDelete = false;
            await showEditorPopover(editor, LinkPopover, {
              initialUrl: curHref,
              initialText: state.doc.textBetween(range.from, range.to),
              focus: options?.focus ?? true,
              noTextInput: options?.noTextInput ?? false,
              onSelected: (newSelected: { url: string; text: string; textChanged: boolean }) => {
                selected = newSelected;
              },
              onDelete: () => {
                doDelete = true;
              },
            });

            if (doDelete) {
              editor.chain().focus().setTextSelection(range).unsetLink().run();
            } else if (selected != null) {
              if (!selected.textChanged) {
                editor
                  .chain()
                  .focus()
                  .setTextSelection(range)
                  .setLink({ href: selected.url })
                  .setMeta('preventAutolink', true)
                  .run();
              } else {
                editor
                  .chain()
                  .focus()
                  .insertContentAt(range, {
                    type: 'text',
                    text: selected.text || selected.url,
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: selected.url,
                        },
                      },
                    ],
                  })
                  .setMeta('preventAutolink', true)
                  .run();
              }
            }
          });

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [...(this.parent?.() ?? []), clickHandler(this.editor)];
  },
});

export function clickHandler(editor: Editor): Plugin {
  const swimmEditorServices = getSwimmEditorServices(editor);

  return new Plugin({
    key: new PluginKey('handleClickLink'),
    props: {
      handleClick: (view, pos, event) => {
        if (event.button !== 0) {
          return false;
        }

        const link = (event.target as HTMLElement)?.closest('a.link') as HTMLAnchorElement;

        const href = link?.href;

        if (link && href) {
          if (!view.editable) {
            swimmEditorServices.external.openLink(href);
          } else {
            editor.chain().setTextSelection(pos).editOrInsertLink({ focus: false }).run();
          }

          return true;
        }

        return false;
      },

      handleDOMEvents: {
        click: (view, event) => {
          const link = (event.target as HTMLElement)?.closest('a.link') as HTMLAnchorElement;

          // Avoid a rogue click handler in VS Code from opening the link yet again
          if (link) {
            event.preventDefault();
            event.stopImmediatePropagation();
          }
        },
      },
    },
  });
}
