<script setup lang="ts">
import { type PropType, computed } from 'vue';
import { DocumentationTypes } from '@/common/consts';
import { useMoreActionsOptions } from '../../home/composables/moreActionsOptions';
import type { SidebarFolderTreeItem } from './SidebarRepoLineFolderTreeItem.vue';
import ShareModal from '@/common/components/modals/ShareModal.vue';
import MoveFolderModal from '@/modules/folders/components/MoveFolderModal.vue';
import { IconButton } from '@swimm/ui';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { isRepoIdDummyRepo, productEvents } from '@swimm/shared';

const props = defineProps({
  treeItem: { type: Object as PropType<SidebarFolderTreeItem>, required: true },
  repoId: { type: String, required: true },
  type: { type: String as PropType<(typeof DocumentationTypes)[keyof typeof DocumentationTypes]>, required: true },
});

defineEmits(['click']);

const analytics = useAnalytics();

const {
  shareModalOptions,
  moveFolderModalOptions,
  closeShareModal,
  handleGetShareableLinkClick,
  handleDuplicateClick,
  handleEditClick,
  handleDeleteClick,
  handleDiscardClick,
  handleMoveToFolderClick,
  handleFolderRenameClick,
  handlePrintModeClick,
} = useMoreActionsOptions();

const isDummyRepo = computed(() => isRepoIdDummyRepo(props.repoId));
const isAllowedToDelete = computed(() => !isDummyRepo.value);

function reportSelection(option) {
  analytics.track(productEvents.SELECTED_SIDEBAR_ELLIPSIS_MENU_OPTION, {
    'Entity Type': props.type,
    'Entity ID': props.treeItem.id,
    Option: option,
  });
}
</script>

<template>
  <VDropdown class="sidebar-item-actions" theme="dropdown-no-arrow" placement="right-start">
    <IconButton class="icon" name="more" no-padding @click="$emit('click')" />
    <template #popper>
      <div class="options">
        <template v-if="type === DocumentationTypes.FOLDER">
          <EllipsisOption
            v-close-popper
            name="Rename"
            icon="pencil"
            :handler="
              () => {
                handleFolderRenameClick({ resource: treeItem });
                reportSelection('Rename');
              }
            "
          />
          <EllipsisOption
            v-if="isAllowedToDelete"
            v-close-popper
            name="Delete"
            icon="trash-outline"
            :handler="
              () => {
                handleDeleteClick({ resource: treeItem, repoId });
                reportSelection('Delete');
              }
            "
          />
          <EllipsisOption
            v-close-popper
            name="Move to folder..."
            icon="move"
            :handler="
              () => {
                handleMoveToFolderClick({
                  resource: treeItem,
                });
                reportSelection('Move');
              }
            "
          />
        </template>
        <template v-else>
          <EllipsisOption
            v-close-popper
            name="Get shareable link"
            icon="share"
            :handler="
              () => {
                handleGetShareableLinkClick({
                  resource: treeItem,
                  repoId,
                  entityName: treeItem.name,
                  type,
                });
                reportSelection('Share');
              }
            "
          />
          <EllipsisOption
            v-if="Boolean(treeItem.id) && !treeItem.isNew && type === DocumentationTypes.DOC"
            v-close-popper
            name="Duplicate"
            icon="duplicate"
            :handler="
              () => {
                handleDuplicateClick({
                  resource: treeItem,
                  repoId,
                  type,
                });
                reportSelection('Duplicate');
              }
            "
          />
          <EllipsisOption
            v-close-popper
            name="Edit"
            icon="pencil"
            :handler="
              () => {
                handleEditClick({
                  resource: treeItem,
                  repoId,
                  type,
                });
                reportSelection('Edit');
              }
            "
          />
          <EllipsisOption
            v-if="Boolean(treeItem.id) && !treeItem.isNew && isAllowedToDelete"
            v-close-popper
            name="Delete"
            icon="trash-outline"
            :handler="
              () => {
                handleDeleteClick({
                  resource: treeItem,
                  repoId,
                });
                reportSelection('Delete');
              }
            "
          />
          <EllipsisOption
            v-close-popper
            v-if="Boolean(treeItem.draftId)"
            name="Discard draft"
            icon="discard"
            :handler="
              () => {
                handleDiscardClick({
                  draftId: treeItem.draftId,
                });
                reportSelection('Discard');
              }
            "
          />
          <EllipsisOption
            v-close-popper
            name="Move to folder..."
            icon="move"
            :handler="
              () => {
                handleMoveToFolderClick({ resource: treeItem });
                reportSelection('Move');
              }
            "
          />
          <EllipsisOption
            v-close-popper
            name="Print mode"
            icon="printer"
            :handler="
              () => {
                handlePrintModeClick(treeItem.id);
                reportSelection('Print mode');
              }
            "
          />
        </template>
      </div>
    </template>
  </VDropdown>
  <ShareModal
    v-if="shareModalOptions.showShareModal"
    :show-share-modal="shareModalOptions.showShareModal"
    :entity-id="shareModalOptions.entityId"
    :entity-name="shareModalOptions.entityName"
    :entity="shareModalOptions.entity"
    :repo-id="shareModalOptions.repoId"
    :entity-link="shareModalOptions.entityLink"
    @close="closeShareModal"
  />
  <MoveFolderModal
    v-if="moveFolderModalOptions.show"
    :show="moveFolderModalOptions.show"
    :repo-id="repoId"
    @close="() => (moveFolderModalOptions = { show: false, items: [] })"
    :items="moveFolderModalOptions.items"
  />
</template>

<style scoped lang="postcss">
.icon {
  font-size: var(--body-XS);
  border-radius: 2px;
}
</style>
