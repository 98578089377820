import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import {
  type FirestoreCollectionNamesType,
  type GitProviderName,
  type StatisticsRequest,
  getLoggerNew,
} from '@swimm/shared';
import type {
  createWorkspaceInSalesforceTypes,
  updateLoginInSalesforceTypes,
  updateRepoInSalesforceTypes,
  updateSalesforceLeadWithGitHostingTypes,
  updateSignupInSalesforceTypes,
  updateWorkspaceInSalesforceTypes,
  updateWorkspaceUserInSalesforceTypes,
} from '@/common/utils/salesforce/salesforce-types';

type HttpsCallableResult = firebase.functions.HttpsCallableResult;
type HttpsCallableOptions = firebase.functions.HttpsCallableOptions;

const logger = getLoggerNew(__modulename);

const MAX_CLOUD_FUNCTION_RUN_TIME = 9 * 60 * 1000; // 9 minutes

export class CloudFunctions {
  static async inviteSwimmer(data: { isWeb: boolean; workspaceId: string; emails: string[] }) {
    return await CloudFunctions.wrapCall('inviteSwimmer', data);
  }

  static async sendWelcomeEmail(data: { isWeb: boolean; isMobile: boolean }) {
    return await CloudFunctions.wrapCall('sendWelcomeEmail', data);
  }

  static async subscribeToPlan(data: {
    paymentMethod;
    name;
    companyName;
    email;
    country;
    state;
    currency;
    workspaceId;
    taxId;
    taxType;
    address;
  }) {
    return await CloudFunctions.wrapCall('subscribeToPlan', data);
  }

  static async subscribeToTrial(data: {
    workspaceId: string;
    productSKU: string;
    billingPeriod?: 'ANNUALLY' | 'MONTHLY';
    quantity?: number;
  }) {
    return await CloudFunctions.wrapCall('subscribeToTrial', data);
  }

  static async fetchSubscriptionCreditCard(data: { workspaceId: string }) {
    return await CloudFunctions.wrapCall('fetchSubscriptionCreditCard', data);
  }

  static async fetchSubscriptionCustomerDetails(data: { workspaceId: string }) {
    return await CloudFunctions.wrapCall('fetchSubscriptionCustomerDetails', data);
  }

  static async updateSubscriptionCustomerDetails(data: { workspaceId: string; updateParams: object }) {
    return await CloudFunctions.wrapCall('updateSubscriptionCustomerDetails', data);
  }

  static async updateRepositoryDocumentFields(data: { repoId: string; workspaceId: string; fieldsToUpdate: object }) {
    return await CloudFunctions.wrapCall('updateRepositoryDocumentFields', data);
  }

  static async removeUserFromWorkspace(data: { workspaceId: string; userId: string }) {
    return await CloudFunctions.wrapCall('removeUserFromWorkspace', data);
  }

  static async addWorkspaceToPlanManager(data: { workspaceId: string; workspaceName?: string; license?: string }) {
    return await CloudFunctions.wrapCall('addWorkspaceToPlanManager', data);
  }

  static async resendInvite(data: { workspaceId: string; email: string }) {
    return await CloudFunctions.wrapCall('resendInvite', data);
  }

  static async removeInvite(data: { workspaceId: string; email: string }) {
    return await CloudFunctions.wrapCall('removeInvite', data);
  }

  static async getWorkspaceSwimmersStatuses(data: { workspaceId: string }) {
    return await CloudFunctions.wrapCall('getWorkspaceSwimmersStatuses', data);
  }

  static async getWorkspacesForUserDomain() {
    return await CloudFunctions.wrapCall('getWorkspacesForUserDomain');
  }

  static async setHostedDomainField(data: { workspaceId: string }) {
    return await CloudFunctions.wrapCall('setHostedDomainField', data);
  }

  static async addRepoToWorkspace(data: { workspaceId: string; repoId: string; isPrivate: boolean; gitUrl: string }) {
    return await CloudFunctions.wrapCall('addRepoToWorkspace', data);
  }

  static async postApprovedToWorkspace(data: { workspaceId: string; email: string }) {
    return await CloudFunctions.wrapCall('postApprovedToWorkspace', data);
  }

  static async updateLoginInSalesforce(data: updateLoginInSalesforceTypes) {
    return await CloudFunctions.wrapCall('updateLoginInSalesforce', data);
  }

  static async updateSalesforceLeadWithGitHosting(data: updateSalesforceLeadWithGitHostingTypes) {
    return await CloudFunctions.wrapCall('updateSalesforceLeadWithGitHosting', data);
  }

  static async updateSignupInSalesforce(data: updateSignupInSalesforceTypes) {
    return await CloudFunctions.wrapCall('updateSignupInSalesforce', data);
  }

  static async updateRepoDbEntry(data: { repoId: string; isPrivate?: boolean; defaultBranch?: boolean }) {
    return await CloudFunctions.wrapCall('updateRepoDbEntry', data);
  }

  static async getAllWorkspacesContainingRepo(data: { repoId: string }) {
    return await CloudFunctions.wrapCall('getAllWorkspacesContainingRepo', data);
  }

  static async acceptInvitation(data: { workspaceId: string; user: object }) {
    return await CloudFunctions.wrapCall('acceptInvitation', data);
  }

  static async postJoinWorkspace(data: { workspaceId: string; user: object }) {
    return await CloudFunctions.wrapCall('postJoinWorkspace', data);
  }

  static async incrementResourceViews(data: {
    containerType: FirestoreCollectionNamesType;
    containerId: string;
    resourceType: FirestoreCollectionNamesType;
    resourceId: string;
  }) {
    return await CloudFunctions.wrapCall('incrementResourceViews', data);
  }

  static async requestInvite(data: { workspaceId: string }) {
    return await CloudFunctions.wrapCall('requestInvite', data);
  }

  static async isRequestedToJoinWorkspace(data: { workspaceId: string }) {
    return await CloudFunctions.wrapCall('isRequestedToJoinWorkspace', data);
  }

  static async updateWorkspaceUserInSalesforce(data: updateWorkspaceUserInSalesforceTypes) {
    return await CloudFunctions.wrapCall('updateWorkspaceUserInSalesforce', data);
  }

  static async createWorkspaceInSalesforce(data: createWorkspaceInSalesforceTypes) {
    return await CloudFunctions.wrapCall('createWorkspaceInSalesforce', data);
  }

  static async updateWorkspaceInSalesforce(data: updateWorkspaceInSalesforceTypes) {
    return await CloudFunctions.wrapCall('updateWorkspaceInSalesforce', data);
  }

  static async updateRepoInSalesforce(data: updateRepoInSalesforceTypes) {
    return await CloudFunctions.wrapCall('updateRepoInSalesforce', data);
  }

  static async sayDocThanks(data: { userName: string; repoId: string; docId: string; thanksCode: string }) {
    return await CloudFunctions.wrapCall('sayDocThanks', data);
  }

  static async toggleWatchForUser(data: { userName: string; repoId: string; docId: string; watch: boolean }) {
    return await CloudFunctions.wrapCall('toggleWatchForUser', data);
  }

  static async changeSlackChannelToNotify(data: {
    workspaceId: string;
    repoId: string;
    channelId: string;
    channelName: string;
  }) {
    return await CloudFunctions.wrapCall('changeSlackChannelToNotify', data);
  }

  static async getSlackChannelList(data: { workspaceId: string; repoId: string }) {
    return await CloudFunctions.wrapCall('getSlackChannelList', data);
  }

  static async slackUserAuth(data: { slackTeamId: string; slackUserId: string; swimmUserId: string }) {
    return await CloudFunctions.wrapCall('slackUserAuth', data);
  }

  static async deleteTag(data: { tagId: string; workspaceId: string }) {
    return await CloudFunctions.wrapCall('deleteTag', data);
  }

  static async statistics(data: StatisticsRequest) {
    return await CloudFunctions.wrapCall('statistics', data);
  }

  static async doesJumpCloudUserHaveAccess(data: { email: string; jumpCloudId: string }) {
    return await CloudFunctions.wrapCall('doesJumpCloudUserHaveAccess', data, { timeout: MAX_CLOUD_FUNCTION_RUN_TIME });
  }

  static async fetchEnterpriseAuthConfig(data: { workspaceId: string; enterpriseName: string }) {
    return await CloudFunctions.wrapCall('fetchEnterpriseAuthConfig', data, {
      timeout: MAX_CLOUD_FUNCTION_RUN_TIME,
    });
  }

  static async fetchAuthConfig(data: { gitHostingUrls: string[] }) {
    return await CloudFunctions.wrapCall('fetchAuthConfig', data, { timeout: MAX_CLOUD_FUNCTION_RUN_TIME });
  }

  static async refreshOAuthToken(data: { provider: GitProviderName; gitHosting: string; refreshToken: string }) {
    return await CloudFunctions.wrapCall('refreshOAuthToken', data, { timeout: MAX_CLOUD_FUNCTION_RUN_TIME });
  }

  static async updateUserProfile(data: { displayName?: string; uid: string; photoURL?: string }) {
    return await CloudFunctions.wrapCall('updateUserProfile', data);
  }

  static async updateWorkspaceAndReposFeature(data: {
    workspaceId: string;
    workspaceSettingsField: string;
    repoIntegrationsField: string;
    newValue: boolean;
  }) {
    return await CloudFunctions.wrapCall('updateWorkspaceAndReposFeature', data);
  }

  static async revokeAllUserSessions(data: { uid: string; signoutSource: 'WebApp' | 'IDE-VSCode' | 'IDE-Jetbrains' }) {
    return await CloudFunctions.wrapCall('revokeAllUserSessions', data);
  }

  /** ***
   * @param functionName
   * @param data
   * @param options
   * a wrapper over calling a cloud function. Gets the function name
   * and (if exists) the parameters as object
   */

  private static async wrapCall(
    functionName: string,
    data?: object,
    options?: HttpsCallableOptions
  ): Promise<HttpsCallableResult> {
    try {
      const functionHandle = firebase.functions().httpsCallable(functionName, options);
      return await functionHandle(data);
    } catch (err) {
      const errorMessage = `Failed to call to function "${functionName}", error: ${err}`;
      logger.error({ err }, errorMessage);
      throw new Error(errorMessage);
    }
  }

  static async getAuthToken(forceRefresh = false): Promise<string> {
    return firebase.auth().currentUser.getIdToken(forceRefresh);
  }
}
