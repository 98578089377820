<script setup lang="ts">
import { type Component, computed, ref } from 'vue';
import { BaseButton, BaseHeading, BaseIcon, BaseLayoutGap, type IconsType } from '@swimm/reefui';
import type { Command, Editor } from '@tiptap/core';
import { BubbleMenu, isTextSelection } from '@tiptap/vue-3';
import { CellSelection } from '@tiptap/pm/tables';
import { directive as vTippy } from 'vue-tippy';
import { Keyboard, keyboardShortcutUtils, productEvents, trimBoth } from '@swimm/shared';
import type { BubbleMenuPluginProps } from '@tiptap/extension-bubble-menu';
import SmartTokenIconSVG from '@/components/SmartTokenIconSVG.vue';
import { getSwimmEditorServices } from '..';
import Code from '@tiptap/extension-code';

const props = defineProps<{ editor?: Editor }>();

const bubbleMenu = ref<InstanceType<typeof BubbleMenu>>();

interface BubbleMenuItem {
  name: string;
  icon?: IconsType | Component;
  tooltip?: string;
  isActive?: () => boolean;
  command: () => Command;
  title?: string; // set if you want to display text + icon and not only icon
  group: 'smart-token' | 'format' | 'ai'; // we add divider between items of different groups
}

const BUBBLE_MENU_ITEMS: BubbleMenuItem[] = [
  {
    name: 'smart_token',
    isActive: () => false,
    icon: SmartTokenIconSVG,
    tooltip: 'Convert to a Smart Token to keep up to date with the code',
    title: 'Smart Token',
    group: 'smart-token',
    command:
      () =>
      ({ editor, chain, dispatch }) => {
        let isOk = true;
        let isFirstTextLeaf = true;
        const { from, to } = editor.state.selection;
        let isInlineCodeBlock = false;
        editor.state.doc.nodesBetween(from, to, (node) => {
          // don't allow if we are inside swmToken
          if (node.type.name === 'swmToken') {
            isOk = false;
            return false;
          }
          // we allow converting into smart token if there is only single leaf node and it is of type text
          // this is to avoid the case of text nodes in different lines
          // (we also don't support text on the same line with different marks, but this is ok product-wise)
          if (node.isLeaf) {
            if (!node.isText || !isFirstTextLeaf) {
              isOk = false;
              return false;
            } else {
              isFirstTextLeaf = false;
              isInlineCodeBlock = node.marks.some((mark) => mark.type.name === Code.name);
            }
          }
          return true;
        });
        if (!isOk) {
          return false;
        }
        let queryText = editor.state.doc.textBetween(from, to);
        if (dispatch) {
          const services = getSwimmEditorServices(editor);
          services.external.trackEvent(productEvents.CLICKED_SMART_TOKEN_IN_BUBBLE_MENU, {
            'Leading Space': queryText.startsWith(' '),
            'Trailing Space': queryText.endsWith(' '),
            'Contains Spaces': queryText.includes(' '),
            'Is Inline Code Block': isInlineCodeBlock,
          });
        }

        let newRange: { from: number; to: number } | null = null;
        if (dispatch) {
          // we reduce the selection if it contains whitespaces at the beginning or end
          // this happenns on wsl when you double click a word
          const { trimmed, fromStart, fromEnd } = trimBoth(queryText);
          if (fromStart > 0 || fromEnd > 0) {
            newRange = { from: from + fromStart, to: to - fromEnd };
            queryText = trimmed;
          }
        }
        if (newRange) {
          return chain().focus().setTextSelection(newRange).openSwmTokenSelectionMenu(queryText).run();
        } else {
          return chain().focus().openSwmTokenSelectionMenu(queryText).run();
        }
      },
  },
  {
    name: 'bold',
    icon: 'bold',
    tooltip: `Bold (${keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, 'B')})`,
    isActive: () => props.editor?.isActive('bold') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleBold().run();
      },
  },
  {
    name: 'italic',
    icon: 'italic',
    tooltip: `Italic (${keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, 'I')})`,
    isActive: () => props.editor?.isActive('italic') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleItalic().run();
      },
  },
  {
    name: 'strike',
    icon: 'strike',
    tooltip: `Strike-through (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.SHIFT, 'S')
    )})`,
    isActive: () => props.editor?.isActive('strike') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleStrike().run();
      },
  },
  {
    name: 'h1',
    icon: 'text-headline1',
    tooltip: `Heading 1 (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.ALT, '1')
    )})`,
    isActive: () => props.editor?.isActive('heading', { level: 1 }) ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleHeading({ level: 1 }).run();
      },
  },
  {
    name: 'h2',
    icon: 'text-headline2',
    tooltip: `Heading 2 (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.ALT, '2')
    )})`,
    isActive: () => props.editor?.isActive('heading', { level: 2 }) ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleHeading({ level: 2 }).run();
      },
  },
  {
    name: 'h3',
    icon: 'text-headline3',
    tooltip: `Heading 3 (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.ALT, '3')
    )})`,
    isActive: () => props.editor?.isActive('heading', { level: 3 }) ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleHeading({ level: 3 }).run();
      },
  },
  {
    name: 'h4',
    icon: 'text-headline4',
    tooltip: `Heading 4 (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.ALT, '4')
    )})`,
    isActive: () => props.editor?.isActive('heading', { level: 4 }) ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleHeading({ level: 4 }).run();
      },
  },
  {
    name: 'ul',
    icon: 'ul',
    tooltip: `Bullet list (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.SHIFT, '8')
    )})`,
    isActive: () => props.editor?.isActive('bulletList') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleBulletList().run();
      },
  },
  {
    name: 'ol',
    icon: 'ol',
    tooltip: `Numbered list (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.SHIFT, '7')
    )})`,
    isActive: () => props.editor?.isActive('orderedList') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleOrderedList().run();
      },
  },
  {
    name: 'code',
    icon: 'dev',
    tooltip: `Code (${keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.CTRL, 'E')})`,
    isActive: () => props.editor?.isActive('code') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleCode().run();
      },
  },
  {
    name: 'blockquote',
    icon: 'quote',
    tooltip: `Quote (${keyboardShortcutUtils.getKeyboardCombinationString(
      Keyboard.Modifiers.CTRL,
      keyboardShortcutUtils.getKeyboardCombinationString(Keyboard.Modifiers.SHIFT, 'B')
    )})`,
    isActive: () => props.editor?.isActive('blockquote') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain, editor }) => {
        if (editor.isActive('mermaid')) {
          return false;
        }
        return chain().focus().toggleBlockquote().run();
      },
  },
  {
    name: 'link',
    icon: 'link',
    tooltip: 'Set Link',
    isActive: () => props.editor?.isActive('link') ?? false,
    group: 'format',
    command:
      () =>
      ({ chain }) => {
        return chain().editOrInsertLink({ noTextInput: true }).run();
      },
  },
  {
    name: 'ai',
    icon: 'magic',
    tooltip: 'Improve with AI',
    group: 'ai',
    command:
      () =>
      ({ chain }) => {
        return chain().improveTextUsingAI().run();
      },
  },
];

// Based on https://github.com/ueberdosis/tiptap/blob/c4e655fb07ca516190b2f0b9abfcb825ae5aa52e/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L47-L73
const shouldShow: Exclude<BubbleMenuPluginProps['shouldShow'], null> = ({ editor, view, state, from, to }) => {
  const { doc, selection } = state;
  const { empty } = selection;

  if (selection instanceof CellSelection) {
    return false;
  }

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection);

  const excluded =
    editor.isActive('codeBlock') ||
    editor.isActive('blockImage') ||
    editor.isActive('swmSnippetPlaceholder') ||
    editor.isActive('youtube');

  // When clicking on a element inside the bubble menu the editor "blur" event
  // is called and the bubble menu item is focused. In this case we should
  // consider the menu as part of the editor and keep showing the menu
  const isChildOfMenu = bubbleMenu.value?.$el.contains(document.activeElement);

  const hasEditorFocus = view.hasFocus() || isChildOfMenu;

  if (!hasEditorFocus || empty || isEmptyTextBlock || excluded || !props.editor?.isEditable) {
    return false;
  }

  return true;
};

const applicableBubbleMenuItems = computed(() => {
  return BUBBLE_MENU_ITEMS.filter((item) => props.editor?.can().command(item.command()));
});
</script>

<template>
  <BubbleMenu
    v-if="editor"
    ref="bubbleMenu"
    :editor="editor"
    class="bubble-menu"
    data-testid="bubble-menu"
    :tippy-options="{
      appendTo: (ref: Element) => ref.parentElement!,
      duration: 100,
      theme: 'none',
      maxWidth: 'none',
    }"
    :should-show="shouldShow"
  >
    <BaseLayoutGap size="xxsmall">
      <template v-for="(item, itemIndex) of applicableBubbleMenuItems" :key="item.name">
        <div
          v-if="itemIndex > 0 && item.group !== applicableBubbleMenuItems[itemIndex - 1]?.group"
          class="group-divider"
        ></div>
        <!-- Workaround for button margins -->
        <span>
          <BaseButton
            v-tippy="item.tooltip"
            variant="tertiary"
            :data-testid="`bubble-menu-item-${item.name}`"
            :class="{ active: item.isActive?.() }"
            :aria-pressed="item.isActive?.()"
            size="small"
            @click="editor.commands.command(item.command())"
          >
            <template v-if="item.title" #default>
              <BaseHeading :level="6">{{ item.title }}</BaseHeading>
            </template>
            <template #leftIcon>
              <!-- TODO v-tippy probably needs a proper theme, didn't use v-tooltip for floating-vue as it is buggy -->
              <BaseIcon v-if="typeof item.icon === 'string'" :name="item.icon" />
              <component :is="item.icon" v-else-if="item.icon" />
            </template>
          </BaseButton>
        </span>
      </template>
    </BaseLayoutGap>
  </BubbleMenu>
</template>

<style scoped lang="scss">
.bubble-menu {
  border: 1px solid var(--color-border-default);
  border-radius: 4px;
  background: var(--color-bg-default);
  padding: var(--space-xxsmall);
  // TODO The default line-height causes the bubble menu to be bigger than intended
  line-height: 0.5rem;

  // TODO Should probably be a prop of the BaseButton
  .active {
    background-color: var(--color-bg-surface-hover);
  }

  .group-divider {
    height: var(--scale-large);
    width: 1px;
    background-color: var(--color-border-default-subtle);
  }

  /* eslint-disable vue-scoped-css/no-unused-selector */
  .icon.icon-magic {
    color: var(--text-color-magic-strong);
  }
}
</style>
