import { GitIconType, GitProviderIcons, gitUnknownProviderIcon } from '../types';

export function toValidBranchName(string: string, delimiter = '_'): string {
  // https://stackoverflow.com/a/31976060/115198
  return string.replace(/[\s?~^:*[\]|<>"\\.]+/g, delimiter);
}

export function getDefaultUpdateBranchPrefix() {
  return 'update-swimm-docs-';
}

export function makeGetCurrentNameCacheKey({
  oldFilePath,
  destCommit,
  repoId,
}: {
  oldFilePath: string;
  destCommit?: string;
  repoId: string;
}): string {
  if (!destCommit) {
    // In case we run against the working dir and the file has changed - we don't rely on cache
    return '';
  }
  return `${repoId}-${destCommit}-${oldFilePath}`;
}

export function createCounterObject(array) {
  const counterObject = {};
  array.forEach((val) => (counterObject[val] = (counterObject[val] || 0) + 1));
  return counterObject;
}

export function sortCounter(counterObject) {
  const sortable = [];
  for (const key in counterObject) {
    sortable.push([key, counterObject[key]]);
  }

  sortable.sort((a, b) => {
    return b[1] - a[1];
  });

  return sortable;
}

export function getGitProviderIconName(provider: string | null | undefined): GitIconType {
  return GitProviderIcons[provider] || gitUnknownProviderIcon;
}

/**
 * Check if any of the patterns are found in the .gitignore file (see tests for examples)
 * The pattern should not start with a "!" (negate pattern)
 * @param gitIgnoreContent - The content of the .gitignore file
 */
export function isGitIgnoreValid(gitIgnoreContent: string): boolean {
  const swmPattern = /^(?!!)\.swm(\/|\*|\\)|^(?!!)\*.sw(\.\*|\*|\.md)|^(?!!)\*\.sw\.md/;

  const regex = new RegExp(swmPattern, 'gm');
  return !regex.test(gitIgnoreContent);
}

export function buildRepoFullName(repo: { owner?: string; name?: string } | undefined | null): string {
  return repo?.owner && repo?.name ? `${repo?.owner}/${repo?.name}` : '';
}
