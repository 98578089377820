// @ts-strict
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { TeamProjectReference } from 'azure-devops-extension-api/Core/Core';
import {
  GitCommit,
  GitCommitChanges,
  GitCommitDiffs,
  GitCommitRef,
  GitPullRequest,
  GitPullRequestCommentThread,
  GitPush,
  GitRef,
  GitRefUpdate,
  GitRefUpdateResult,
  GitRepository,
  GitTreeRef,
} from 'azure-devops-extension-api/Git/Git';
import { PolicyConfiguration } from 'azure-devops-extension-api/Policy/Policy';
import { GitProviderName } from '../../types';
import UrlUtils from '../../utils/url-utils';
import gitProviderUtils, { getGitHostingToken } from '../git-provider-utils';
import { DriverOptions } from '../index';
import {
  AzureCreateCommentParams,
  AzureGetCommitsParams,
  AzureGetDiffPrams,
  AzureGetFileContentParams,
  AzureGetPullRequestPrams,
  AzureListBranchesParams,
  AzureListBranchesPolicyParams,
  AzureListCommitsParams,
  AzureListPullRequestsParams,
  AzureListRepositoriesParams,
  AzureListTreeParams,
  AzurePaginateParams,
  AzurePaginateParamsDollar,
  AzurePaginationResponse,
  AzureRequestContext,
  ItemType,
} from './azure-transport-types';
import { getLoggerNew } from '#logger';
import { NotFoundError } from '../../types/swimm-errors';

const logger = getLoggerNew("packages/shared/src/git-utils/transports/azure-transport.ts");

const AZURE_DEFAULT_API_VERSION = '7.1-preview.1';

const TOKEN_EXPIRY_TIME_IN_SECONDS = 55 * 60; // The token expires after 1 hour, we will refresh it after 55 minutes

// The AXIOS is parse content automatically, so we need to return raw data.
// The transformResponse is returning the raw data instead of the parsed data.
const transformResponse = [
  (data) => {
    return data;
  },
];

type Params = { [key: string]: string | number | boolean | Params };

function prepareParams(params: Params = {}): Record<string, string | number | boolean> {
  const dict: ReturnType<typeof prepareParams> = {};
  for (const [key, value] of Object.entries(params)) {
    if (typeof value === 'object') {
      const subKeys = prepareParams(value);
      for (const [subKey, subValue] of Object.entries(subKeys)) {
        dict[`${key}.${subKey}`] = subValue;
      }
    } else {
      // In the old version of this function, we prefixed '$' in front of some special values. If something doesn't work, may this information help you.
      dict[key] = value;
    }
  }
  return dict;
}

export class AzureTransport {
  private axios: AxiosInstance;
  private config = { baseUrl: '', token: '', hostname: '', tenantId: '' };
  private _refreshTokenRequestCache: Promise<void>;

  public constructor({ authToken = '', baseUrl, tenantId }: DriverOptions = {}) {
    this.init({ authToken, baseUrl, tenantId });
  }

  private async init({
    baseUrl = gitProviderUtils.AZURE_BASE_URLS.CODE,
    tenantId,
    authToken = '',
  }: DriverOptions = {}): Promise<void> {
    logger.info(
      `Initializing AzureTransport with baseUrl: ${baseUrl} and tenantId: ${tenantId}. Initialized With token? ${!!authToken}`
    );
    this.config.baseUrl = baseUrl.replace(/\/+$/, '');
    this.config.tenantId = tenantId;
    this.config.hostname = UrlUtils.getGitHostingKey({
      provider: GitProviderName.AzureDevops,
      gitUrl: this.config.baseUrl,
      tenantId,
    });
    logger.info(`AzureTransport hostname set to: ${this.config.hostname}`);

    if (authToken) {
      this.config.token = authToken;
    } else {
      this.config.token = await getGitHostingToken(this.config.hostname);
      logger.info(`AzureTransport token set? ${!!this.config.token}`);
    }

    this.axios = Axios.create({
      baseURL: gitProviderUtils.AZURE_BASE_URLS.CODE,
      headers: { Authorization: `Bearer ${this.config.token}`, 'X-TFS-FedAuthRedirect': 'Suppress' },
    });
  }

  private configureAuthToken(): void {
    this.axios.defaults.headers.Authorization = `Bearer ${this.config.token}`;
    // TODO: implement the next lines only for tests OR try to use bearer with personal access token (PAT)
    // there is only one way to use exists token
    // https://github.com/microsoft/typed-rest-client/blob/5d146925f6145bedb890b085fb5f787106a24eac/lib/handlers/personalaccesstoken.ts#L24C30-L24C30
    // this.axios.defaults.auth = {
    //   username: 'PAT',
    //   password: this.config.token,
    // };
  }

  private async validateAuthToken(): Promise<void> {
    if (!this.config.token) {
      logger.info('Token is not set, trying to extract from the cache.');
      this.config.token = await getGitHostingToken(this.config.hostname);
      logger.info(`Token extracted from cache? ${!!this.config.token}`);
      this.configureAuthToken();
    }
    if (!this._refreshTokenRequestCache) {
      this._refreshTokenRequestCache = this.refreshAuthToken();
    }
    await this._refreshTokenRequestCache;
  }

  private async refreshAuthToken() {
    const newToken = await gitProviderUtils.refreshAuthToken({
      provider: GitProviderName.AzureDevops,
      hostname: UrlUtils.getUrlHostname(this.config.baseUrl),
      tenantId: this.config.tenantId,
      expiryTimeInSeconds: TOKEN_EXPIRY_TIME_IN_SECONDS,
    });

    if (newToken) {
      logger.info('Token refreshed successfully.');
      this.config.token = newToken;
      this.configureAuthToken();
    }

    this._refreshTokenRequestCache = null;
  }

  private buildRepositoryRequestRoute(context: AzureRequestContext): string {
    return `/${context.organization.name}/${context.project.name}/_apis/git/repositories/${context.repo.id}`;
  }

  private async request<
    T,
    R extends AxiosRequestConfig & {
      returnRaw?: boolean;
      skipTransformParameters?: boolean;
      customAPIVersion?: string;
    }
  >(
    method: string,
    url: string,
    { params, returnRaw: returnResponse, skipTransformParameters, customAPIVersion, ...options }: R
  ): Promise<R['returnRaw'] extends true ? AxiosResponse<T> : T> {
    const apiVersion =
      customAPIVersion === 'NONE' ? {} : { 'api-version': customAPIVersion ?? AZURE_DEFAULT_API_VERSION };
    await this.validateAuthToken();
    try {
      const response = await this.axios({
        method,
        url,
        params: skipTransformParameters ? { ...apiVersion, ...params } : prepareParams({ ...apiVersion, ...params }),
        ...options,
      });
      return returnResponse ? response : response.data;
    } catch (e) {
      const customMessage = e?.response?.data?.Message || e?.response?.data?.message;
      if (customMessage) {
        if (e?.response?.status === StatusCodes.NOT_FOUND) {
          throw new NotFoundError(customMessage, { cause: e });
        }
        throw new Error(customMessage, { cause: e });
      }

      throw e;
    }
  }

  deleteTokenFromMemory(): void {
    this.config.token = null;
  }

  getUserProfile(): Promise<{ id: string; coreAttributes: { DisplayName: { value: string } } }> {
    return this.request('GET', '/_apis/profile/profiles/me', {
      baseURL: gitProviderUtils.AZURE_BASE_URLS.ACCOUNT,
    });
  }

  listProjects({
    orgName,
    params,
  }: {
    orgName: string;
    params?: AzurePaginateParamsDollar;
  }): Promise<AzurePaginationResponse<TeamProjectReference>> {
    return this.request('GET', `/${orgName}/_apis/projects`, {
      params,
    });
  }

  listRepositories({
    orgName,
    projectName,
    params,
  }: {
    orgName: string;
    projectName: string;
    params?: AzureListRepositoriesParams;
  }): Promise<AzurePaginationResponse<GitRepository>> {
    return this.request('GET', `/${orgName}/${projectName}/_apis/git/repositories`, {
      params,
    });
  }

  async listBranches({
    context,
    params,
  }: {
    context: AzureRequestContext;
    params?: AzureListBranchesParams;
  }): Promise<AzurePaginationResponse<GitRef> & { continuationToken?: string }> {
    const result = await this.request('GET', `${this.buildRepositoryRequestRoute(context)}/refs`, {
      params,
      returnRaw: true,
    });
    const continuationToken = result.headers['x-ms-continuationtoken'];
    return { ...(result.data as AzurePaginationResponse<GitRef>), continuationToken };
  }

  async getBranch({
    context,
    params,
    branchName,
  }: {
    context: AzureRequestContext;
    branchName: string;
    params?: Omit<AzureListBranchesParams, 'filter'>;
  }): Promise<GitRef | undefined> {
    try {
      const fixedParams: AzureListBranchesParams = { ...params, filter: `heads/${branchName}` };
      const result = await this.listBranches({ context, params: fixedParams });
      // `result` should contain only a single element unless two branches start with the same prefix
      return result.value?.find((branch) => branch.name === `refs/heads/${branchName}`);
    } catch (err) {
      logger.error({ err }, `Error while getting branch ${branchName}: ${err.message}`);
      return undefined;
    }
  }

  async listCommits({
    context,
    params,
  }: {
    context: AzureRequestContext;
    params?: AzureListCommitsParams;
  }): Promise<AzurePaginationResponse<GitCommitRef>> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/commits`, { params });
  }

  async getCommit({
    context,
    commitId,
    params,
  }: {
    context: AzureRequestContext;
    commitId: string;
    params?: AzureGetCommitsParams;
  }): Promise<GitCommit> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/commits/${commitId}`, { params });
  }

  getPullRequest({
    context,
    pullRequestId,
    params,
  }: {
    context: AzureRequestContext;
    pullRequestId: string;
    params?: AzureGetPullRequestPrams;
  }): Promise<GitPullRequest> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/pullRequests/${pullRequestId}`, {
      params,
    });
  }

  getDiff({ context, params }: { context: AzureRequestContext; params?: AzureGetDiffPrams }): Promise<GitCommitDiffs> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/diffs/commits`, {
      params,
    });
  }

  listPullRequests({
    context,
    params,
  }: {
    context: AzureRequestContext;
    params?: AzureListPullRequestsParams;
  }): Promise<AzurePaginationResponse<GitPullRequest>> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/pullrequests`, {
      params,
    });
  }

  // TODO: Fix thread related functions (only used in `azure-platform`).
  listThreads(
    projectId: string,
    repositoryId: string,
    pullRequestId: string
  ): Promise<AzurePaginationResponse<GitPullRequestCommentThread>> {
    return this.request(
      'GET',
      `/${projectId}/_apis/git/repositories/${repositoryId}/pullRequests/${pullRequestId}/threads`,
      { params: null }
    );
  }

  // TODO: Fix thread related functions (only used in `azure-platform`).
  createThread(
    projectId: string,
    repositoryId: string,
    pullRequestId: string,
    data?: AzureCreateCommentParams
  ): Promise<AzurePaginationResponse<GitPullRequestCommentThread>> {
    return this.request(
      'POST',
      `/${projectId}/_apis/git/repositories/${repositoryId}/pullRequests/${pullRequestId}/threads`,
      {
        data,
      }
    );
  }

  // TODO: Fix thread related functions (only used in `azure-platform`).
  updateThreadComment(
    projectId: string,
    repositoryId: string,
    pullRequestId: string,
    threadId: string,
    commentId: string,
    data?: { content: string }
  ): Promise<AzurePaginationResponse<GitPullRequestCommentThread>> {
    return this.request(
      'PATCH',
      `/${projectId}/_apis/git/repositories/${repositoryId}/pullRequests/${pullRequestId}/threads/${threadId}/comments/${commentId}`,
      {
        data,
      }
    );
  }

  // TODO: Fix thread related functions (only used in `azure-platform`).
  deleteThreadComment(
    projectId: string,
    repositoryId: string,
    pullRequestId: string,
    threadId: string,
    commentId: string
  ): Promise<AzurePaginationResponse<GitPullRequestCommentThread>> {
    return this.request(
      'DELETE',
      `/${projectId}/_apis/git/repositories/${repositoryId}/pullRequests/${pullRequestId}/threads/${threadId}/comments/${commentId}`,
      {}
    );
  }

  async listBranchPolicies({
    context,
    params,
  }: {
    context: AzureRequestContext;
    params?: AzureListBranchesPolicyParams;
  }): Promise<AzurePaginationResponse<PolicyConfiguration> & { continuationToken?: string }> {
    const result = await this.request(
      'GET',
      `${context.organization.name}/${context.project.name}/_apis/git/policy/configurations`,
      {
        params,
        returnRaw: true,
      }
    );
    const continuationToken = result.headers['x-ms-continuationtoken'];
    return { ...(result.data as AzurePaginationResponse<PolicyConfiguration>), continuationToken };
  }

  listTree({
    context,
    sha,
    params,
  }: {
    context: AzureRequestContext;
    sha: string;
    params?: AzureListTreeParams;
  }): Promise<GitTreeRef> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/trees/${sha}`, {
      params,
    });
  }

  /** *
   * The function can return a string or a GitItem object depending on the format parameter
   * If the format parameter is 'json' then the function returns a GitItem object
   * In other cases, the function returns a string if it is file and GitItem object if it is a folder
   */
  getFileContent<T extends AzureGetFileContentParams>({
    context,
    params,
  }: {
    context: AzureRequestContext;
    params?: T;
  }): Promise<ItemType<T>> {
    const options: AxiosRequestConfig = {
      params,
    };
    if (params.path && params.format === 'text') {
      options.transformResponse = transformResponse;
    }

    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/items`, options) as unknown as Promise<
      ItemType<T>
    >;
  }

  async getBinaryContentAsBase64<T extends AzureGetFileContentParams>({
    context,
    params,
  }: {
    context: AzureRequestContext;
    params: T;
  }): Promise<string> {
    const options: AxiosRequestConfig = {
      params: {
        ...params,
        ['$format']: 'OctetStream',
      },
      responseType: 'arraybuffer',
    };
    const response = await this.request('GET', `${this.buildRepositoryRequestRoute(context)}/items`, {
      ...options,
      returnRaw: true,
    });
    return Buffer.from(response.data as ArrayBuffer).toString('base64');
  }

  /**
   * The function can create, update or delete a branch depending on the data parameters
   * If the oldObjectId equal to 0000000000000000000000000000000000000000 and newObjectId is equal to a commit id - the function creates a new branch
   * If the oldObjectId is equal to a commit id and newObjectId is equal to a commit id - the function updates a branch
   * If the oldObjectId is equal to a commit id and newObjectId is equal to 0000000000000000000000000000000000000000 - the function deletes a branch
   */
  updateCreateOrDeleteBranch({
    context,
    data,
  }: {
    context: AzureRequestContext;
    data: GitRefUpdate[];
  }): Promise<AzurePaginationResponse<GitRefUpdateResult>> {
    return this.request('POST', `${this.buildRepositoryRequestRoute(context)}/refs`, {
      data,
    });
  }

  pushChanges({ context, data }: { context: AzureRequestContext; data: GitPush }): Promise<GitPush> {
    return this.request('POST', `${this.buildRepositoryRequestRoute(context)}/pushes`, {
      data,
      params: { 'api-version': '7.1-preview.2' },
    });
  }

  createPullRequest({
    context,
    data,
  }: {
    context: AzureRequestContext;
    data: GitPullRequest;
  }): Promise<GitPullRequest> {
    return this.request('POST', `${this.buildRepositoryRequestRoute(context)}/pullrequests`, {
      data,
    });
  }

  updatePullRequest({
    context,
    pullRequestId,
    data,
  }: {
    context: AzureRequestContext;
    pullRequestId: string | number;
    data: GitPullRequest;
  }): Promise<GitPullRequest> {
    return this.request('PATCH', `${this.buildRepositoryRequestRoute(context)}/pullrequests/${pullRequestId}`, {
      data,
    });
  }

  getRepository({ context }: { context: AzureRequestContext }): Promise<GitRepository> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}`, {});
  }

  getChangesCommit({
    context,
    commitId,
    params,
  }: {
    context: AzureRequestContext;
    commitId: string;
    params?: AzurePaginateParams;
  }): Promise<GitCommitChanges> {
    return this.request('GET', `${this.buildRepositoryRequestRoute(context)}/commits/${commitId}/changes`, {
      params,
    });
  }

  async getAccounts(): Promise<{ AccountName: string; AccountId: string }[]> {
    return this.request('GET', `/_apis/accounts`, {
      baseURL: gitProviderUtils.AZURE_BASE_URLS.ACCOUNT,
      customAPIVersion: 'NONE',
    });
  }
}
