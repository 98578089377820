export const firestoreCollectionNames = {
  WORKSPACES: 'workspaces',
  REPOSITORIES: 'repositories',
  WORKSPACE_ADMINS: 'workspace_admins',
  WORKSPACE_USERS: 'workspace_users',
  WORKSPACE_API_KEYS: 'workspace_api_keys',
  ARCHIVED_WORKSPACE_USERS: 'archived_workspace_users',
  CLOUD_DOCS: 'cloud_docs',
  AUTOSYNC_CACHE: 'autosync_cache',
  UPVOTES: 'upvotes',
  USER_UPVOTES: 'user_upvotes',
  USERS: 'users',
  LIFEGUARDS: 'lifeguards',
  SWIMMERS: 'swimmers',
  SWIMMS: 'swimms',
  PLAYLISTS: 'playlists',
  DOC_REQUESTS: 'doc_requests',
  SWIMMS_STATUS: 'swimms_status',
  DOMAINS: 'domains',
  ASSIGNMENTS: 'assignments',
  CONTRIBUTIONS: 'contributions',
  CONTRIBUTORS: 'contributors',
  THANKS: 'thanks',
  WATCHERS: 'watchers',
  NOTIFICATIONS: 'notifications',
  EVENT_LOGS: 'event_logs',
  TAGS: 'tags',
  STATISTICS: 'statistics',
  ANALYTICS: 'analytics',
  BILLING_WORKSPACES: 'billing_workspaces',
  BILLING_ACCOUNTS: 'billing_accounts',
  SLACK_ACCESS_TOKENS: 'slack_access_tokens',
  SLACK_INTEGRATIONS: 'slack_integrations',
  PENDING_SLACK_USERS: 'pending_users',
  RULES: 'rules',
  CROSS_REPO_REFS: 'cross-repo-refs',
  FOLDERS: 'folders',
  LOCAL_AUTH_KEEP_ALIVE: 'local_auth_keep_alive',
  IDE_USAGES: 'ide-usages',
  PUBLIC_DATA: 'public-data',
  AI_EVENTS: 'ai_events',
  ASK_SWIMM_USAGE_CACHE: 'ask_swimm_usage_cache',
  ASK_SWIMM_MESSAGE_REQUESTS: 'ask_swimm_message_requests',
  ASK_SWIMM_SHARED_QUEREIS: 'ask-swimm-shared-queries',
  DOCUMENT_TEMPLATES: 'document_templates',
};

export type FirestoreCollectionNamesType = (typeof firestoreCollectionNames)[keyof typeof firestoreCollectionNames];
