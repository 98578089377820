import { Fragment, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Metadata, ObjectRef, ValueObject } from './output-types';
import { schema } from '@swimm/swmd';

// Matches placeholders in the form of {{ placeholder }} which we will want to replace with smart tokens
// Placeholder formats can be `{{ objectName.attributeName }}` or `{{ objectName.resourceType.resourceAttribute }}` or `{{ system-requirements }}`
export const PLACEHOLDER_TEXT_MATCHER = /{{\s?([^\W{}]+([\\.-]?[^\W{}]+)+)\s?}}/;
export const PLACEHOLDER_TEXT_MATCHER_GLOBAL = /{{\s?([^\W{}]+([\\.-]?[^\W{}]+)+)\s?}}/g;
export const LOOP_START_MATCHER = /<!-- LOOP ((?<objectType>\w+) )?((?<subject>\w+) )?((?<nestedLoop>\w+) )*-->/;
export const LOOP_END_MATCHER = /<!-- END LOOP -->/;

export const config = {
  'file-version': '1.0',
};

export function valueToSwmToken(valueObject: ValueObject, filePath: string, metadata: Metadata): ProseMirrorNode {
  return schema.node('swmToken', {
    token: valueObject.value,
    path: filePath,
    pos: {
      line: valueObject.line,
      wordStart: valueObject.wordStartIndex,
      wordEnd: valueObject.wordEndIndex,
    },
    lineData: valueObject.lineText,
    repoId: metadata.repoId,
    repoName: metadata.repoName,
  });
}

export function missingDataSwmToken(text: string, filePath: string, metadata: Metadata) {
  return schema.node('swmToken', {
    token: `Missing data for ${text}`,
    path: filePath,
    pos: {
      line: 1,
      wordStart: 0,
      wordEnd: 0,
    },
    lineData: text,
    repoId: metadata.repoId,
    repoName: metadata.repoName,
  });
}

export function composeExternalAttribute(placeholder: string, filePath: string, metadata: Metadata) {
  switch (placeholder) {
    case 'created-at':
      return schema.text(new Date().toLocaleDateString());
    case 'author':
      return schema.nodes.swmMention.create({
        uid: metadata.user.id,
        name: metadata.user.name,
        email: metadata.user.email,
      });
    default:
      if (config[placeholder]) {
        return schema.text(config[placeholder]);
      }
      return missingDataSwmToken(placeholder, filePath, metadata);
  }
}

export function createTable(tableSlice: ProseMirrorNode[][]): ProseMirrorNode {
  const rows = [];
  for (const row of tableSlice) {
    const cells = [];
    for (const cell of row) {
      cells.push(cell);
    }
    rows.push(schema.nodes.tableRow.create(null, Fragment.fromArray(cells)));
  }
  return schema.nodes.table.create(null, Fragment.fromArray(rows));
}

export function openLoop(
  placeholderNode: ProseMirrorNode,
  loopStack: string[][],
  loopSlice: {
    start: number;
    end: number;
  },
  pos: number
) {
  const loopMatch = placeholderNode.text.match(LOOP_START_MATCHER);
  loopStack.push([loopMatch.groups.objectType, loopMatch.groups.subject, loopMatch.groups.nestedLoop]);

  // This is the outermost loop. Start a new loop slice
  if (loopStack.length === 1) {
    loopSlice = {} as { start: number; end: number };
  } else {
    // This is a nested loop, it should be added to the outer loop slice
    if (loopSlice.start == null) {
      loopSlice.start = pos;
    }
    loopSlice.end = pos;
  }
  return loopSlice;
}

export function arrayAttributeToContentNode(
  attribute: Array<ValueObject | { [key: string]: ValueObject } | ObjectRef>,
  referenceFilePath: string,
  metadata: Metadata
): ProseMirrorNode[] {
  if (attribute.length && (attribute[0] as ValueObject).lineText) {
    // assume this is an array of ValueObjects
    return attribute.reduce((acc, item, index) => {
      if (index > 0) {
        acc.push(schema.text(', '));
      }
      acc.push(valueToSwmToken(item as ValueObject, referenceFilePath, metadata));
      return acc;
    }, []);
  }
  if (attribute.length && (attribute[0] as ObjectRef).name) {
    // assume this is an array of ObjectRef
    return (attribute as Array<ObjectRef>).reduce((acc, item, index) => {
      if (index > 0) {
        acc.push(schema.text(', '));
      }
      acc.push(valueToSwmToken(item.name as ValueObject, item.filePath ?? referenceFilePath, metadata));
      return acc;
    }, []);
  }
  return attribute.reduce((acc, item, index) => {
    if (index > 0) {
      acc.push(schema.text(';'));
      acc.push(schema.node('hardBreak'));
    }
    const [key, value] = Object.entries(item)[0];
    acc.push(schema.text(`${key}:`));
    acc.push(valueToSwmToken(value as ValueObject, referenceFilePath, metadata));
    return acc;
  }, []);
}
