import { Fragment, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Transform } from '@tiptap/pm/transform';
import { TableMap } from '@tiptap/pm/tables';
import { getLoggerNew } from '@swimm/shared';
import { parseSwmd, schema } from '@swimm/swmd';
import { Metadata, ValueObject } from '../output-types';
import { Action, Binding, ObjectField, ParameterField, Process, ProcessConnector, ProcessObject } from './input';
import {
  LOOP_END_MATCHER,
  LOOP_START_MATCHER,
  PLACEHOLDER_TEXT_MATCHER,
  PLACEHOLDER_TEXT_MATCHER_GLOBAL,
  arrayAttributeToContentNode,
  composeExternalAttribute,
  createTable,
  missingDataSwmToken,
  openLoop,
  valueToSwmToken,
} from '../output-composer';

const logger = getLoggerNew('ppg/cai/output.ts');

function closeLoop(
  loopStack: string[][],
  loopSlice: { start: number; end: number },
  doc: ProseMirrorNode,
  pos: number,
  allProcesses: Process[],
  currentSourceObject?: Process | ProcessObject | ProcessConnector | ParameterField | Action | ObjectField | Binding
) {
  // We could have multiple nested loops
  // We only want to start iterating when we reach the end of the outermost loop
  if (loopStack.length === 1) {
    const [loopObjectType, loopSubject, nestedLoop] = loopStack.pop();

    // Find the relevant array of objects to loop over for this doc segment
    let loopObjects: (Process | ProcessObject | ProcessConnector | ParameterField | Action | ObjectField | Binding)[];

    if (loopObjectType === 'PROCESS' && !loopSubject && !currentSourceObject) {
      // Object loops loop over all processes
      loopObjects = [...allProcesses];
    } else if (loopSubject && currentSourceObject && !nestedLoop) {
      switch (loopSubject) {
        case 'OBJECTS':
          loopObjects = (currentSourceObject as Process).processObjects;
          break;
        case 'CONNECTORS':
          loopObjects = (currentSourceObject as Process).processConnectors;
          break;
        case 'ACTIONS':
          loopObjects = ((currentSourceObject as ProcessConnector)?.serviceConnectors || [])
            .map((serviceConnector) => serviceConnector.actions)
            .flat();
          break;
        default:
          if (currentSourceObject[loopSubject.toLowerCase()]) {
            loopObjects = currentSourceObject[loopSubject.toLowerCase()] as (ParameterField | ObjectField)[];
          }
          break;
      }
    } else if (nestedLoop) {
      loopObjects = currentSourceObject[nestedLoop.toLowerCase()] as (ParameterField | ObjectField | Binding)[];
    }

    if (loopSlice && loopObjects) {
      // Create a slice of nodes to loop over and fill
      const subDoc = doc.cut(loopSlice.start, loopSlice.end);
      return { subDoc, loopObjects };
    }
    return null;
  } else {
    loopStack.pop();
    loopSlice.end = pos;
    return null;
  }
}

/**
 * Compose the actual content nodes from a placeholder node
 * This function replaces inline text placeholders with their matching source file data
 * It also preserves the hardcoded text around those placeholders
 * @param placeholderNode
 * @param sourceObject
 * @param metadata
 * @param referenceFilePath
 */
function composeContentNodes(
  placeholderNode: ProseMirrorNode,
  sourceObject: Process | ProcessObject | ProcessConnector | ParameterField | Action | ObjectField | Binding,
  metadata: Metadata,
  referenceFilePath: string
): ProseMirrorNode | ProseMirrorNode[] {
  const contentNodes = [];
  let lastInsertedIndex = 0;

  for (const match of placeholderNode.text.matchAll(PLACEHOLDER_TEXT_MATCHER_GLOBAL)) {
    if (match.index > lastInsertedIndex) {
      // Keep hardcoded text between placeholders as-is in the final result
      contentNodes.push(schema.text(placeholderNode.text.slice(lastInsertedIndex, match.index)));
    }
    // move lastInsertedIndex to the end of the current placeholder match (we take match[0] which includes the curly braces - so the entire template syntax is ignored.
    lastInsertedIndex = match.index + match[0].length;

    // We use match[1] for understanding the actual placeholder as it is the content inside the curly braces
    const placeholderAttributes = match[1].split('.');

    // Placeholder is templating an external / configuration attribute. ex. {{ created-at }} | {{ author }}
    if (placeholderAttributes.length === 1) {
      contentNodes.push(composeExternalAttribute(placeholderAttributes[0], referenceFilePath, metadata));
      continue;
    }

    // placeholder references a value from a file. Example: {{ DATAFLOW.description }} || {{ MTT.AgentGroup.name }} || {{ source.name }}
    const [_objectType, objectAttribute, referenceAttribute] = placeholderAttributes;

    if (sourceObject?.filePath) {
      referenceFilePath = sourceObject.filePath;
    }

    const attribute = sourceObject?.[objectAttribute];
    if (!attribute) {
      contentNodes.push(missingDataSwmToken(objectAttribute, referenceFilePath, metadata));
      continue;
    }

    if (attribute.filePath) {
      referenceFilePath = attribute.filePath;
    }

    if (referenceAttribute) {
      // When referenceAttribute is provided the template is referencing a related object by its key (its type). ex. {{ MTT.AgentGroup.name }}
      if (!attribute[referenceAttribute]) {
        contentNodes.push(missingDataSwmToken(referenceAttribute, referenceFilePath, metadata));
        continue;
      }

      if (Array.isArray(attribute[referenceAttribute])) {
        contentNodes.push(...arrayAttributeToContentNode(attribute[referenceAttribute], referenceFilePath, metadata));
        continue;
      }
      contentNodes.push(
        valueToSwmToken(
          attribute[referenceAttribute] as ValueObject,
          attribute[referenceAttribute].filePath || referenceFilePath,
          metadata
        )
      );
      continue;
    }

    if (Array.isArray(attribute)) {
      contentNodes.push(...arrayAttributeToContentNode(attribute, referenceFilePath, metadata));
      continue;
    }

    contentNodes.push(valueToSwmToken(attribute as unknown as ValueObject, referenceFilePath, metadata));
  }

  if (contentNodes.length) {
    if (lastInsertedIndex < placeholderNode.text.length) {
      contentNodes.push(schema.text(placeholderNode.text.slice(lastInsertedIndex)));
    }
    return contentNodes;
  }

  return placeholderNode;
}

function composeProcessMermaidContent(allProcesses: Process[], metadata: Metadata): ProseMirrorNode[] {
  const mermaidContent = [];
  mermaidContent.push(schema.text('flowchart LR;\n'));
  for (const [processIndex, process] of allProcesses.entries()) {
    if (allProcesses.length > 1) {
      mermaidContent.push(schema.text(`\nsubgraph process-`));
      mermaidContent.push(valueToSwmToken(process.name, process.filePath, metadata));
      mermaidContent.push(schema.text('\n'));
    }
    for (const [processConnectorIndex, processConnector] of process.processConnectors.entries()) {
      mermaidContent.push(valueToSwmToken(process.name, process.filePath, metadata));
      mermaidContent.push(schema.text(' -->|'));
      mermaidContent.push(valueToSwmToken(processConnector.type, processConnector.filePath, metadata));
      mermaidContent.push(schema.text('| '));
      mermaidContent.push(schema.text(`connector-${processIndex}${processConnectorIndex}[`));
      mermaidContent.push(valueToSwmToken(processConnector.name, processConnector.filePath, metadata));
      mermaidContent.push(schema.text(']\n'));
      for (const [serviceConnectorIndex, serviceConnector] of (processConnector.serviceConnectors ?? []).entries()) {
        mermaidContent.push(schema.text(`connector-${processIndex}${processConnectorIndex}[`));
        mermaidContent.push(valueToSwmToken(processConnector.name, processConnector.filePath, metadata));
        mermaidContent.push(schema.text(']'));
        mermaidContent.push(schema.text(' -->|'));
        mermaidContent.push(valueToSwmToken(serviceConnector.type, serviceConnector.filePath, metadata));
        mermaidContent.push(schema.text('| '));
        mermaidContent.push(schema.text(`service-${processIndex}${processConnectorIndex}${serviceConnectorIndex}[`));
        mermaidContent.push(valueToSwmToken(serviceConnector.name, serviceConnector.filePath, metadata));
        mermaidContent.push(schema.text(']\n'));
        for (const [actionIndex, action] of (serviceConnector.actions ?? []).entries()) {
          mermaidContent.push(schema.text(`service-${processIndex}${processConnectorIndex}${serviceConnectorIndex}[`));
          mermaidContent.push(valueToSwmToken(serviceConnector.name, serviceConnector.filePath, metadata));
          mermaidContent.push(schema.text(']'));
          mermaidContent.push(schema.text(' -->|Action| '));
          mermaidContent.push(
            schema.text(`action-${processIndex}${processConnectorIndex}${serviceConnectorIndex}${actionIndex}[`)
          );
          mermaidContent.push(valueToSwmToken(action.name, action.filePath, metadata));
          mermaidContent.push(schema.text(']\n'));
        }
      }
    }
    for (const [index, processObject] of process.processObjects.entries()) {
      if (index > 0) {
        mermaidContent.push(schema.text(`\n`));
      }
      mermaidContent.push(valueToSwmToken(process.name, process.filePath, metadata));
      mermaidContent.push(schema.text(' -->|'));
      mermaidContent.push(valueToSwmToken(processObject.type, processObject.filePath, metadata));
      mermaidContent.push(schema.text('| '));
      mermaidContent.push(valueToSwmToken(processObject.name, processObject.filePath, metadata));
    }

    if (allProcesses.length > 1) {
      mermaidContent.push(schema.text(`end`));
    }
  }
  return mermaidContent;
}

function composeFlowMermaidContent(process: Process, metadata: Metadata): ProseMirrorNode[] {
  const mermaidContent = [];

  if (process.flows && process.flows.length) {
    mermaidContent.push(schema.text('flowchart LR;\n'));
    for (const [index, flow] of process.flows.entries()) {
      if (process.flows.length > 1) {
        mermaidContent.push(schema.text(`\nsubgraph flow-${index + 1}\n`));
      }

      mermaidContent.push(schema.text('start((start)):::start --> '));
      mermaidContent.push(valueToSwmToken(flow.source, flow.filePath, metadata));
      for (const serviceFlow of flow.serviceFlows) {
        mermaidContent.push(schema.text(`\n`));
        mermaidContent.push(valueToSwmToken(flow.source, flow.filePath, metadata));
        mermaidContent.push(schema.text(' --> '));
        mermaidContent.push(valueToSwmToken(serviceFlow, flow.filePath, metadata));
        mermaidContent.push(schema.text(' --> e((end)):::e'));
      }

      if (process.flows.length > 1) {
        mermaidContent.push(schema.text(`\nend`));
      }
    }

    mermaidContent.push(schema.text('\nclassDef start stroke:#3bce64\n'));
    mermaidContent.push(schema.text('classDef start fill:#3bce64\n'));
    mermaidContent.push(schema.text('classDef e stroke:#ff6464\n'));
    mermaidContent.push(schema.text('classDef e fill:#ff6464'));
  }

  return mermaidContent;
}

function fillDocument(
  templateDoc: ProseMirrorNode,
  tr: Transform,
  metadata: Metadata,
  allProcesses: Process[],
  currentSourceData?: Process | ProcessObject | ProcessConnector | ParameterField | Action | ObjectField | Binding,
  loopingTableRows?: (ParameterField | Action | ObjectField | Binding)[]
) {
  const loopStack: string[][] = [];
  let loopSlice: { start: number; end: number };
  let lastTableNode: ProseMirrorNode;
  let tableSlice: ProseMirrorNode[][];
  let currentTableRow: ProseMirrorNode[];
  let currentTableRowPlaceholders: ProseMirrorNode[];
  const currentFilePath = currentSourceData?.filePath || allProcesses?.[0]?.filePath;

  templateDoc.descendants((placeholderNode, pos, parent) => {
    if (placeholderNode.type.name === 'text' && placeholderNode.text.match(LOOP_START_MATCHER)) {
      loopSlice = openLoop(placeholderNode, loopStack, loopSlice, pos);
      return true;
    }

    if (placeholderNode.type.name === 'text' && placeholderNode.text.match(LOOP_END_MATCHER)) {
      const loop = closeLoop(loopStack, loopSlice, templateDoc, pos, allProcesses, currentSourceData);
      if (loop) {
        loopSlice = null;
        const { subDoc, loopObjects /* , referenceFilePath*/ } = loop;

        let tableRowObjects;
        if (subDoc.childCount === 1 && subDoc.firstChild.type.name === 'swmTablePlaceholder') {
          // Create table row for each loop object
          tableRowObjects = loopObjects.slice(1);
          loopObjects.splice(1, Infinity);
        }
        for (const loopObject of loopObjects) {
          fillDocument(subDoc, tr, metadata, allProcesses, loopObject, tableRowObjects);
        }
      }

      return true;
    }

    // Group all nodes under a loop placeholder to fill them iteratively
    if (loopStack.length) {
      if (loopSlice.start == null) {
        loopSlice.start = pos;
      }
      loopSlice.end = pos;
      return true;
    }

    if (placeholderNode.type.name === 'text') {
      // markdown-it linkify parses .name as a link and splits the placeholder accordingly
      // Reference to the check in the code below:
      // https://github.com/markdown-it/linkify-it/blob/5e79093543092562b4348ea58395110357f3d296/index.mjs#L444
      // We are trying to handle it by ignoring the curley braces ejected from the text node and passing them as part of the content text node
      if (parent.textContent.match(PLACEHOLDER_TEXT_MATCHER)) {
        if (placeholderNode.text.match(/^{{\s*$|^\s*}}$/)) {
          return false;
        }
        placeholderNode = schema.text(parent.textContent);
      }

      const content = composeContentNodes(
        placeholderNode,
        currentSourceData,
        metadata,
        currentSourceData?.filePath || currentFilePath
      );

      // We only create the content when we reach the leaf node (text).
      // When we do we want make sure we create it within the proper context.
      // ex. text leaf inside a heading node - we want to create as a heading
      const contentNode = schema.node(parent.type.name, parent.attrs ?? {}, content);
      if (!['tableHeader', 'tableCell'].includes(parent.type.name)) {
        tr.insert(tr.doc.content.size, contentNode);
      } else {
        if (!currentTableRow) {
          currentTableRow = [];
          currentTableRowPlaceholders = [];
        }
        currentTableRow.push(contentNode);
        currentTableRowPlaceholders.push(placeholderNode);
      }

      if (tableSlice && lastTableNode && lastTableNode.eq(parent)) {
        // This is the last text node of the last table cell - create the table
        if (currentTableRow) {
          tableSlice.push(currentTableRow);
          currentTableRow = null;
          if (loopingTableRows) {
            for (const tableRow of loopingTableRows) {
              const loopRow = [];
              for (const placeholderNode of currentTableRowPlaceholders) {
                if (placeholderNode.type.name === 'tableCell') {
                  loopRow.push(placeholderNode);
                  continue;
                }
                const contentNode = composeContentNodes(
                  placeholderNode,
                  tableRow,
                  metadata,
                  tableRow?.filePath || currentFilePath
                );
                const content = schema.node('tableCell', {}, contentNode);
                loopRow.push(content);
              }
              tableSlice.push(loopRow);
            }
          }
          currentTableRowPlaceholders = null;
        }

        tr.insert(tr.doc.content.size, createTable(tableSlice));
        tableSlice = null;
        lastTableNode = null;
      }
      return false;
    }

    if (placeholderNode.type.name === 'table') {
      tableSlice = [];

      // Store the last table cell so we can insert the table when we reach the last cell
      const tableMap = TableMap.get(placeholderNode);
      const lastTableCellPos = tableMap.positionAt(tableMap.height - 1, tableMap.width - 1, placeholderNode);
      lastTableNode = placeholderNode.nodeAt(lastTableCellPos);
      return true;
    }

    if (placeholderNode.type.name === 'tableRow') {
      if (currentTableRow && tableSlice) {
        tableSlice.push(currentTableRow);
      }
      currentTableRow = [];
      currentTableRowPlaceholders = [];
      return true;
    }

    if (placeholderNode.type.name === 'tableCell') {
      // Protect against empty table cells
      if (tableSlice && !placeholderNode.content.size) {
        if (!currentTableRow) {
          currentTableRow = [];
          currentTableRowPlaceholders = [];
        }
        currentTableRow.push(placeholderNode);
        currentTableRowPlaceholders.push(placeholderNode);
      }
    }

    if (placeholderNode.type.name === 'swmMermaidPlaceholder') {
      const [diagramObject, diagramType] = placeholderNode.attrs.placeholder.split(' ');

      let mermaidContent;

      // Assuming here that the diagram placeholder indicates the file type the diagram is based on and the type of diagram
      if (diagramObject.toUpperCase() === 'PROCESS' && diagramType === 'flowchart') {
        mermaidContent = composeProcessMermaidContent(allProcesses, metadata);
      } else if (
        diagramObject === 'flow' &&
        currentSourceData &&
        (currentSourceData as Process).type?.value === 'PROCESS'
      ) {
        mermaidContent = composeFlowMermaidContent(currentSourceData as Process, metadata);
      } else {
        logger.warn(`Unknown diagram referenced: ${diagramObject}. Skipping...`);
      }

      if (!mermaidContent || !mermaidContent.length) {
        mermaidContent = [
          missingDataSwmToken(
            placeholderNode.attrs.placeholder,
            currentSourceData?.filePath || allProcesses[0].filePath,
            metadata
          ),
        ];
      }
      if (mermaidContent?.length) {
        tr.insert(tr.doc.content.size, schema.node('mermaid', {}, Fragment.fromArray(mermaidContent)));
      }
      return false;
    }

    return true;
  });
}

export async function toSwmd(metadata: Metadata, data: Process[], template: string) {
  // Use the template as the starting point for the document
  const document = parseSwmd(template);
  document.title = metadata.title;
  document.repoId = metadata.repoId;
  document.repoName = metadata.repoName;

  const templateDoc = ProseMirrorNode.fromJSON(schema, document.content);

  // Create a new document to fill while traversing the template
  const tr = new Transform(schema.topNodeType.create());
  fillDocument(templateDoc, tr, metadata, data);
  document.content = tr.doc.toJSON();

  return document;
}
