<script setup lang="ts">
import trim from 'lodash-es/trim.js';
import { type PropType, computed } from 'vue';
import { NodeViewWrapper } from '@tiptap/vue-3';
import { ApplicabilityStatus, type Repo, reversedEllipsisFunction } from '@swimm/shared';
import { Menu as VMenu } from 'floating-vue';
import PathStatusTooltip from './PathStatusTooltip.vue';
import { Action, Icon } from '@swimm/ui';

const props = defineProps({
  selected: { type: Boolean, required: true },
  editable: { type: Boolean, required: true },
  isMarkingAsVerified: { type: Boolean, required: true },
  type: { type: String as PropType<'file' | 'folder'>, required: true },
  branch: { type: String },
  repoId: { type: String, required: true },
  crossRepo: { type: Boolean, required: true },
  authorized: { type: Boolean, required: true },
  repoIconName: { type: String, required: true },
  repoName: { type: String, required: true },
  repoFullName: { type: String, required: false, default: '' },
  repo: { type: Object as PropType<Repo> },
  sharedDocId: { type: String },
  path: { type: String, required: true },
  applicability: { type: String as PropType<ApplicabilityStatus>, required: true },
  autosyncedPath: { type: String },
  hideApplicability: { type: Boolean, default: false },
});

const emit = defineEmits<{
  (event: 'open-file'): void;
  (event: 'mark-as-verified'): void;
  (event: 'reselect-path', applicabilityStatus: ApplicabilityStatus): void;
  (event: 'delete-path', applicabilityStatus: ApplicabilityStatus): void;
}>();

interface PathStatus {
  icon?: string;
  title?: string;
  pathClass?: string;
  text?: string;
  actions?: {
    secondary?: boolean;
    text: string;
    class: string;
    callback: () => void;
    size: 'big' | 'small' | undefined;
    isDeleteAction?: boolean;
  }[];
}

// TODO The data is not exhaustive
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const pathStatus: Record<ApplicabilityStatus | 'noAccess', PathStatus> = {
  [ApplicabilityStatus.Verified]: {
    icon: 'updated',
    title: 'Path is up to date.',
    pathClass: 'verified',
    text: 'This path matches the current state of your code.',
  },
  [ApplicabilityStatus.Autosyncable]: {
    icon: 'sync',
    title: 'Path is Auto-synced.',
    text: 'The path was changed and Swimm updated it.',
    pathClass: 'autosynced',
    actions: [
      {
        text: 'Accept',
        class: 'primary-button',
        callback: () => emit('mark-as-verified'),
        size: 'small',
      },
      {
        secondary: true,
        text: 'Reselect',
        class: 'secondary-button',
        callback: () => emit('reselect-path', ApplicabilityStatus.Autosyncable),
        size: 'small',
      },
    ],
  },
  [ApplicabilityStatus.Outdated]: {
    icon: 'warning',
    title: 'Path is Outdated',
    text: 'The path no longer exists.',
    pathClass: 'outdated',
    actions: [
      {
        text: 'Reselect',
        class: 'primary-button',
        callback: () => emit('reselect-path', ApplicabilityStatus.Outdated),
        size: 'small',
      },
      {
        secondary: true,
        text: 'Delete',
        class: 'secondary-button',
        callback: () => emit('delete-path', ApplicabilityStatus.Outdated),
        size: 'small',
        isDeleteAction: true,
      },
    ],
  },
  // TODO The data is not exhaustive
  // [ApplicabilityStatus.Invalid]: {},
  // [ApplicabilityStatus.Unavailable]: {},
  // [ApplicabilityStatus.DisconnectedFromRepo]: {},
  // Unknown is set temporarily on symbols copied from another doc - autosync will run when it detects such
  // tokens and correct the applicability - so we show a loader here
  [ApplicabilityStatus.Unknown]: {
    icon: 'sync',
    text: 'Checking path status...',
  },
  noAccess: {
    icon: 'not-allowed',
    title: 'Path Inaccessible',
    pathClass: 'no-access',
    text: "You don't have access to this repo.",
  },
};

const isOutdated = computed(() => props.applicability === ApplicabilityStatus.Outdated);
const isUnknown = computed(() => props.applicability === ApplicabilityStatus.Unknown);
const isAutosyncable = computed(() => props.applicability === ApplicabilityStatus.Autosyncable);

// TODO Can we make this cleaner?
const noAccessToRepo = computed(() => !props.authorized || (props.repo && !props.branch));
const isCrossRepoNotInWorkspace = computed(() => props.crossRepo && !props.repo);

const pathIcon = computed(() => (props.type === 'file' ? 'file' : 'folder'));

const filePath = computed(() => props.autosyncedPath ?? props.path);

const displayedPath = computed(() => trim(decodeURI(reversedEllipsisFunction(filePath.value, 50)), '/'));

const originalPath = computed(() => {
  if (isAutosyncable.value && props.path && props.path !== props.autosyncedPath) {
    return trim(props.path, '/');
  }

  return '';
});

const clickable = computed(() => {
  if (isOutdated.value) {
    return false;
  }

  if (isUnknown.value) {
    return false;
  }

  if (props.type !== 'file') {
    return false;
  }

  if (noAccessToRepo.value) {
    return false;
  }

  return true;
});

const status = computed<PathStatus>(() => {
  if (noAccessToRepo.value) {
    return pathStatus.noAccess;
  }

  // TODO pathStatus is not exhaustive
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (pathStatus[props.applicability]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return pathStatus[props.applicability];
  }

  if (props.crossRepo && props.sharedDocId) {
    return { title: 'Path' };
  }

  return { icon: 'verified', title: 'Auto-syncing path' };
});

const statusActions = computed(() => {
  let result = status.value.actions;
  // repo not in workspace - disallow edit ops
  if (!props.repo) {
    result = result?.filter((action) => action.isDeleteAction);
  }

  return result;
});

function openLink() {
  emit('open-file');
}
</script>

<template>
  <!-- TODO For the new editor we might want to have have the content in HTML that is similar to the one returned by renderHTML -->
  <NodeViewWrapper as="span" :class="['sym-path', { selected: selected }]" :data-selected="selected">
    <VMenu :auto-hide="false" theme="menu-delayed">
      <div class="container" :class="applicability">
        <div
          class="path-line"
          :class="{
            accepting: isMarkingAsVerified,
            clickable,
            [applicability ?? '']: true,
          }"
          @click="clickable ? openLink() : null"
          data-testid="path-line"
        >
          <template v-if="crossRepo">
            <Icon :name="repoIconName" />
            <div data-testid="cross-repo-path">{{ repoName }}</div>
            <template v-if="branch || (!noAccessToRepo && !isCrossRepoNotInWorkspace)">
              <div v-if="branch">({{ branch }})</div>
              <span v-else>(<Icon class="branch-loader" name="sync" />)</span>
            </template>
            <Icon name="arrow-right" no-padding></Icon>
          </template>
          <Icon class="path-icon" :name="pathIcon" no-padding />
          <div class="link file-path text-ellipsis text-reverse-ellipsis" :data-testid="`path-${applicability}`">
            <div class="text-reverse-ellipsis-inner">
              {{ displayedPath }}
            </div>
          </div>
          <Icon
            v-if="!hideApplicability && !noAccessToRepo && !isCrossRepoNotInWorkspace"
            :name="status.icon ?? ''"
            class="applicability"
            data-testid="applicability-icon"
            :data-applicability="applicability"
            :class="applicability"
            no-padding
          />
          <Icon
            v-else-if="noAccessToRepo || isCrossRepoNotInWorkspace"
            name="not-allowed"
            data-testid="applicability-icon"
            class="applicability"
          />
        </div>
      </div>
      <template #popper>
        <div data-testid="path-tooltip" class="applicability-popover-container">
          <div>
            <PathStatusTooltip
              :state-title="status.title"
              :path="displayedPath"
              :original-path="originalPath"
              :path-class="status.pathClass"
              :note="status.text"
              :path-icon="pathIcon"
              :repo-icon="crossRepo ? repoIconName : ''"
              :repo-name="crossRepo ? repoName : ''"
              :repo-full-name="crossRepo ? repoFullName : ''"
            />
          </div>
          <div v-if="editable && statusActions" class="popover-actions" data-testid="popover-actions">
            <Action
              data-testid="popover-action"
              v-for="popverAction in statusActions"
              :key="popverAction.text"
              v-close-popper
              :class="popverAction.class"
              :size="popverAction.size"
              :secondary="popverAction.secondary"
              @click="popverAction.callback"
              >{{ popverAction.text }}
            </Action>
          </div>
        </div>
      </template>
    </VMenu>
  </NodeViewWrapper>
</template>

<style scoped lang="postcss">
.sym-path {
  display: inline; /* This allows the user to place the cursor before this node if it is first in the line. */
}

.container {
  display: inline-block;
  max-width: 100%;
}

.path-line {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-surface);
  border-radius: 4px;
  gap: var(--space-xs);
  padding: 0 var(--space-xs);

  &.verified {
    &.accepting {
      animation-name: autosyncable-smart-element-accepted;
      animation-duration: 1s;
      animation-delay: 250ms;
    }
  }

  &.clickable {
    color: var(--text-color-link);

    &:hover .file-path {
      text-decoration: underline;
    }
  }
}

.selected.sym-path .path-line {
  background-color: var(--color-selection);
  color: var(--text-color-selection);
}

.container ::selection {
  background-color: transparent;
}

.applicability.verified {
  color: var(--text-color-success-strong);
}

.applicability.autosyncable {
  color: var(--text-color-magic-strong);
}

.applicability.outdated {
  color: var(--text-color-error-strong);
}

.path-line.autosyncable {
  background-color: var(--color-status-magic);
  color: var(--text-color-magic);
}

.path-line.outdated {
  background-color: var(--color-status-error);
  color: var(--text-color-error);
}

.applicability.unknown {
  animation: spin;
  animation-duration: 1s;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
  transform-origin: center;
}

.branch-loader {
  animation: spin;
  animation-duration: 1s;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
  transform-origin: center;
}

.snippet-container {
  overflow: hidden;
  width: 75vw;
  max-width: inherit;
  height: 75vh;
  max-height: inherit;
  background: var(--color-surface);
  flex-direction: column;
  box-sizing: border-box;
}

.snippet-container :deep(.suggest-widget) {
  display: none;
}

.v-popper {
  display: inline-flex;
  max-width: 100%;
}

.v-popper :deep(.trigger) {
  max-width: 100%;
}

.applicability-popover-container {
  --side-padding: 15px;
  min-width: 300px;
}

.applicability-popover-container .popover-actions {
  padding: var(--space-base) var(--side-padding);
}

.applicability-popover-container .popover-actions .secondary-button {
  margin-left: var(--space-xs);
  border-color: transparent;
}

.path {
  font-size: var(--body-S);
  font-family: var(--fontfamily-secondary);
  font-weight: 600;
  color: var(--text-color-primary);
  line-height: 150%;
}

.path.original-path {
  background-color: var(--color-status-error);
}

.path.updated-path {
  background-color: var(--color-status-magic);
}

.path-status {
  padding: 2px var(--side-padding);
  font-size: var(--body-XS);
  color: var(--text-color-primary);
  background-color: var(--color-bg);
}

.has-focus .container.verified {
  outline: 1px solid var(--color-border-brand);
  border-radius: 3px;
}

.has-focus .container.autosyncable {
  border-radius: 3px;
  box-shadow: 0px 0px 0px 2px var(--high-violet);
}

.has-focus .container.outdated {
  border-radius: 3px;
  box-shadow: 0px 0px 0px 2px var(--oh-no-red);
}
</style>
