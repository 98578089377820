import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { camelCase } from 'lodash-es';
import { type User, UserOnboardingFields, getLoggerNew } from '@swimm/shared';
import { setWebGlobalContext } from '@/common/utils/logger-utils';
import {
  UserOnboardingCustomSteps,
  getProviderDataInfo,
  getProviderUidDataInfo,
  setUserFieldInDB,
} from '@/common/utils/user-utils';
import { getSourcePropertiesFromLocalStorage } from '@/common/utils/helpers';
import { STORES } from '@/modules/core/stores/store-constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { updateLoginInSalesforce, updateSignupInSalesforce } from '@/common/utils/salesforce/salesforce';
import * as Sentry from '@sentry/vue';
import { fetchSSOSetup, isSSOActive } from '@/modules/core/composables/sso';
import { checkBackEndClient } from '@/common/swimm-backend-client';

const logger = getLoggerNew(__modulename);

export interface AuthUser extends User {
  provider?: firebase.FirebaseSignInProvider | null;
  providerUid?: string | null;
  metadata?: firebase.auth.UserMetadata;
}

export interface SetUserParams extends AuthUser {
  isRegistrationEvent?: boolean;
  isInvited?: boolean;
  providerData: firebase.UserInfo[];
  _delegate?;
}

const EMPTY_USER: AuthUser = {
  uid: '',
  displayName: '',
  nickname: '',
  email: '',
  provider: null,
  providerUid: null,
  metadata: null,
};

const INITIAL_ONBOARDING_CUSTOM_STEPS = {
  [UserOnboardingCustomSteps.FIRST_COMMIT]: {
    done: false,
    dismissed: false,
  },
};

export const useAuthStore = defineStore(STORES.AUTH, () => {
  const user = ref(EMPTY_USER);

  function RESET_STATE() {
    user.value = EMPTY_USER;
  }

  function SET_USER(args: AuthUser) {
    user.value = { ...user.value, ...args };
  }

  function resetState() {
    RESET_STATE();
    setWebGlobalContext({ uid: null });
  }

  function setUser(args: SetUserParams) {
    if (!args) {
      return;
    }
    const { uid, email, displayName, isRegistrationEvent, isInvited, providerData, _delegate, metadata, photoURL } =
      args;
    const provider = getProviderDataInfo({ providerData, delegate: _delegate });
    const providerUid = getProviderUidDataInfo({ providerData, delegate: _delegate });
    if (uid && email) {
      Sentry.setUser({ id: uid, email });
      SET_USER({ uid, email, nickname: displayName, displayName, provider, providerUid, metadata, photoURL });
      setWebGlobalContext({ uid });
      void setInitialUserFields({ uid, isRegistrationEvent });
      if (!isRegistrationEvent) {
        // Triggered only when setUser() is triggered from within observeAuthChanges(),
        // because on registration event, observeAuthChanges() is triggered and setUser() is called again
        void setLoginDatesInSalesforce({ email });
        if (
          !['/login', '/register', '/logout', '/ssologout', '/sso', '/forget', '/auth', '/setOIDCToken'].includes(
            window.location.pathname
          )
        ) {
          logger.info(`Navigating to ${window.location.pathname}`);
          void checkSSOAuth(email);
        }
      } else if (!isInvited) {
        void setSignupInSalesforce({ email, name: displayName });
      }
      // check the backend client after the user is set
      void checkBackEndClient();
    }
  }

  function observeAuthChanges() {
    firebase['hasSubscribedAuth'] = true;
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        setUser(user);
        resolve(user);
      }, reject);
    });
  }

  async function setLoginDatesInSalesforce(args: { email: string }) {
    const { email } = args;
    const domain = email.split('@')[1];
    if (domain === 'swimm.io') {
      return;
    }

    await updateLoginInSalesforce({ email });
  }

  async function setSignupInSalesforce(args: { email: string; name: string; companyName?: string }) {
    const { email, name, companyName } = args;
    const domain = email.split('@')[1];
    if (domain === 'swimm.io') {
      return;
    }

    try {
      const sourceProperties = getSourcePropertiesFromLocalStorage({
        keyFormatCallback: camelCase,
      });

      await updateSignupInSalesforce({
        email,
        name,
        companyName,
        ...sourceProperties,
      });
    } catch (err) {
      logger.error({ err }, err);
    }
  }

  async function setInitialUserFields({ uid, isRegistrationEvent }) {
    if (isRegistrationEvent) {
      await setUserFieldInDB(uid, UserOnboardingFields.ONBOARDING_CUSTOM_STEPS, INITIAL_ONBOARDING_CUSTOM_STEPS);
      await setUserFieldInDB(uid, UserOnboardingFields.SHOWN_HELP_TOOLTIPS, {});
    }
  }

  async function checkSSOAuth(email) {
    try {
      const ssoResponse = await fetchSSOSetup(email);
      if (!ssoResponse) {
        return;
      }

      logger.info(`User ${email.slice(email.indexOf('@'))}, is an SSO user verifying token`);

      const isSessionActive = await isSSOActive(ssoResponse.type, email, ssoResponse, user);

      if (!isSessionActive) {
        const redirect = window.location.pathname + window.location.search;
        window.location.href = '/ssologout?redirect=' + encodeURIComponent(redirect);
      }
    } catch (err) {
      logger.error({ err }, `Failed checking auth for ${email.slice(email.indexOf('@'))}, ${err.message}`);
    }
  }

  return {
    user,
    resetState,
    setUser,
    observeAuthChanges,
  };
});
