import { type SwimmDocument, getLoggerNew } from '@swimm/shared';
import type { User } from '@swimm/reefui';
import cai from './cai/main';
import cdi from './cdi/main';
import InvalidInputError from './invalidInputError';

const logger = getLoggerNew('ppg/main.ts');

export default async function main(
  title: string,
  template: string,
  templateType: 'cdi' | 'cai',
  filePaths: string[],
  config: { repoId: string; repoName: string; branch: string; user: User }
): Promise<SwimmDocument> {
  if (templateType === 'cdi') {
    return cdi(title, template, filePaths, config);
  }
  if (templateType === 'cai') {
    return cai(title, template, filePaths, config);
  }
  logger.error(`Invalid template type ${templateType}`);
  throw new InvalidInputError(`Invalid template type ${templateType}`);
}
