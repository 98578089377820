import { Transform } from '@tiptap/pm/transform';
import { Fragment, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { TableMap } from '@tiptap/pm/tables';
import { SwimmDocument, getLoggerNew } from '@swimm/shared';
import { parseSwmd, schema } from '@swimm/swmd';
import { Metadata, ObjectRef, ProcessFile, ValueObject } from '../output-types';
import { CDIFileTypes } from './types';
import {
  LOOP_END_MATCHER,
  LOOP_START_MATCHER,
  PLACEHOLDER_TEXT_MATCHER,
  PLACEHOLDER_TEXT_MATCHER_GLOBAL,
  arrayAttributeToContentNode,
  composeExternalAttribute,
  createTable,
  missingDataSwmToken,
  openLoop,
  valueToSwmToken,
} from '../output-composer';

const logger = getLoggerNew('ppg/cdi/output.ts');

function composeDataFlowMermaidContent(taskFlow: ProcessFile, metadata: Metadata) {
  const mermaidContent = [];
  mermaidContent.push(schema.text('flowchart LR;\n'));
  for (const mttObject of (taskFlow['MTT'] ?? []) as ObjectRef[]) {
    mermaidContent.push(valueToSwmToken(taskFlow.name, taskFlow.filePath, metadata));
    mermaidContent.push(schema.text(' --> '));

    let mttItemName = missingDataSwmToken('MTT object', mttObject.filePath || taskFlow.filePath, metadata);
    if (mttObject.name) {
      mttItemName = valueToSwmToken(mttObject.name, mttObject.filePath, metadata);
    }
    mermaidContent.push(mttItemName);
    mermaidContent.push(schema.text('\n'));

    for (const dtemplateObject of (mttObject['DTEMPLATE'] ?? []) as ObjectRef[]) {
      mermaidContent.push(mttItemName);

      mermaidContent.push(schema.text(' --> '));

      let dtemplateItemName = missingDataSwmToken(
        'DTEMPLATE object',
        dtemplateObject.filePath || taskFlow.filePath,
        metadata
      );
      if (dtemplateObject.name) {
        dtemplateItemName = valueToSwmToken(dtemplateObject.name, dtemplateObject.filePath, metadata);
      }

      mermaidContent.push(dtemplateItemName);
      mermaidContent.push(schema.text('\n'));

      if (dtemplateObject.objectRefs?.length) {
        mermaidContent.push(schema.text(`\nsubgraph `));
        mermaidContent.push(dtemplateItemName);
        mermaidContent.push(schema.text(`-refs\n`));
        for (const [index, objectRef] of dtemplateObject.objectRefs.entries()) {
          mermaidContent.push(dtemplateItemName);
          mermaidContent.push(schema.text(` --> ${dtemplateObject.name.value}${index}[`));
          mermaidContent.push(valueToSwmToken(objectRef.name, dtemplateObject.filePath, metadata));
          mermaidContent.push(schema.text(']\n'));
        }

        mermaidContent.push(schema.text('end\n'));
      }
    }
  }
  return mermaidContent;
}

function composeObjectMermaidContent(objectContent: ProcessFile, metadata: Metadata) {
  // Fill mermaid
  const sources = objectContent?.sources ?? [];
  const targets = objectContent?.targets ?? [];
  const mermaidContent = [];
  if (sources.length || targets.length) {
    mermaidContent.push(schema.text('flowchart LR;\n'));
    for (let i = 0; i < Math.max(sources.length, targets.length); i++) {
      mermaidContent.push(schema.text('SRC[SRC_'));
      mermaidContent.push(
        sources[i]
          ? valueToSwmToken(sources[i].name, objectContent.filePath, metadata)
          : missingDataSwmToken(`missing source ${i}`, objectContent.filePath, metadata)
      );
      mermaidContent.push(schema.text(']'));
      mermaidContent.push(schema.text(' --> EXP_PassThru --> '));
      mermaidContent.push(schema.text('TGT[TGT_'));
      mermaidContent.push(
        targets[i]
          ? valueToSwmToken(targets[i].name, objectContent.filePath, metadata)
          : missingDataSwmToken(`missing target ${i}`, objectContent.filePath, metadata)
      );
      mermaidContent.push(schema.text(']'));
    }
  }
  return mermaidContent;
}

/**
 * Compose the actual content nodes from a placeholder node
 * This function replaces inline text placeholders with their matching source file data
 * It also preserves the hardcoded text around those placeholders
 * @param placeholderNode
 * @param sourceObject
 * @param metadata
 * @param referenceFilePath
 */
function composeContentNodes(
  placeholderNode: ProseMirrorNode,
  sourceObject: ProcessFile | ValueObject | ObjectRef,
  metadata: Metadata,
  referenceFilePath: string
): ProseMirrorNode | ProseMirrorNode[] {
  const contentNodes = [];
  let lastInsertedIndex = 0;

  function composeAttributeNode(
    objectAttribute: string,
    attribute: ValueObject | ObjectRef,
    referenceAttribute: string
  ) {
    if (referenceAttribute) {
      // When referenceAttribute is provided the template is referencing a related object by its key (its type). ex. {{ MTT.AgentGroup.name }}
      if (!attribute[referenceAttribute]) {
        contentNodes.push(missingDataSwmToken(objectAttribute, referenceFilePath, metadata));
        return;
      }

      if (Array.isArray(attribute[referenceAttribute])) {
        contentNodes.push(...arrayAttributeToContentNode(attribute[referenceAttribute], referenceFilePath, metadata));
        return;
      }

      contentNodes.push(valueToSwmToken(attribute[referenceAttribute] as ValueObject, referenceFilePath, metadata));
      return;
    }

    contentNodes.push(valueToSwmToken(attribute as unknown as ValueObject, referenceFilePath, metadata));
    return;
  }

  for (const match of placeholderNode.text.matchAll(PLACEHOLDER_TEXT_MATCHER_GLOBAL)) {
    if (match.index > lastInsertedIndex) {
      // Keep hardcoded text between placeholders as-is in the final result
      contentNodes.push(schema.text(placeholderNode.text.slice(lastInsertedIndex, match.index)));
    }
    // move lastInsertedIndex to the end of the current placeholder match (we take match[0] which includes the curly braces - so the entire template syntax is ignored.
    lastInsertedIndex = match.index + match[0].length;

    // We use match[1] for understanding the actual placeholder as it is the content inside the curly braces
    const placeholderAttributes = match[1].split('.');

    // Placeholder is templating an external / configuration attribute. ex. {{ created-at }} | {{ author }}
    if (placeholderAttributes.length === 1) {
      contentNodes.push(composeExternalAttribute(placeholderAttributes[0], referenceFilePath, metadata));
      continue;
    }

    // placeholder references a value from a file. Example: {{ DATAFLOW.description }} || {{ MTT.AgentGroup.name }} || {{ source.name }}
    const [_objectType, objectAttribute, referenceAttribute] = placeholderAttributes;

    if (sourceObject['filePath']) {
      referenceFilePath = sourceObject['filePath'];
    }

    const attribute = sourceObject[objectAttribute];
    if (!attribute) {
      contentNodes.push(missingDataSwmToken(objectAttribute, referenceFilePath, metadata));
      continue;
    }

    if (Array.isArray(attribute)) {
      for (const item of attribute) {
        if (item.filePath) {
          referenceFilePath = item.filePath;
        }
        composeAttributeNode(objectAttribute, item, referenceAttribute);
      }
    } else {
      if (attribute.filePath) {
        referenceFilePath = attribute.filePath;
      }

      composeAttributeNode(objectAttribute, attribute, referenceAttribute);
    }
  }

  if (contentNodes.length) {
    if (lastInsertedIndex < placeholderNode.text.length) {
      contentNodes.push(schema.text(placeholderNode.text.slice(lastInsertedIndex)));
    }
    return contentNodes;
  }

  return placeholderNode;
}

function closeLoop(
  loopStack: string[][],
  loopSlice: { start: number; end: number },
  doc: ProseMirrorNode,
  pos: number,
  allTaskFlows: ProcessFile[],
  currentSourceObject: ProcessFile | ObjectRef
) {
  // We could have multiple nested loops
  // We only want to start iterating when we reach the end of the outermost loop
  if (loopStack.length === 1) {
    const [loopObjectType, loopSubject] = loopStack.pop();

    // Find the relevant array of objects to loop over for this doc segment
    let loopObjects: (ProcessFile | ObjectRef)[];
    if (loopObjectType === 'TASKFLOW') {
      loopObjects = [...allTaskFlows] as ProcessFile[];
    } else if (currentSourceObject && !loopSubject) {
      loopObjects = currentSourceObject[loopObjectType] as ProcessFile[];
    } else if (currentSourceObject && loopSubject) {
      loopObjects = currentSourceObject[loopSubject.toLowerCase()] as ObjectRef[];
    } else {
      logger.warn({ loopObjectType, loopSubject }, `Unknown loop annotation. Skipping...`);
    }

    if (loopSlice && loopObjects) {
      // Create a slice of nodes to loop over and fill
      const subDoc = doc.cut(loopSlice.start, loopSlice.end);
      return { subDoc, loopObjects };
    }
    return null;
  } else {
    loopStack.pop();
    loopSlice.end = pos;
    return null;
  }
}

function fillDocument(
  templateDoc: ProseMirrorNode,
  tr: Transform,
  metadata: Metadata,
  allTaskFlows: ProcessFile[],
  currentSourceData?: ProcessFile | ObjectRef
) {
  const loopStack: string[][] = [];
  let loopSlice: { start: number; end: number };
  let lastTableNode: ProseMirrorNode;
  let tableSlice: ProseMirrorNode[][];
  let currentTableRow: ProseMirrorNode[];
  const currentFilePath = currentSourceData?.filePath || allTaskFlows?.[0]?.filePath;

  templateDoc.descendants((placeholderNode, pos, parent) => {
    if (placeholderNode.type.name === 'text' && placeholderNode.text.match(LOOP_START_MATCHER)) {
      loopSlice = openLoop(placeholderNode, loopStack, loopSlice, pos);
      return true;
    }

    if (placeholderNode.type.name === 'text' && placeholderNode.text.match(LOOP_END_MATCHER)) {
      const loop = closeLoop(loopStack, loopSlice, templateDoc, pos, allTaskFlows, currentSourceData);

      if (loop) {
        loopSlice = null;
        const { subDoc, loopObjects } = loop;
        for (const loopObject of loopObjects) {
          fillDocument(subDoc, tr, metadata, allTaskFlows, loopObject);
        }
      }

      return true;
    }

    // Group all nodes under a loop placeholder to fill them iteratively
    if (loopStack.length) {
      if (loopSlice.start == null) {
        loopSlice.start = pos;
      }
      loopSlice.end = pos;
      return true;
    }

    if (placeholderNode.type.name === 'text') {
      // markdown-it linkify parses .name as a link and splits the placeholder accordingly
      // Reference to the check in the code below:
      // https://github.com/markdown-it/linkify-it/blob/5e79093543092562b4348ea58395110357f3d296/index.mjs#L444
      // We are trying to handle it by ignoring the curley braces ejected from the text node and passing them as part of the content text node
      if (parent.textContent.match(PLACEHOLDER_TEXT_MATCHER)) {
        if (placeholderNode.text.match(/^{{\s*$|^\s*}}$/)) {
          return false;
        }
        placeholderNode = schema.text(parent.textContent);
      }

      const content = composeContentNodes(
        placeholderNode,
        currentSourceData,
        metadata,
        currentSourceData?.filePath || currentFilePath
      );

      // We only create the content when we reach the leaf node (text).
      // When we do we want make sure we create it within the proper context.
      // ex. text leaf inside a heading node - we want to create as a heading
      const contentNode = schema.node(parent.type.name, parent.attrs ?? {}, content);
      if (!['tableHeader', 'tableCell'].includes(parent.type.name)) {
        tr.insert(tr.doc.content.size, contentNode);
      } else {
        if (!currentTableRow) {
          currentTableRow = [];
        }
        currentTableRow.push(contentNode);
      }

      if (tableSlice && lastTableNode && lastTableNode.eq(parent)) {
        // This is the last text node of the last table cell - create the table
        if (currentTableRow) {
          tableSlice.push(currentTableRow);
          currentTableRow = null;
        }
        tr.insert(tr.doc.content.size, createTable(tableSlice));
        tableSlice = null;
        lastTableNode = null;
      }
      return false;
    }

    if (placeholderNode.type.name === 'table') {
      tableSlice = [];

      // Store the last table cell so we can insert the table when we reach the last cell
      const tableMap = TableMap.get(placeholderNode);
      const lastTableCellPos = tableMap.positionAt(tableMap.height - 1, tableMap.width - 1, placeholderNode);
      lastTableNode = placeholderNode.nodeAt(lastTableCellPos);
      return true;
    }

    if (placeholderNode.type.name === 'tableRow') {
      if (currentTableRow && tableSlice) {
        tableSlice.push(currentTableRow);
      }
      currentTableRow = [];
      return true;
    }

    if (placeholderNode.type.name === 'tableCell') {
      // Protect against empty table cells
      if (tableSlice && !placeholderNode.content.size) {
        if (!currentTableRow) {
          currentTableRow = [];
        }
        currentTableRow.push(placeholderNode);
      }
    }

    if (placeholderNode.type.name === 'swmMermaidPlaceholder') {
      const diagramObject = placeholderNode.attrs.placeholder.split(' ')[0].toUpperCase();
      let mermaidContent;

      // Assuming here that the diagram placeholder indicates the file type the diagram is based on and the type of diagram
      if (CDIFileTypes.includes(diagramObject)) {
        if (diagramObject === 'TASKFLOW') {
          mermaidContent = composeDataFlowMermaidContent(currentSourceData as ProcessFile, metadata);
        } else {
          mermaidContent = composeObjectMermaidContent(currentSourceData as ProcessFile, metadata);
        }

        if (!mermaidContent || !mermaidContent.length) {
          mermaidContent.push(missingDataSwmToken(placeholderNode.attrs.placeholder, currentFilePath, metadata));
        }
      } else {
        logger.warn(`Unknown diagram referenced: ${diagramObject}. Skipping...`);
      }
      if (mermaidContent?.length) {
        tr.insert(tr.doc.content.size, schema.node('mermaid', {}, Fragment.fromArray(mermaidContent)));
      }
      return false;
    }

    return true;
  });
}

export async function toSwmd(metadata: Metadata, taskFlows: ProcessFile[], template: string): Promise<SwimmDocument> {
  // Use the template as the starting point for the document
  const document = parseSwmd(template);
  document.title = metadata.title;
  document.repoId = metadata.repoId;
  document.repoName = metadata.repoName;

  const templateDoc = ProseMirrorNode.fromJSON(schema, document.content);

  // Create a new document to fill while traversing the template
  const tr = new Transform(schema.topNodeType.create());
  fillDocument(templateDoc, tr, metadata, taskFlows);
  document.content = tr.doc.toJSON();

  return document;
}
